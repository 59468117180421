import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Price from "../Common/Price";

export default class SimpleExample extends Component {
    state = {
        location:[],
        infoLocation:[],
        zoom: 13,
    }
    getInfo=()=>{
        let pinsArray=[];
        let pinsInfo=[];
        if(this.props.data){
            this.props.data.forEach((item)=>{
                const pinItem = this.props.isDomesticHotel ? [item.Latitude,item.Longitude] : [item.coordinates.latitude,item.coordinates.longitude];
                const pinInfo= this.props.isDomesticHotel ? {name:item.HotelTypeName + " " + item.HotelName ,price:item.priceInformation.salePrice } : {name:item.name,price:item.salePrice}
                pinsArray.push(pinItem);
                pinsInfo.push(pinInfo);
            });
            this.setState({location:pinsArray,infoLocation:pinsInfo})
        }
    }
    componentDidMount() {
        this.getInfo();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!==this.props){
            this.getInfo();
        }
    }

    render() {
        const position = this.state.location[0];
        return (
            <Map center={position} zoom={this.state.zoom}  style={{height:this.props.height?this.props.height:"300px"}}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {this.state.location.map((item,index)=>{
                    return(
                        <Marker position={item} key={index}>
                            <Popup>
                                <div><b>{this.state.infoLocation[index].name}</b></div>
                                <div>{!!this.state.infoLocation[index].price && <Price currency={this.props.currency}>{this.state.infoLocation[index].price}</Price>}</div>
                            </Popup>
                        </Marker>
                    )
                })}

            </Map>
        )
    }
}