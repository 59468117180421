import React from "react";
import HotelFormSearchTOF from "./Hotel-Form-Search-TOF";

class HotelHomeTop extends React.Component {
  render() {
    return (
      <div className="page-container">
        <div className="padideh-home-top">
          <HotelFormSearchTOF />
          <div
            onClick={() => this.props.handleLink()}
            className="adv-banner hidden-small hidden-xsmall hidden-medium margin-start-medium"
          ></div>
        </div>
      </div>
    );
  }
}
export default HotelHomeTop;
