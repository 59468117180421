import { getCookie, setCookie } from './Common';

//read from ENV files
const readENV = () => {
  if (process.env.REACT_APP_THEME === 'NAMINTRAVELCOM') {
    return 'NOK';
  }
  switch (process.env.REACT_APP_SERVER_CULTURE) {
    case 'NO':
      return 'NOK';
    case 'DK':
      return 'DKK';
    case 'SE':
      return 'SEK';
    case 'IR':
      return 'IRR';
    case 'US':
      return 'USD';
    case 'AR':
      return 'AED';
    case 'TR':
      return 'TRY';
    default:
      return 'EUR';
  }
};
class Currency {
  getCurrency = () => {
    if (getCookie('itoursCurrency')) {
      return getCookie('itoursCurrency');
    } else {
      return readENV();
    }
  };
  setCurrency = (cvalue) => {
    setCookie('itoursCurrency', cvalue, 2000);
  };
  getNationality = () => {
    return process.env.REACT_APP_SERVER_CULTURE;
  };
}
export default new Currency();
