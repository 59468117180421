import {Reducers} from '../Enum/Models';
import Dic from '../Dictionary.json';
const userDataStorage=localStorage.getItem("userData");
const DefaultData={
    showAccountWindowIR:false,
    showAccountWindow:false,
    error:{visibility:false,content:''},
    registerMode:false,
    Verification:userDataStorage?true:false,
    Responded:false,
    HomeStyle:null,
    Dictionary:Dic.dictionary[process.env.REACT_APP_SERVER_CULTURE],
    LAN:null,
    scrollability : true,
    safarmarketLayout : false
};
export const VisualData=(state=DefaultData,action)=>{
    switch(action.type){
        case 'ShowAccountWindowIR':
            return {
                ...state,
                showAccountWindowIR:action.data
            };
        case 'ToggleAccountWindow':
            return {
                ...state,
                showAccountWindow:!state.showAccountWindow
            };
        case 'ToggleErrorWindow':
            return{
                ...state,
                error:{
                    visibility:action.params.visibility,
                    content:action.params.content
                }

            };
        case 'SwitchAccountWindow':
            return {
                ...state,
                registerMode:action.mode
            };
        case 'VERIFICATION':
            return {
                ...state,
                Verification: action.mode
            };
        case 'HANDLE_ERROR':
            return {
                ...state,
                error:{
                    content:action.content,
                    visibility: action.visibility,
                    closable: action.closable,
                    buttonLabel:action.buttonLabel,
                    icon:action.icon,
                    bold:action.bold
                }
            };
        case Reducers.MESSAGE:
            return {
                ...state,
                Responded:action.responded
            };
        case 'SETHOMEPAGECSS':
            return {
                ...state,
                HomeStyle: action.status
            };
        case 'SETLAN':
            return{
                ...state,
                //Dictionary:action.data.Dictionary,
                LAN:action.data.Lan
            };
        case 'SETLANG':
            return{
                ...state,
                Dictionary:Dic.dictionary[action.lan],
                LAN:action.lan
            };
        case Reducers.SCROLLABILITY :
            return {
                ...state,
                scrollability:action.value
            }
        case  Reducers.SAFARMARKET_LAYOUT : 
            return {
                ...state,
                safarmarketLayout : action.value
            } 
        default :
            return state;
    }
};