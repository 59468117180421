import { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';

import { changeScrollAbility,showAccountWindowIR } from '../../../Actions/VisualDataAction';
import UserName from './UserName';
import Verification from './Verification';
import Login from './Login';
import MobileForgetPassword from './MobileForgetPassword';

const IrLogin = () => {
  const [userName, setUserName] = useState('');

  const dispatch = useDispatch();
  const modal = useSelector(state=>state.VisualData.showAccountWindowIR);
  const safarmarketLayout = useSelector(state=>state.VisualData.safarmarketLayout);

  useEffect(() => {
    return () => {
      dispatch(showAccountWindowIR());
      dispatch(changeScrollAbility(true));
    };
  }, []);

  useEffect(() => {
    if (!modal) {
      setUserName('');
    }
  }, [modal]);

  const displayModalHandle = (value) => {
    dispatch(showAccountWindowIR(value));
    if (value) {
      dispatch(changeScrollAbility(false));
    } else {
      dispatch(changeScrollAbility(true));
    }
  };

  const userNameIsEmail = (/[a-zA-Z]/g.test(userName))?true:false;
  return (
    <>
      <button
        type="button"
        className="link-style-btn"
        onClick={() => {
          displayModalHandle('usernameModal');
        }}>
          {safarmarketLayout?<span className='itours-icon user-circle-dark' title="ورود، ثبت نام" />:"ورود، ثبت نام"}
        
      </button>
      <div className={`itours-modal ${modal ? 'visible' : ''}`}>
        <div
          className="modal-overlay"
          onClick={() => {
            displayModalHandle(undefined);
          }}
        />
        <div className="modal-inner modal-rounded xsmall-width">
          <button
            type="button"
            className="close-ir-login-modal"
            onClick={() => {
              displayModalHandle(undefined);
            }}>
            <span className="itours-icon gray-close-icon" />
          </button>
          <div className="modal-main large-padding">
            {modal && modal === 'usernameModal' && <UserName changeUserName={setUserName} userName={userName} changeModal={displayModalHandle} />}

            {modal && modal === 'verification' && <Verification userNameIsEmail={userNameIsEmail} userName={userName} changeModal={displayModalHandle} />}

            {modal && modal === 'login' && <Login userName={userName} changeModal={displayModalHandle} />}
            
            {modal && modal === 'mobileForgetPassword' && <MobileForgetPassword userName={userName} changeModal={displayModalHandle} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default IrLogin;
