import React from 'react';
import PropTypes from 'prop-types';

const Rating = props => (<div className={`ratingContainer ${props.className || ""}`}>
    {[...new Array(props.number)].map((_,index)=><span key={index} className='itours-icon icon-small star'  />)}
</div>);

Rating.propTypes = {
    number:PropTypes.number.isRequired
}
export default Rating;