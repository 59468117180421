import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { logoutUser } from "../../Actions/UserAction";
import Price from "../Common/Price";
import { toggleVarification } from "../../Actions/VisualDataAction";
import LayoutConfig from "../../Config/layoutConfig";

class ProfileBallon extends React.Component {
  state = {
    visibileBallon: false,
  };
  toggleBallon = () => {
    this.setState((prevState) => {
      return {
        visibileBallon: !prevState.visibileBallon,
      };
    });
  };
  logout = () => {
    this.props.logout();
    this.props.toggleVarification(false);
  };
  render() {
    const { Dictionary ,balance} = this.props;

    const AppAddress = process.env.REACT_APP_SERVER_LOCALSERVER; 
    const token=localStorage.getItem('userToken');
    const isItoursIr = token && (AppAddress ==="santana.imt.as" || AppAddress ==="itours.ir");

    let balanceElement = null;

    if (isItoursIr && balance && !this.props.safarmarketLayout){
      if (balance ==="loading"){
        balanceElement = <span className="loading-inline-circle margin-end" /> ;
      }else{

        balanceElement = <Link to="/profile?mode=chargeDeposit" className="margin-end link-style-btn">
  
        <span>
          <span className="itours-icon wallet hidden-small hidden-medium hidden-large margin-end-light" />
          <span className={isItoursIr?"hidden-xsmall":""} >موجودی کیف پول :</span>
        </span>

        <span dir="ltr" className="margin-start-light">
          {balance.amount > 0 ? <Price currency={balance.currencyType} >{balance.amount}</Price> : balance.amount}
        </span>

      </Link>
      }
    }

    const profileLinkElement = <Link to={"/profile?mode=userInformation"} className={`link-style-btn ${LayoutConfig.themeClassName === 'naminTravel-theme'?"margin-end-half":""}`}>
      {this.props.safarmarketLayout ?
        <span className="itours-icon user-circle-dark" title={Dictionary.userProfile} />
        :
        <>
          {isItoursIr && <span className="itours-icon profile hidden-small hidden-medium hidden-large" />}
          <span className={isItoursIr ? "hidden-xsmall" : ""}>
            {Dictionary.userProfile}
          </span>
        </>
      }
    </Link>;

    const logoutBtn = this.props.safarmarketLayout ? null : <button
      onClick={this.logout}
      type="button"
      className={`link-style-btn margin-start ${LayoutConfig.themeClassName === 'naminTravel-theme'?"margin-end-half":""}`}
    >

      {isItoursIr && <span className="itours-icon exit hidden-small hidden-medium hidden-large mirror-rtl" />}
      <span className={isItoursIr ? "hidden-xsmall" : ""}>
        {Dictionary.signOut}
      </span>

    </button>;


    return (
      <>
        {balanceElement}
        <div className="profile-and-logout">
          {profileLinkElement}
          {logoutBtn}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  model: state.UserData,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  safarmarketLayout:state.VisualData.safarmarketLayout,
  balance:state.UserData.userBalance
});
const MapActionToProps = (dispatch) => ({
  logout: () => {
    dispatch(logoutUser());
  },
  toggleVarification: (data) => dispatch(toggleVarification(data)),
});
export default connect(mapStateToProps, MapActionToProps)(ProfileBallon);
