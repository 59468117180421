import React from "react";
import moment from "moment";
import { Form,Input} from "antd";
import { setHomeCSS } from "../../Actions/VisualDataAction";
import { connect } from "react-redux";
import Currency from "../../Enum/Currency";
import axios from "axios";
import { ServerAddress, Hotel, Header,User } from "../../Enum/Urls";
import LayoutConfig from "../../Config/layoutConfig";
import Banner from '../../components/Home/Banner';
import NaminHomeDestinations from '../../components/NaminHome/NaminHomeDestinations/NaminHomeDestinations';
import Style2HomeAds from "../../components/Style2Home/Style2HomeAds";
import Style2HomeDestinations from "../../components/Style2Home/Style2HomeDestinations";
import BestDestinations from "../../components/Home/BestDestinations";
import IncrediblePlaces from "../../components/Home/IncrediblePlaces";
import NaminAmazingOffers from "../../components/NaminHome/NaminAmazingOffers/NaminAmazingOffers";

class PackageIndex extends React.Component {
  state={
    newsLetterLoading:false,
    newsLetterFormMessage:undefined,
    showModal:false
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setHomeCSS(true);
    axios({
      method: "get",
      url: `${
        ServerAddress.Type + ServerAddress.Hotel + Hotel.GetLocation
      }?value=ist`,
      headers: { ...Header },
    });
  }

  handleLink = (idLocation, locationName) => {
    let date = new Date();
    let checkIn = moment(date).add(15, "d").format("YYYY-MM-DD");
    let checkout = moment(date).add(18, "d").format("YYYY-MM-DD");
    this.props.history.push(
      `/hotel/result?locationId=${idLocation}&location=${locationName}&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`
    );
  }
  handleFlightLink = (destCode, destName) => {
    let culture = Currency.getNationality();
    let originCode = null;
    let originName = null;
    switch (culture) {
      case "IR":
        originCode = "THR";
        originName = "Tehran,%20Iran(THR-All%20Airports)";
        break;
      case "NO":
        originCode = "OSL";
        originName = "Oslo,%20Norway(OSL-All%20Airports)";
        break;
      case "SE":
        originCode = "STO";
        originName = "Stockholm,%20Sweden(STO-All%20Airports)";
        break;
      case "DK":
        originCode = "HEL";
        originName = "Helsinki,%20Finland(HEL-All%20Airports)";
        break;
      case "US":
        originCode = "OSL";
        originName = "Oslo,%20Norway(OSL-All%20Airports)";
        break;
      default:
        originCode = "HEL";
        originName = "Helsinki,%20Finland(HEL-All%20Airports)";
    }
    let date = new Date();
    let depart = moment(date).add(15, "d").format("YYYY-MM-DD");

    this.props.history.push(
      `/flight/result?originCodes=${originCode}&originNames=${originName}&destinationCodes=${destCode}&destinationNames=${destName}&departureDateTimes=${depart}&adult=1&child=0&infant=0&cabinClassCode=0&airTripType=OneWay&nonStop=false&airlineCode=all&airlineText=all`
    );
  }
  closeModal = () =>{
    if (!this.state.newsLetterLoading){
      this.setState({showModal:false});
    }
  }
  handleReset = () => {
    this.props.form.resetFields();
  };
  handleSubmitNewsletter = e =>{
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({newsLetterLoading:true,showModal:true});
        const params = {"email":values.email}
        axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.CreateNewsLetter}`, params, {headers: {...Header,},})
        .then(({ data }) => {
          if (data.error) {
             this.setState({
                newsLetterLoading:false,
                newsLetterFormMessage:{text:data.error.message,success:data.success}
              });
            }else{
              this.handleReset();
              this.setState({
                newsLetterLoading:false,
                newsLetterFormMessage:{text:this.props.Dictionary.youveSuccessfullySubscribedToOurNewsletter,success:data.success}
              });
           }
        })
        .catch((error) => {
          this.setState({
            newsLetterLoading:false,
            newsLetterFormMessage:{text:error.response.data.error.message,success:false}
          });
        });
      }
    });
  }
  componentWillUnmount() {
    this.props.setHomeCSS(false);
  }

  render() {
    const { Dictionary } = this.props;
    const { getFieldDecorator } = this.props.form;

    const newsletterForm = (
      <Form className="newsletter-form" onSubmit={this.handleSubmitNewsletter} >
      <div className="form-group inline-newsletter-form">
        <Form.Item className="no-margin">
          {getFieldDecorator('email', {
            rules: [{ required: true, message: Dictionary.EnterYourEmailAddress },
              { type:"email", message: Dictionary.enteredEmailAddressIsNotValid2 }],
          })(
            <Input
              type="text"
              placeholder={Dictionary.EnterYourEmailAddress}
              className={`form-input ${LayoutConfig.themeClassName === 'style-2'?"":"placeholder-right"}`}
          />,
          )}
        </Form.Item>                   
        <button type="submit" className={`button ${LayoutConfig.themeClassName === 'naminTravel-theme'?"namin-blue-btn":"color-1-btn"}`}>
          {LayoutConfig.themeClassName === 'style-2'?"اشتراک خبرنامه":Dictionary.subscribe}
        </button>
      </div>
    </Form>
    );
    return (
      <div className="Home package">
        <div
          className={`itours-modal ${this.state.showModal ? "itours-modal visible" : ""}`}
        >
          <div className="modal-overlay" onClick={this.closeModal} />
          <div className="modal-inner small-width text-center">
            <div className="modal-main">
              {this.state.newsLetterLoading ? (
                <div className="loading-outer-container">
                  <div>
                    <div className="loading-inner-container">
                      <div className="item-1"></div>
                      <div className="item-2"></div>
                      <div className="item-3"></div>
                      <div className="item-4"></div>
                      <div className="item-5"></div>
                    </div>
                    <div className="text-center">
                      {Dictionary.loadingData} ...
                    </div>
                  </div>
                </div>
              ) : (
                this.state.newsLetterFormMessage && 
                <div>
                  <br/><br/>
                    {this.state.newsLetterFormMessage.success?
                    <span className="itours-icon check-green icon-xlarge" />
                    :
                    <span className="itours-icon error-icon icon-xlarge" />
                    }
                  <div className="margin-top margin-bottom">
                  {this.state.newsLetterFormMessage.text}.
                  </div>
                  <br/>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button color-1-btn"
                onClick={this.closeModal}
                disabled={this.state.newsLetterLoading}
              >
                {Dictionary.ok}
              </button>
            </div>
          </div>
        </div> 

        <Banner searchType="package" />

        {LayoutConfig.themeClassName === 'naminTravel-theme' && <><NaminAmazingOffers /><NaminHomeDestinations/> </>}

        <div className="newletter-section">
          <div className="page-container">
            <div className="newsletter-holder">
              {LayoutConfig.themeClassName === 'style-2'?
                (<div className="float-row">
                  <div className="col-small-12 col-medium-8">
                    <Style2HomeAds/>
                  </div>
                  <div className="col-small-12 col-medium-4">
                    <div className="newsletter-box">
                      <h4 className="large-title">
                        برای اطلاع از تخفیف ها
                      </h4>
                      {newsletterForm}
                    </div>
                  </div>
                </div>)
              : (<div className="float-row">
                <div className="col-small-12 col-medium-6">
                  <div className="newsleter-inner">
                    <h4 className="normal-title">
                      {Dictionary.newsletterSubscription}
                    </h4>
                    <p>{Dictionary.newsLeterText}</p>
                  </div>
                </div>
                <div className="col-small-12 col-medium-6 text-end">
                  {newsletterForm}
                </div>
              </div>
              )
            }
            </div>
          </div>
        </div>

        {LayoutConfig.themeClassName === 'style-2' && <Style2HomeDestinations />}
        
        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === 'naminTravel-theme' || LayoutConfig.themeClassName === 'style-2')  || (
          <>
            <BestDestinations handleFlightLink={this.handleFlightLink} />
            <IncrediblePlaces handleFlightLink={this.handleFlightLink} handleLink={this.handleLink} />
          </>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setHomeCSS: (data) => {
    dispatch(setHomeCSS(data));
  },
});
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const WrappedNewsletterForm = Form.create({ name: 'newsLetterForm' })(PackageIndex);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedNewsletterForm);
