import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import HotelDetail from "../../components/Accomodation/HotelDetail";
import HotelDetailSafarmarket from "../../components/Accomodation/HotelDetailSafarmarket";
import LayoutConfig from "../../Config/layoutConfig";

class HotelDetails extends React.Component {

  render() {
    const parameters = queryString.parse(this.props.location.search);
    if (LayoutConfig.safarmarket && this.props.currencyCode ==="IRR" && parameters.safarmarketId && parameters.utm_medium && parameters.utm_source ){
      return <HotelDetailSafarmarket />
    }
    return (
      <HotelDetail />
    );
  }
}

const stateMapProp = (state) => ({
  currencyCode: state.UserData.Currency
});
export default connect(stateMapProp)(HotelDetails);
