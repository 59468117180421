import React from "react";
import axios from "axios";
import { withRouter } from 'react-router-dom';
import {
  Header,
  Package as PackageURL,
  ServerAddress
} from "../../Enum/Urls";
import queryString from "query-string";
import moment from "moment-jalaali";
import HotelItemDetails from "../Accomodation/HotelItemDetails";
import Loading from "../Common/Loading";
import Rate from "../Common/Rate";
import HotelNoImage from "../../image/default-hotel-image.svg";
import { Currency } from "../../Enum/Models";
import { connect } from "react-redux";
import { Tooltip, Tag } from "antd";
import Price from "../Common/Price";
import LayoutConfig from "../../Config/layoutConfig";
class PackageHotelItem extends React.Component {
  state={
    detailsLoading:false,
    hotelDetails:undefined,
    detailsModalVisiblility:false,
    errorMessage:""
  }
  static defaultProps = {
    model: {
      phone: [],
      emails: [],
      geolocation: { latitude: 0, longitude: 0 },
      features: [],
      address: "",
      id: 0,
      rating: 0,
      image: null,
      regularPrice: 0,
      salePrice: 0,
      discountPercent: 0,
      name: "",
    },
    forBook: true,
  };
  currecySign = null;

  showHotelDetail =()=>{
    this.setState({detailsModalVisiblility:true});
    if (!this.state.hotelDetails){
      this.setState({detailsLoading:true});
      let parameters = queryString.parse(this.props.location.search);
      if(parameters){
        let adults=[];         
        const searchedAdults= parameters.adults.split(",");
        for(let i=0 ; i<searchedAdults.length ; i++){
           adults.push(+searchedAdults[i]);
        }
 
        let children=[];
        const searchedChildren= parameters.children.split(",");
        for(let i=0 ; i<searchedChildren.length ; i++){
           children.push(+searchedChildren[i]);
        }
 
        let ages=[];
        const searchedAges= parameters.ages.split(",");
        for(let i=0 ; i<searchedAges.length ; i++){
           ages.push(+searchedAges[i]);
        }
 
       const params = {
         "Id":this.props.model.id,
         "Adults":adults,
         "Children":children,
         "Ages":ages,
         "Checkin":moment(parameters.checkin),
         "Checkout":moment(parameters.checkout)
       }
       axios.post(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.GetPackageHotelDetailById}`, params, {headers: {...Header,},})
       .then((response) => {
          this.setState({detailsLoading:false,hotelDetails:response.data.result});
        })
        .catch((error) => {
          this.setState({errorMessage:"Internal Error,Please refresh the page",detailsLoading:false})
          try {
            debugger;
          } catch (e) {
            debugger;
          }
        });
      }
    }
  }
  render() {
    const {
      features,
      address,
      rating,
      mainPhoto,
      regularPrice,
      salePrice,
      discountPercent,
      name,
    } = this.props.model;
    const { Dictionary } = this.props;
    if (this.props.currencyCode !== undefined) {
      let currency = this.props.currencyCode.toString();
      this.currecySign = Currency[currency];
    } else {
      this.currecySign = "???";
    }
    return (
      <div className="package-hotel-item">
        {this.state.detailsModalVisiblility && <div className="itours-modal hotel-details-modal visible">
          <div className="modal-overlay" onClick={()=>{this.setState({detailsModalVisiblility:false})}} />
          <div className="modal-inner">
            <div className="modal-main">
              {this.state.detailsLoading ? 
                <div className="card-padding"><Loading style2={LayoutConfig.themeClassName === "style-2"} /></div>
              :this.state.errorMessage ?
                <div className="card-padding text-center">
                  <br/>
                  <span className="itours-icon error2-icon icon-xlarge" />
                  <br/><br/>
                  <h3 className="normal-title">{this.state.errorMessage}</h3>
                  <br/><br/>
                </div>
              :
                <HotelItemDetails hotelDetails={this.state.hotelDetails} />
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button grey-btn margin-end"
                onClick={()=>{this.setState({detailsModalVisiblility:false})}}
                disabled={this.state.detailsLoading}
              >
                {Dictionary.close}
              </button>
              {this.props.isSelected ?
                this.props.showTag && <span className="check-green itours-icon margin-start icon-large" />
              :
              <button 
                type="button" 
                disabled={this.state.detailsLoading}
                className="button red-btn nowrap" 
                onClick={()=>{this.props.onSelectHotel();this.setState({detailsModalVisiblility:false})}}
              >
                {Dictionary.select}
              </button>}
            </div>
          </div>
        </div>}         
        <div className="package-hotel-main">
          <div className="hotel-item-header">
            {mainPhoto == null ? (
            <div
                className="package-hotel-image default-image"
                style={{ backgroundImage: "url(" + HotelNoImage + ")" }}
            ></div>
            ) : (
            <div
                className="package-hotel-image"
                style={{ backgroundImage: `url(${mainPhoto})` }}
            ></div>
            )}
            <div className="package-hotel-name">
                <h3 className="hotel-name en-font">{name}</h3>
                <div className="rating"><Rate number={rating} /></div>
                <div className="gray font-12 margin-top-light">{address}</div>    
            </div>            
          </div>
          <div className="package-hotel-details font-13" dir="ltr">
            {this.props.model && this.props.model.boards && (this.props.model.boards.length > 0 ) ?              
              <div> {Dictionary.board}: {this.props.model && this.props.model.boards && this.props.model.boards.map(boardItem=><Tooltip key={boardItem.name} placement="top" title= {boardItem.name}>
                    <Tag>{boardItem.code}</Tag>
                </Tooltip>)}
              </div>
              :null
            }
            <div className="feature-list">
              <div className="feature-items" dir="ltr">
                {features &&
                  features.map((item, index) => (
                    <div key={index} className="en-font">
                      {item.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>          
          </div>
          <div className="package-hotel-footer">
            <div>
              <button type="button" className="button link-style-btn" onClick={this.showHotelDetail}>{Dictionary.hotelDetails}</button>
            </div>
            <div className="package-hotel-footer-end text-end">
              <div className="margin-end-half">
                {discountPercent === 0 ? (
                  <div>
                    <b dir="ltr">
                      <Price currency={this.props.currencyCode}>
                        {salePrice}
                      </Price>
                    </b>
                  </div>
                ) : (
                  <div>
                    <b className="no-wrap inline-block" dir="ltr">
                      <Price currency={this.props.currencyCode}>
                        {salePrice}
                      </Price>
                    </b>
                    <span className="margin-start-half old-price no-wrap inline-block" dir="ltr">
                      <Price currency={this.props.currencyCode}>
                        {regularPrice}
                      </Price>
                    </span>
                  </div>
                )}
                <div className={"font-12 gray price-for-night nowrap"}>  
                  {Dictionary.startingPrice} ({this.props.night} {Dictionary.nights})
                </div>                
              </div>
              {this.props.isSelected ?
                this.props.showTag && <span className="check-green itours-icon margin-start icon-large" />
              :
               <button type="button" className="button red-btn nowrap" onClick={this.props.onSelectHotel}>
                {Dictionary.select}
              </button>}
            </div>
          </div>
      </div>
    );
  }
}
const stateMapProps = (state) => {
  return {
    currencyCode: state.UserData.Currency,
    params: state.HotelSearch.parameters,
    night: state.HotelSearch.date.duration,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
  };
};
export default withRouter(connect(stateMapProps)(PackageHotelItem));
