import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

export const onChangeValidation = (event, type, maxLength) => {
    if (maxLength) {
      if (event.target.value.length > maxLength) {
        const prevVal = event.target.value.substring(0, maxLength);
        event.target.value = prevVal;
      }
    }
    let regex;
    switch (type) {
      case 'number':
        regex = /^[0-9۰-۹٠-٩]*$/;
        break;
      case 'alphabet':
        regex = /^[a-zA-Z \u0600-\u06FF ]*$/;
        break;
      case 'enAlphabet':
        regex = /^[a-zA-Z ]*$/;
        break;
      case 'faAlphabet':
        regex = /^[\u0600-\u06FF ]*$/;
        break;
      case 'email':
        regex = /[^\u0600-\u06FF\s]/;
        break;
      default:
        regex = /^[a-zA-Z ]*$/;
    }

    if (!regex.test(event.target.value)) {
      const prevVal = event.target.value.substring(0, event.target.value.length - 1);
      if (regex.test(prevVal)) {
        event.target.value = prevVal;
      } else {
        event.target.value = '';
      }
    }
  }

class Validation extends React.Component{
    static propTypes={
        model:PropTypes.any,
        name:PropTypes.string.isRequired,
        feild:PropTypes.string.isRequired
    }
    render(){
        /* const {Dictionary,LAN} = this.props; */
        return(
            <div className="validator-label">{(this.props.model)?this.props.model[this.props.feild][this.props.name]:'SOS'}</div>
            
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Validation);