const defaultPaymentData={banks:null,depositBanks:null};
export const PaymentData=(state=defaultPaymentData,action)=>{
    switch (action.type) {
        case "SetBanks":
            return{
                ...state,
                banks: action.banksInfo

            }
        case "SetDepositBanks":
            return{
                ...state,
                depositBanks: action.banksInfo

            }            
        case "SetTokenID":
            return{
                ...state,
                tokenId:action.tokenId
            }
        case "VoucherDetailPayment":
            return{
                ...state,
                voucherDetailsPayment:action.result
            }
        default:
            return state;

    }
}
