import React from "react";
import { connect } from "react-redux";

const BestDestinations = props => {
    const {Dictionary} = props;
    return (<div className="landmarks-section">
        <div className="page-container-fluid">
          <div className="float-row text-center">
            <h3 className="purple section-title">
              <span className="inline-block-middle margin-end-half">
                {Dictionary.topDestinations}
              </span>
            </h3>
            <div className="col-small-4 col-medium-2">
              <div
                className="landmark-link"
                onClick={() =>
                    props.handleFlightLink(
                    "DXB",
                    "Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)"
                  )
                }
              >
                <div className="landmarg-icon landmark-dubai"></div>
                <span className="title">{Dictionary.Dubai}</span>
              </div>
            </div>
            <div className="col-small-4 col-medium-2">
              <div
                className="landmark-link"
                onClick={() =>
                    props.handleFlightLink(
                    "PAR",
                    "Paris,%20France(PAR-All%20Airports)"
                  )
                }
              >
                <div className="landmarg-icon landmark-paris"></div>
                <span className="title">{Dictionary.Paris}</span>
              </div>
            </div>
            <div className="col-small-4 col-medium-2">
              <div
                className="landmark-link"
                onClick={() =>
                    props.handleFlightLink(
                    "LON",
                    "London,%20United%20Kingdom(LON-All%20Airports)"
                  )
                }
              >
                <div className="landmarg-icon landmark-london"></div>
                <span className="title">{Dictionary.London}</span>
              </div>
            </div>
            <div className="col-small-4 col-medium-2">
              <div
                className="landmark-link"
                onClick={() =>
                    props.handleFlightLink(
                    "MOW",
                    "Moscow,%20Russia(MOW-All%20Airports)"
                  )
                }
              >
                <div className="landmarg-icon landmark-moscow"></div>
                <span className="title">{Dictionary.Moscow}</span>
              </div>
            </div>
            <div className="col-small-4 col-medium-2">
              <div
                className="landmark-link"
                onClick={() =>
                  props.handleFlightLink(
                    "SYD",
                    "Sydney,%20Australia(SYD-All%20Airports)"
                  )
                }
              >
                <div className="landmarg-icon landmark-sydney"></div>
                <span className="title">{Dictionary.Sydney}</span>
              </div>
            </div>
            <div className="col-small-4 col-medium-2">
              <div
                className="landmark-link"
                onClick={() =>
                  this.handleFlightLink("DEL", "Indira%20Gandhi%20Intl")
                }
              >
                <div className="landmarg-icon landmark-delhi"></div>
                <span className="title">{Dictionary.Delhi}</span>
              </div>
            </div>
          </div>
        </div>
    </div>);
} 
const mapStateToProps = state => ({
    Dictionary: state.VisualData.Dictionary
});

export default connect(mapStateToProps)(BestDestinations);