import queryString from "query-string";
import DomesticHotelCapacityCheck from "../../components/domesticHotel/DomesticHotelCapacityCheck";
//import GetPassengerSafarmarket from "../../components/Accomodation/GetPassengerSafarmarket";
import LayoutConfig from "../../Config/layoutConfig";

const DomesticHotelCapacity = props => {
    const parameters = queryString.parse(props.location.search);
    if (LayoutConfig.safarmarket && parameters.isFromSafarmarket && parameters.smid){
      //return <GetPassengerSafarmarket />
      return ("safaramarket")
    }
    return (
      <DomesticHotelCapacityCheck />
    );
}

export default DomesticHotelCapacity;