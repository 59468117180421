import { Skeleton } from 'antd';
import parse from 'html-react-parser';
import React from 'react';


const DomesticHotelDetailAbout = React.memo(props => {

    const projectName = 'آیتورز';
    const projectAddress = process.env.REACT_APP_SERVER_LOCALSERVER;

    let content;
    if (props.hotel?.Content) {
        content = parse(
            props.hotel.Content.replace(/سفرانه/g, projectName)
                .replace(/سامیتا/g, projectName)
                .replace(/<a /g, '<span ')
                .replace(/<\/a>/g, '</span>')
                .replace(/https:\/\/safaraneh.com\/fa\/hotels\/%d8%b1%d8%b2%d8%b1%d9%88-%d9%87%d8%aa%d9%84/g, '/')
                .replace(/https:\/\/www.safaraneh.com\/fa/g, projectAddress)
                .replace(/https:\/\/www.safaraneh.com\/en/g, projectAddress)
                .replace(/https:\/\/safaraneh.com/g, projectAddress)
                .replace(/https:\/\/www.samita.com\/fa/g, projectAddress)
                .replace(/https:\/\/www.samita.com\/en/g, projectAddress)
                .replace(/https:\/\/samita.com/g, projectAddress)
                .replace(/http:\/\/www.samita.com\/en/g, projectAddress)
        );
    }

    return (
        <div className="card card-padding bg-white box-border margin-bottom blue-border-right">

            <h5 class="normal-title">درباره هتل</h5>
            <div className='margin-top'>
                {!props.hotel ?
                    <Skeleton active />
                    : content || "NoInformationFound"
                }
            </div>

        </div>
    );
});

export default DomesticHotelDetailAbout;
