import {Package} from '../Enum/Models';

export const PackageSearch=(state=new Package(),action)=>{
  switch (action.type) {
      case "setPackageData":
          return{
              ...state,
              reservedPackageData:action.result
          }
      default:
          return state;
  }
};