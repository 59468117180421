import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";

import HotelCancellationPolicy from "./HotelCancellationPolicy";

HotelBoardPolicy.propTypes = {
  rate: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  rooms: PropTypes.array.isRequired,
};

function HotelBoardPolicy(props) {
  return (
    <>
      <div className={"margin-bottom-light"}>
        <Tooltip title={props.rate.board.extra}>
          <span>
            <strong className={"font-14 margin-end-light inline-block-middle"}>
              {props.rate.board.name}
            </strong>
            {props.rate.board.extra ? (
              <span className="info-icon itours-icon margin-end-light icon-small inline-block-middle margin-b-3" />
            ) : null}
          </span>
        </Tooltip>
      </div>
      <ul className={"simple-bullet-list"}>
        <HotelCancellationPolicy
          rate={props.rate}
          rooms={props.rooms}
          date={props.date}
          location={props.location}
          currency={props.currency}
        />
        {props.rate.board.description ? (
          <li className={"has-list-icon"}>
            <span className="breakfast-icon itours-icon margin-end-light inline-block" />
            <span className="green inline-block">
              {props.rate.board.description}
            </span>
          </li>
        ) : null}
      </ul>
    </>
  );
}

export default HotelBoardPolicy;
