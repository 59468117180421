import React, { Component } from 'react';
//React Redux Data
import {Provider} from 'react-redux';
import {ConnectedRouter} from "connected-react-router";
import {history,store} from "../Reducers/configureStore";
//Load Layout Page
import Layout from './Layout';
//Declare App for start Programing
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Layout />
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
