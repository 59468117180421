import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {Collapse,Checkbox} from 'antd';
import Rate from "../Common/Rate";
import {filterSelect,applyFilter} from "../../Actions/HotelAction";

const PackageFilterForm = props => {

    const [featuresList,setFeaturesList]=useState([]);
    const [ratingsList,setRatingsList] = useState([]);
    const [boardList,setBoardList] = useState([]);
    const [regionList,setRegionList] = useState([]);
    const [cabinClassList,setCabinClassList] = useState([]);
    const [airlineList,setAirlineList]=useState([]);

    const { Panel } = Collapse;

    useEffect(()=>{

        let features =[];            
        for(let i=0 ; i < props.hotels.length ; i++){
            for (let j=0 ; j < props.hotels[i].features.length;j++){
                if(features.filter(item=>item.keyword === props.hotels[i].features[j].keyword ).length === 0){
                    let newitem = props.hotels[i].features[j];
                    features.push(newitem);
                }
            }
        }
        setFeaturesList(features); 

        let boards =[];            
        for(let i=0 ; i < props.hotels.length ; i++){
            for (let j=0 ; j < props.hotels[i].boards.length;j++){
                if(boards.filter(item=>item.name === props.hotels[i].boards[j].name ).length === 0){
                    let newitem = props.hotels[i].boards[j];
                    boards.push(newitem);
                }
            }
        }
        setBoardList(boards); 

        let regions =[];            
        for(let i=0 ; i < props.hotels.length ; i++){
            for (let j=0 ; j < props.hotels[i].regions.length;j++){
                if(regions.filter(item=>item.name === props.hotels[i].regions[j].name ).length === 0){
                    let newitem = props.hotels[i].regions[j];
                    regions.push(newitem);
                }
            }
        }
        setRegionList(regions); 
        
        let rating =[];            
        for(let i=0 ; i < props.hotels.length ; i++){
            if (! rating.includes(props.hotels[i].rating) ){
                rating.push(props.hotels[i].rating);
            }
        }
        setRatingsList(rating); 

    },[props.hotels]);

    useEffect(()=>{

        let cabinClasses =[];            
        for(let i=0 ; i < props.flights.length ; i++){
            const routes= props.flights[i].airItinerary.originDestinationOptions;
            for (let j=0 ; j < routes.length;j++){
                const segments=routes[j].flightSegments;
                for (let k=0 ; k< segments.length;k++){
                    const newCabinClass = segments[k].cabinClass;
                    if(cabinClasses.length === 0 || cabinClasses.filter(item=>item.name === newCabinClass.name ).length === 0){
                        cabinClasses.push(newCabinClass);
                    }
                } 
            }
        }
        setCabinClassList(cabinClasses); 

        let airlines =[];            
        for(let i=0 ; i < props.flights.length ; i++){
            const routes= props.flights[i].airItinerary.originDestinationOptions;
            for (let j=0 ; j < routes.length;j++){
                const segments=routes[j].flightSegments;
                for (let k=0 ; k< segments.length;k++){
                    const newAirline = segments[k].marketingAirline;
                    if(airlines.length === 0 || airlines.filter(item=>item.name === newAirline.name ).length === 0){
                        airlines.push(newAirline);
                    }
                } 
            }
        }
        setAirlineList(airlines); 

    },[props.flights]);

    const timesArray =[
        {value:"0-6",iconClass:"fill-sun",label:["00:00","06:00"]},
        {value:"6-12",iconClass:"empty-sun",label:["06:00","12:00"]},
        {value:"12-18",iconClass:"fill-moon",label:["12:00","18:00"]},
        {value:"18-24",iconClass:"empty-moon",label:["18:00","00:00"]}
    ];

    const {Dictionary} =props;

    return(
        <>
        {(props.hotels.length>0) && <div className="box-border bg-white margin-bottom package-hotel-filter">        
            <strong className="filter-header margin-top">
                {Dictionary.hotelFilter}
                <button
                    type="button"
                    className="package-filter-close-btn hidden-medium hidden-large"
                    onClick={props.closeFilters}
                >
                    <span className="itours-icon white-close-icon" />
                </button>
            </strong>
            <div className="hr_padding_half">
                <Collapse
                    expandIconPosition={"right"}
                    bordered={false}
                    defaultActiveKey={["1", "2", "3", "4", "5"]}
                >
                    <Panel header={Dictionary.searchByName} key={"1"}>
                        <div className="form-group has-fake-magnifirer-icon">
                            <input className="form-input placeholder-right"  placeholder={Dictionary.enterHotelName} type="text" onChange={e => {props.filterHandle("hotelName",e.target.value)}} />
                        </div>
                    </Panel>
                    <Panel header={<span className="okr-bg">{Dictionary.starRating}</span>} key={"2"}>
                        <div className="filter-content">
                            <Checkbox.Group  onChange={(e) => { props.filterHandle("hotelRating",e) }}>
                                {ratingsList.sort((a, b)=>{return a - b}).map(item => <div key={item} className="filter-antd-checkbox-item"><Checkbox value={item}> <Rate number={parseFloat(item)} /></Checkbox></div>)}
                            </Checkbox.Group>
                        </div>                        
                    </Panel>
                    <Panel header={<span className="okr-bg">{Dictionary.facilities}</span>} key={"3"}>
                        <div className="filter-content" dir="ltr">
                            <Checkbox.Group  onChange={(e) => { props.filterHandle("hotelFeatures",e) }}>
                                {featuresList.map(item => <div key={item.keyword} className="filter-antd-checkbox-item"><Checkbox value={item.keyword}> {item.name} </Checkbox></div>)}
                            </Checkbox.Group>
                        </div>                      
                    </Panel>
                    <Panel header={<span className="okr-bg">{Dictionary.board}</span>} key={"4"}>
                        <div className="filter-content" dir="ltr">
                            <Checkbox.Group  onChange={(e) => { props.filterHandle("hotelBoard",e) }}>
                                {boardList.map(item => <div key={item.name} className="filter-antd-checkbox-item"><Checkbox value={item.name}> {item.name} </Checkbox></div>)}
                            </Checkbox.Group>
                        </div>                                         
                    </Panel>
                    <Panel header={<span className="okr-bg">{Dictionary.area}</span>} key={"5"}>
                        <div className="filter-content" dir="ltr">
                            <Checkbox.Group  onChange={(e) => { props.filterHandle("hotelRegion",e) }}>
                                {regionList.map(item => <div key={item.name} className="filter-antd-checkbox-item"><Checkbox value={item.name}> {item.name} </Checkbox></div>)}
                            </Checkbox.Group>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </div>}
        <div className="box-border bg-white package-flight-filter">   
            <strong className="filter-header">
                {Dictionary.flightFilter}
                <button
                    type="button"
                    className="package-filter-close-btn hidden-medium hidden-large"
                    onClick={props.closeFilters}
                >
                    <span className="itours-icon white-close-icon" />
                </button>                
            </strong>     
            <div className="hr_padding_half">
                <Collapse
                expandIconPosition={"right"}
                bordered={false}
                defaultActiveKey={["1", "2", "3", "4", "5"]}
                >
                <Panel header={<span className="okr-bg">{Dictionary.stops}</span>} key={"1"}>
                    <div className="filter-content">
                        <Checkbox.Group  onChange={(e) => { props.filterHandle("flightStop",e) }}>
                            <div className="filter-antd-checkbox-item"><Checkbox value={0}> {Dictionary.direct} </Checkbox></div>
                            <div className="filter-antd-checkbox-item"><Checkbox value={1}> 1 {Dictionary.stop}{" "} </Checkbox></div>
                            <div className="filter-antd-checkbox-item"><Checkbox value={2}> 1+ {Dictionary.stop}{" "} </Checkbox></div>
                        </Checkbox.Group>
                    </div>
                </Panel>
                <Panel header={<span className="okr-bg">{Dictionary.classCabin}</span>} key={"2"}>
                    <div className="filter-content" dir="ltr">
                        <Checkbox.Group  onChange={(e) => { props.filterHandle("flightCabinClass",e) }}>
                            {cabinClassList.map(item => <div key={item.name} className="filter-antd-checkbox-item"><Checkbox value={item.name}> {item.name} </Checkbox></div>)}
                        </Checkbox.Group>
                    </div>
                </Panel>

                <Panel header={<span className="okr-bg">{Dictionary.airline}</span>} key={"3"}>
                    <div className="filter-content" dir="ltr"> 
                        <Checkbox.Group  onChange={(e) => { props.filterHandle("flightAirline",e) }}>
                            {airlineList.map(item => <div key={item.name} className="filter-antd-checkbox-item"><Checkbox value={item.name}> {item.name} </Checkbox></div>)}
                        </Checkbox.Group>
                    </div>
                </Panel>
                <Panel header={<span className="okr-bg">{Dictionary.departureTime}</span>} key={"4"}>
                    <div className="filter-content flight-time-filter"> 
                        <Checkbox.Group className="time-filters-group"  onChange={(e) => { props.filterHandle("flightDepartureTime",e) }}>
                            {timesArray.map(item => <div key={item.value} className="time-antd-filter-item"><Checkbox value={item.value}>
                                    <span className={`itours-icon icon-small ${item.iconClass}`}/>
                                    <div>{item.label[0]}</div>
                                    <div>-</div>
                                    <div>{item.label[1]}</div>
                                </Checkbox></div>)
                            }
                        </Checkbox.Group>
                    </div>
                </Panel>
                <Panel header={<span className="okr-bg">{Dictionary.arrivalTime}</span>} key={"5"}>
                    <div className="filter-content flight-time-filter margin-bottom"> 
                        <Checkbox.Group className="time-filters-group"  onChange={(e) => { props.filterHandle("flightArrivalTime",e) }}>
                            {timesArray.map(item => <div key={item.value} className="time-antd-filter-item"><Checkbox value={item.value}>
                                    <span className={`itours-icon icon-small ${item.iconClass}`}/>
                                    <div>{item.label[0]}</div>
                                    <div>-</div>
                                    <div>{item.label[1]}</div>
                                </Checkbox></div>)
                            }
                        </Checkbox.Group>
                    </div>
                </Panel>
                </Collapse>
            </div>
        </div>
    </>
    )

}
const dispatchToProps=(dispatch)=>({
    filterSelect:(data)=>dispatch(filterSelect(data)),
    applyFilter:()=>dispatch(applyFilter())
})
const stateToProps=(state)=>({
   filterShow:state.HotelSearch.filterShow,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export  default connect(stateToProps,dispatchToProps)(PackageFilterForm);