import React from "react";
import { connect } from "react-redux";

const IncrediblePlaces = props => {
    const {Dictionary} = props;
    return (
        <div className="cities-section">
        <div className="page-container">
          <h3 className="color-1 section-title text-center">
            {Dictionary.incrediblePlaces}
          </h3>

          <div className="float-row">
            <div className="col-xsmall-12 col-small-6 margin-bottom">
              <div className="thumbnail-link">
                <div className="city-link city-ist large"></div>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">
                      {Dictionary.Istanbul}
                    </span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() => props.handleLink(75286, "Istanbul")}
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink(
                            "IST",
                            "Istanbul,%20Turkey(IST-All%20Airports)"
                          )
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-6 margin-bottom">
              <div className="thumbnail-link">
                <div className="city-link city-dubai large"></div>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">{Dictionary.Dubai}</span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() => props.handleLink(267, "Dubai")}
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink(
                            "DXB",
                            "Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)"
                          )
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-4 margin-bottom">
              <div className="thumbnail-link">
                <div className="city-link city-beijing medium"></div>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">{Dictionary.Beijing}</span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() => props.handleLink(12827, "Beijing")}
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink(
                            "BJS",
                            "Beijing,%20China(BJS-All%20Airports)"
                          )
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-4 margin-bottom">
              <div className="thumbnail-link">
                <span className="city-link city-rome medium"></span>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">{Dictionary.Rome}</span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() => props.handleLink(54323, "Rome")}
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink(
                            "ROM",
                            "Rome,%20Italy(ROM-All%20Airports)"
                          )
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-4 margin-bottom">
              <div className="thumbnail-link">
                <span className="city-link city-phuket medium"></span>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">{Dictionary.Phuket}</span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() => props.handleLink(74961, "Phuket")}
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink(
                            "HKT",
                            "Phuket%20International"
                          )
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-3 margin-bottom">
              <div className="thumbnail-link">
                <span className="city-link city-baku small"></span>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">{Dictionary.Baku}</span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() => props.handleLink(5120, "Baku")}
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink(
                            "BAK",
                            "Baku,%20Azerbaijan(BAK-All%20Airports)"
                          )
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-3 margin-bottom">
              <div className="thumbnail-link">
                <span className="city-link city-delhi small"></span>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">{Dictionary.Delhi}</span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() => props.handleLink(49497, "Delhi")}
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink(
                            "DEL",
                            "Indira%20Gandhi%20Intl"
                          )
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-3 margin-bottom">
              <div className="thumbnail-link">
                <span className="city-link city-bali small"></span>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">{Dictionary.Bali}</span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() => props.handleLink(47947, "Kuta")}
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink("DPS", "Ngurah%20Rai")
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-xsmall-12 col-small-3 margin-bottom">
              <div className="thumbnail-link">
                <span className="city-link city-copenhagen small"></span>
                <div className="bg-on-hover">
                  <h5 className="title center">
                    <span className="cityName">
                      {Dictionary.Copenhagen}
                    </span>
                    <span className="show-on-hover">
                      <span
                        className="itours-icon hotel-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleLink(22664, "Copenhagen")
                        }
                      />
                      <span
                        className="itours-icon top-plane-white hotel-flight-search pointer"
                        onClick={() =>
                          props.handleFlightLink(
                            "CPH",
                            "Copenhagen,%20Denmark(CPH-All%20Airports)"
                          )
                        }
                      />
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
    Dictionary: state.VisualData.Dictionary
});

export default connect(mapStateToProps)(IncrediblePlaces);