import React, { useState } from "react";
import { connect } from "react-redux";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { showError } from "../../Actions/VisualDataAction";

const ImageGallery = props => {
    const { images, Dictionary } = props;

    const [currentImage, setCurrentImage] = useState(0);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

    const photos = images?.map(item => item.large) || [];

    const openLightbox = index => {
        if (photos.length < 2) {
            props.showError({
                visibility: true,
                content: <div>
                    {Dictionary.NoOtherPhotosFoundForThisHotel}.
                </div>,
                closable: true,
                icon: "info",
                buttonLabel: Dictionary.ok
            })
        } else {
            setCurrentImage(index)
            setLightboxIsOpen(true);
        }
    };
    const closeLightbox = () => {
        setCurrentImage(0)
        setLightboxIsOpen(false);
    };


    const galeryElement = (index) => {
        if (!images || !images[index]) {
            return null;
        }
        return (<div className={index === 0 ? "large-item" : ""} >
            <div
                className="image"
                style={{ backgroundImage: `url(${index === 0 ? images[index].large : images[index].thumbnail})` }}
                onClick={openLightbox.bind(this, index)}
            >
                {index === 8 && (<div
                    className="hover"
                    onClick={openLightbox.bind(this, 8)}
                >
                    <span className="more-images-text">
                        {Dictionary.moreImages}
                    </span>
                </div>)
                }
            </div>
        </div>);
    }

    return (
        <>
            <div className={`hotel-gallery ${props.onePhoto ? "one-photo-gallery" : ""}`}>
                {props.onePhoto ? galeryElement(0) :
                    <div className="gallery-row">
                        {galeryElement(0)}

                        {images[1] ? (
                            <div className="gallery-column">
                                {galeryElement(1)}
                                {galeryElement(2)}
                                {galeryElement(3)}
                                {galeryElement(4)}
                            </div>
                        ) : null}
                        {images[5] ? (
                            <div className="gallery-column">
                                {galeryElement(5)}
                                {galeryElement(6)}
                                {galeryElement(7)}
                                {galeryElement(8)}
                            </div>
                        ) : null}
                    </div>
                }
            </div>
            {lightboxIsOpen && <Lightbox
                images={photos}
                startIndex={currentImage}
                onClose={closeLightbox}
            />}
        </>
    );

}
const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary
});

const mapDispatchToProps = dispatch => ({
    showError: (param) => { dispatch(showError(param)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallery);
