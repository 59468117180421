import React, { Component } from "react";
import queryString from "query-string";
import GetPassenger from "../../components/Accomodation/GetPassenger";
import GetPassengerSafarmarket from "../../components/Accomodation/GetPassengerSafarmarket";
import LayoutConfig from "../../Config/layoutConfig";

class GetPassengers extends Component {
  render() {
    const parameters = queryString.parse(this.props.location.search);
    if (LayoutConfig.safarmarket && parameters.isFromSafarmarket && parameters.smid){
      return <GetPassengerSafarmarket />
    }
    return (
      <GetPassenger />
    );
  }
}
export default GetPassengers;
