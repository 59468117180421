import React from 'react';
import {connect} from 'react-redux';
import {toggleErrorWindow} from '../../Actions/VisualDataAction';
import {withRouter} from "react-router-dom";
import LayoutConfig from '../../Config/layoutConfig';
class Error extends React.Component{
    render(){
        const {Dictionary} = this.props;
        const {toggleErrorWindow} = this.props;
        let icon = "error-icon";
        if (LayoutConfig.themeClassName === 'naminTravel-theme'){
            icon = "error-3"
        }
        if (this.props.icon){
            if (this.props.icon === "timer"){
                icon = "red-clock-icon"
            }else if (this.props.icon === "info"){
                icon = "blue-info"
            }
        }
        return(
            <div className="ErrorShow">
                {LayoutConfig.themeClassName === 'naminTravel-theme'?<div className='error-box text-center bg-white rounded'>
                    <div className='box-header'>ERROR</div>
                    <div className='box-main'>
                        <span className={`icon-xxlarge itours-icon ${icon}`}/>
                        <div className="margin-top margin-bottom-large semi-bold">{this.props.children}</div>
                        <button 
                            type="button" 
                            className="button namin-yellow-btn" 
                            onClick={()=>{
                                toggleErrorWindow({visibility:false,content:""});
                                if (!this.props.closableError){
                                    this.props.history.push("/")
                                }
                            }}
                        >
                            {this.props.buttonLabel || Dictionary.close}
                        </button>
                    </div>
                </div>
                :
                <div className={`toolbar ${this.props.boldError?"large":""} card-padding box-shadow bg-white text-center`}>
                    <div className="section-vertical-padding">
                        <div className="image text-center">
                            <span className={`icon-xlarge itours-icon ${icon}`}/>
                        </div>
                        <div className={`${this.props.boldError?"semi-bold font-20 margin-top-large margin-bottom-large":"margin-top margin-bottom"}`}>{this.props.children}</div>
                        <button 
                            type="button" 
                            className="button blue-btn" 
                            onClick={()=>{
                                toggleErrorWindow({visibility:false,content:""});
                                if (!this.props.closableError){
                                    this.props.history.push("/")
                                }
                            }}
                        >
                            {this.props.buttonLabel || Dictionary.close}
                        </button>
                    </div>
                </div>}
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const router=withRouter(Error)
const mapAction=(dispatch)=>({toggleErrorWindow:(params)=>{dispatch(toggleErrorWindow(params))}});
export default connect(mapStateToProps,mapAction)(router)