
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { changeScrollAbility, showError } from '../../Actions/VisualDataAction';
import { Flight as FlightURL, Payment as PaymentURL, Header, ServerAddress } from "../../Enum/Urls";
import Loading from '../Common/Loading';
import Price from '../Common/Price';

const DomesticFlightRefund = props => {

    const [loading, setLoading] = useState('درحال دریافت اطلاعات کنسلی...');
    const [refundDetails, setRefundDetails] = useState();
    const [modalVisiblity, setModalVisiblity] = useState(false);
    const [refundMethod, setRefundMethod] = useState();
    const [validationMessage, setValidationMessage] = useState(false);
    const [refundResponse, setRefundResponse] = useState();

    const dispatch = useDispatch();

    const showCallSupportMessage = () =>{
        close();
        dispatch(
            showError({
                visibility: true,
                content: 'لطفا با پشتیبانی تماس بگیرید',
                closable: true
            })
        );
    }

    const getTransaction = (reserveId,userName) => {
        axios({
            method: "get",
            url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.GetTransactionByReserveId}?ReserveId=${reserveId}&Username=${userName}`,
            headers: { ...Header },
        })
        .then((response) => {
            const data = response.data.result[0];
            if (data.type === "Gateway" && data.amount < 100000000){
                getDomesticRefundDetail();
            }else{
                setLoading("");
                showCallSupportMessage();
            }
        })
        .catch((error) => {
            console.dir(error.response?.error);
            setLoading("");
            showCallSupportMessage();
        });
    };

    const getDomesticRefundDetail = () => {
        let token = localStorage.getItem("userToken");
        let HeaderAthu = null;
        if (token) {
            HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
        } else {
            HeaderAthu = { ...Header };
        }

        axios({
            method: "get",
            url: `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetDomesticRefundRuleDetail}`,
            params: { reserveId: props.reserveID },
            headers: { ...HeaderAthu },
        })
        .then((response) => {
            setRefundDetails(response.data.result);
        })
        .catch((error) => {
            console.dir(error.response?.error);
            showCallSupportMessage();
        }).finally(() => {
            setLoading("");
        });
    };

    const refund = params => {
        setLoading("ارسال اطلاعات...");
        let token = localStorage.getItem("userToken");
        let HeaderAthu = null;
        if (token) {
            HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
        } else {
            HeaderAthu = { ...Header };
        }
        axios({
            method: "post",
            data: { ...params },
            url: `${ServerAddress.Type}${ServerAddress.Payment}${PaymentURL.DomesticFlightRefund}`,
            headers: { ...HeaderAthu }
        }).then((response) => {
            if (response.data.success) {
                setRefundResponse(response.data.result);
            }
        }).catch((error) => {
            showCallSupportMessage();
        }).finally(() => {
            setLoading("");
        });

    }

    useEffect(() => {
        setModalVisiblity(true);
        dispatch(changeScrollAbility(false));
        getTransaction(props.reserveID,props.username);
        return () => {
            dispatch(changeScrollAbility(true));
        };
    }, []);

    const submitHandler = () => {
        if (refundMethod) {
            const params = {
                type: "Personal",
                method: refundMethod,
                cardNumber: "",
                accountName: "",
                reserveId: props.reserveID,
                username: props.username,
                operatorName: "string"
            }
            refund(params);
        } else {
            setValidationMessage(true);
        }
    }

    const close = () => {
        setModalVisiblity(false);
        dispatch(changeScrollAbility(true));
        props.onClose();
    }
    return (
        <div className={`itours-modal ${modalVisiblity ? 'visible' : ''}`}>
            <div className="modal-overlay" />
            <div className="modal-inner modal-rounded xsmall-width">
                <div className="modal-main large-padding">
                    {loading ? <Loading description={loading} />
                    
                    : refundResponse ? <div className='text-center'>
                        <span className='itours-icon icon-xlarge check-green' />
                        <div className='margin-top-large'>
                            {refundResponse?.method === "DepositToWallet" ?
                                "مبلغ به کیف پول شما واریز شد."
                                :
                                "درخواست بازگشت وجه شما به بانک ارسال شد و تا 72 ساعت آینده به حساب شما واریز می شود."
                            }
                        </div>
                        <div>
                            <button type='button' className='margin-top-large button color-1-btn' onClick={()=>{props.updateReserveState();close();}}>بسیار خوب</button>
                        </div>
                    </div>
                    : refundDetails ? <div>
                        <div className="pair-row purple">
                            <span> جریمه کنسلی :</span>
                            <span dir="ltr" className="margin-end-half margin-start-light">
                                <Price currency={refundDetails.currency}>
                                    {refundDetails.damageAmount}
                                </Price>
                            </span>
                        </div>
                        <div className="pair-row green">
                            <span>مبلغ قابل استرداد:</span>
                            <b dir="ltr" className="margin-end-half margin-start-light">
                                <Price currency={refundDetails.currency}>
                                    {refundDetails.returningAmount}
                                </Price>
                            </b>
                        </div>
                        <div className='margin-bottom-large margin-top'>
                            {/* <div className="radio-item">
                                <input
                                    className="custom-radio"
                                    type="radio"
                                    name="refundMethod"
                                    value="DepositToWallet"
                                    onChange={e => { setRefundMethod(e.target.value); setValidationMessage(false); }}
                                />
                                <label className="radio-label">
                                    <span className="radio-mark"></span>
                                    واریز  مبلغ به کیف پول
                                </label>
                            </div> */}
                            <div className="radio-item">
                                <input
                                    className="custom-radio"
                                    type="radio"
                                    name="refundMethod"
                                    value={"DepositToAccount"}
                                    onChange={e => { setRefundMethod(e.target.value); setValidationMessage(false); }}
                                />
                                <label className="radio-label">
                                    <span className="radio-mark"></span>
                                    واریز مبلغ به کارت
                                </label>
                            </div>
                            {validationMessage && <p className='purple'>
                                لطفا یکی از روشهای استرداد را انتخاب کنید.
                            </p>}
                        </div>
                        <div className='clearfix'>
                            <button type='button' className='button grey-btn pull-start' onClick={close}>
                                لغو
                            </button>
                            <button type='button' className='button color-1-btn pull-end' onClick={submitHandler}>
                                تایید
                            </button>
                        </div>
                    </div>
                    : null}
                </div>
            </div>
        </div>
    );
};

export default DomesticFlightRefund;