import React from 'react';
import PropTypes from 'prop-types';
import {Travel} from '../../Enum/Models';
import HotelItem from '../Accomodation/ShowAccomodation';
import FlightItem from '../Flight/FlightItem';
export default  class ListItem extends React.Component{
    static propTypes={
        data:PropTypes.any.isRequired,
        last:PropTypes.number.isRequired,
        start:PropTypes.number.isRequired,
        type:PropTypes.string.isRequired,
        tripType:PropTypes.string
    };
    state={
        result:[]
    };
    handleShowItem=()=>{
        let {start,last,data}=this.props;
        let dataShow=[];
        let lastItem=null;
        if(data.length<last+1){
            lastItem=data.length-1;
        }else{
            lastItem=last
        }
        for(let index=start;index<=lastItem;index++){
            dataShow.push(this.props.data[index]);
        }
        this.setState({
            result:dataShow
        },()=>{
            this.render()
        })
    };
    componentDidMount=()=> {
        this.handleShowItem();

    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!==this.props){
            this.handleShowItem();
        }
    }

    render() {
        return(
            <>
                {(this.props.type===Travel.hotel)?
                this.state.result.map(item=><HotelItem model={item} key={item.id} />)
                :
                this.state.result.map(item=><FlightItem hasExtraBaggage={item.hasExtraBaggage && (1+1==="%%%extraBaggageDeactive")} domestic={this.props.domestic} type={this.props.tripType} model={item} key={item.key}/>)}
            </>

        )
    }

}