import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import '../../scss/page.scss';
class Page extends React.Component{
    static  propTypes={
        length:PropTypes.number.isRequired,
        itemPerPage:PropTypes.number.isRequired
    };
    state={
        pageNumber: Math.ceil(this.props.length / this.props.itemPerPage),
        pages:[],
        showButton:{first:0,last:4}
        // selectedPage:{
        //     first:0,
        //     last:this.props.itemPerPage-1,
        // }
    };
    initilzePage=()=>{
        let pageCash=[];
        this.setState({
            pageNumber:Math.ceil(this.props.length / this.props.itemPerPage)
        },()=>{
            for(let counter=0;counter<this.state.pageNumber;counter++){
                let first=counter*this.props.itemPerPage;
                let last=0;
                if(counter===this.state.pageNumber-1){
                    last=this.props.length-1;
                }else{
                    last=first + (this.props.itemPerPage-1);
                }
                pageCash.push({first,last});

            };
            this.setState({
                pages:pageCash,
            });
            if(pageCash.length<= 5){
                this.setState({
                    showButton:{
                        first:0,
                        last:pageCash.length-1
                    }
                })
            }
        });
        return null;
    };
    componentDidMount() {
        this.initilzePage();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.length!==this.props.length){
            this.initilzePage();
        }

    }
    render() {
        const {Dictionary} = this.props;
        return(
            <div className="Pagination">
                {(this.state.pages.length > 5 && this.state.showButton.first !== 0) ?
                    <button type="button"  className="prev-btn page-btn" onClick={()=>{this.setState({showButton:{first:this.state.showButton.first-1,last:this.state.showButton.last-1}})}}>
                        {Dictionary.previous}
                    </button>
                    :
                    <button type="button" className="prev-btn page-btn disabled">
                        {Dictionary.previous}
                    </button>
                }
                {this.state.pages.map((item,index)=>{
                return (index>= this.state.showButton.first) && (index<=this.state.showButton.last) && <input key={index} type="button" className={(this.props.itemCurrent/this.props.itemPerPage===index)?"clicked page-btn":"page-btn"} value={index+1} onClick={this.props.onChangeValue.bind(this,item.first,item.last)} />

                })}
                {(this.state.pages.length > 5 && this.state.showButton.last !== (this.state.pages.length-1)) ?
                    <button type="button" className="next-btn page-btn" onClick={()=>{this.setState({showButton:{first:this.state.showButton.first+1,last:this.state.showButton.last+1}})}}>
                        {Dictionary.next}
                    </button>
                    :
                    <button type="button" className="next-btn page-btn disabled">
                        {Dictionary.next}
                    </button>
                }
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(Page)