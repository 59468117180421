import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LayoutConfig from "../../Config/layoutConfig";
import amadeus from "../../image/logos/amadeus.png";
import iata from "../../image/logos/iata.png";
import RGF from "../../image/logos/RGF.png";
import sabre from "../../image/logos/sabre.png";

class NaminTravelFooter extends React.Component {
  state = {
    showcurrency: false,
    showlang: false,
  };
  toggleCurrency() {
    this.setState((prevState) => ({
      showcurrency: !prevState.showcurrency,
    }));
  }
  toggleLang() {
    this.setState((prevState) => ({
      showlang: !prevState.showlang,
    }));
  }
  render() {
    const { Dictionary, LAN } = this.props;
    return (
      <footer className="footer">
          <div className="main-footer">
            <div className="page-container">
              <div className="float-row">
                <div className="col-xsmall-12 col-small-6 col-medium-4 offset-medium-2 text-center">
                    <h5 className="footer-title">
                        {Dictionary.followUsOnSocialNetworks}:{" "}
                    </h5>

                    <div>
                    {LayoutConfig.contacts &&
                    LayoutConfig.contacts.instagram === "disabled" ? (
                        <span className="social-link disabled margin-end">
                        <span className="itours-icon instagram" />
                        </span>
                    ) : (
                        <a
                        href={
                            LayoutConfig.contacts &&
                            LayoutConfig.contacts.instagram
                            ? LayoutConfig.contacts.instagram
                            : LAN === "IR"
                            ? "https://www.instagram.com/itours.ir/"
                            : "https://www.instagram.com/itours_no/"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link margin-end"
                        >
                        <span className="itours-icon instagram" />
                        </a>
                    )}

                    <a
                        href={`https://wa.me/${LayoutConfig.contacts.telephone[0].replace(/ +/g, "").replace(/[{(+)}]/g, '').replace(/-/g , "")}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link margin-end"
                        >
                        <span className="itours-icon whatsapp" />
                    </a>  


                    {LayoutConfig.contacts &&
                    LayoutConfig.contacts.facebook && (LayoutConfig.contacts.facebook !== "disabled" )? (
                        <a
                        href={LayoutConfig.contacts.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link margin-end"
                        >
                        <span className="itours-icon facebook" />
                        </a>  
                    ) : (
                        <span className="social-link">
                            <span className="itours-icon facebook-gray" />
                        </span>

                    )}

                    </div>
                    {/* <div className="text-center margin-top">
                    <span dir="ltr">
                        {LayoutConfig.contacts && LayoutConfig.contacts.telephone && LayoutConfig.contacts.telephone.length>0
                        ? LayoutConfig.contacts.telephone[0]
                        : Dictionary.supportNumber}
                    </span>
                    </div> */}
                </div>                  
                <div className="col-xsmall-12 col-small-6 col-medium-4">
                    <div className="margin-start-large quick-access-block">
                    <h5 className="footer-title">
                        {Dictionary.quickAccess}
                    </h5>
                    <Link to={"/"} className="footerblock-link">
                        {Dictionary.flight}
                    </Link>
                    <Link to={"/hotel"} className="footerblock-link">
                        {Dictionary.hotel}
                    </Link>
                    <Link
                        to={"/terms"}
                        className="footerblock-link"
                        disabled={
                        LayoutConfig.themeClassName === "padide-com-theme"
                        }
                    >
                        {Dictionary.termsAndConditions}
                    </Link>
                    <Link to={"/about"} className="footerblock-link">
                        {Dictionary.aboutUs}
                    </Link>
                    <Link to={"/contact"} className="footerblock-link">
                        {Dictionary.contactUs}
                    </Link>
                    </div>
                </div>
                </div>
            </div>
          </div>
          <div className="partners-footer">
            <div className="page-container">
              <div className="partners-logos">
                <img src={amadeus} alt="amadeus" />
                <img src={RGF} alt="RGF" />
                <img src={sabre} alt="sabre" />
                <img src={iata} alt="iata" />
              </div>
            </div>
          </div>
          <div className="copy-right-footer padding-v-20">
            <div className="page-container">
                <p className="text-center no-margin">
                Namintravel.com/Internasjonal Tours AS*Torggata 31, 0183 Oslo. Org Nr : 990183694 mva.nr.VAT : 990183694* +47 465 74 712
                <br/>
                2022 Namintravel.com. All Rights Reserved
                </p>
            </div>
          </div>
      </footer>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(NaminTravelFooter);
