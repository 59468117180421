import React from 'react';
import { connect } from "react-redux";

import airplane from "../../image/airplane.svg";

const BaggageInfoItem = props => {

    const { model, hasExtraBaggage, Dictionary } = props;
    return (
        <div className="detail-flight-item" >
            {model.flightSegments.map((item, index) => {
                return (
                    <div className="baggegesInformation" dir="ltr" key={index} >
                        <div className="text-left">
                            <div>
                                <b>{item.departureAirport.cityName}</b> -{" "}
                                <small>
                                    {item.departureAirport.countryName}
                                </small>
                            </div>
                        </div>
                        <div className="airplaneIcon">
                            <img src={airplane} alt="airplane" title="airplane" />
                        </div>
                        <div className="text-left">
                            <div>
                                <b>{item.arrivalAirport.cityName}</b> -{" "}
                                <small>
                                    {item.arrivalAirport.countryName}
                                </small>
                            </div>
                        </div>
                        <div>
                            {item.baggageInformation.map(
                                (baggageItem, baggageIndex) => (
                                    <div key={baggageIndex}>
                                        {baggageItem.baggageAllowance}{" "}
                                        {baggageItem.unitType}{" "}
                                        {hasExtraBaggage && <span>(
                                            +{item.extraBaggageInformation.filter(x => x.passengerType === baggageItem.passengerType)[0].baggageAllowance}  {baggageItem.unitType}{" "} {Dictionary.extraBaggage}
                                            )</span>}
                                        <span> {Dictionary.for} </span>
                                        {baggageItem.passengerType === "ADT" && (
                                            <span>{Dictionary.adult}</span>
                                        )}
                                        {baggageItem.passengerType === "CHD" && (
                                            <span>{Dictionary.child}</span>
                                        )}
                                        {baggageItem.passengerType === "INF" && (
                                            <span>{Dictionary.infant}</span>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const stateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
    });
    
export default connect(stateToProps)(BaggageInfoItem);