import React, { useEffect, useState } from 'react';

const QuantityInput = props => {
  const [value, setValue] = useState(props.min != undefined ? props.min : 1);

  const decrease = () => {
    if (props.min == null || value > props.min) {
      setValue((prevValue) => prevValue - 1);
    }
  };

  const increase = () => {
    if (props.max == null || value < props.max) {
      setValue((prevValue) => prevValue + 1);
    }
  };
  useEffect(() => {
    props.onChange(value);
  }, [value]);

  useEffect(()=>{
    if (props.value !== undefined){
      setValue(props.value);
    }
  },[props.value])

  return (
    <span className={`quantity-input-wrapper ${props.small ? "small-input" : ''} ${props.className || ""}`} dir="ltr">
      <span className={`decrease ${props.min != null && value <= props.min ? "disabled" : ''}`} onClick={decrease} >
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M19,13H5V11H19V13Z" />
        </svg>
      </span>
      <input type="text" readOnly value={value} className="quantity-input" />
      <span className={`increase ${props.max != null && value >= props.max ? "disabled" : ''}`} onClick={increase} >
        <svg viewBox="0 0 24 24">
          <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </svg>

      </span>
    </span>
  );
};

export default QuantityInput;
