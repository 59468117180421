import { useEffect, useState } from "react";
import queryString from 'query-string';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Form, Steps, Input, Radio, Checkbox } from 'antd';

import { ServerAddress, Header, DomesticHotelApiUrls } from "../../Enum/Urls";
import { DomesticHotelPreReserve } from "../../Actions/DomesticHotelAction";
import { splitCodeAndNumber } from "../../Actions/UserAction";
import { showError } from "../../Actions/VisualDataAction";
import Loading from "../Common/Loading";
import LayoutConfig from "../../Config/layoutConfig";
import DomesticHotelSummery from "./DomesticHotelSummery";
import { CountryPostalCode } from "../../Enum/Models";
import { onChangeValidation } from "../Common/Validation";
import DomesticHotelCheckoutRoomItem from "./DomesticHotelCheckoutRoomItem";

const DomesticHotelGetPassenger = props => {

  const { Step } = Steps;
  const { userData, Dictionary, LAN } = props;

  const { getFieldDecorator } = props.form;

  const preReserveKey = queryString.parse(props.location.search)?.preReserveKey;

  const [validationData, setValidationData] = useState();
  const [hotelData, setHotelData] = useState();
  const [loading, setLoading] = useState(true);
  const [userIsPassenger, setUserIsPassenger] = useState(true);

  const [roomChildAndExtraBed, setRoomChildAndExtraBed] = useState([]);

  const disableUserIsPassenger = () => {
    if (userIsPassenger) {
      setUserIsPassenger(false)
    }
  };

  useEffect(() => {

    function getOffsetTop(elem) {
      let offsetTop = 0;
      do {
        if (!isNaN(elem.offsetTop)) {
          offsetTop += elem.offsetTop;
        }
      } while (elem === elem.offsetParent);
      return offsetTop;
    }
    function getOffsetLeft(elem) {
      let offsetLeft = 0;
      do {
        if (!isNaN(elem.offsetLeft)) {
          offsetLeft += elem.offsetLeft;
        }
      } while (elem === elem.offsetParent);
      return offsetLeft;
    }
    function sidesEffect() {
      if (document.getElementsByClassName("sides-holder").length > 0) {
        let docTop = document.documentElement.scrollTop,
          elmntsTop = getOffsetTop(
            document.getElementsByClassName("sides-holder")[0]
          ),
          elmnt1 = document.getElementsByClassName("side-item")[0],
          elmnt2 = document.getElementsByClassName("side-item")[1],
          h1 = elmnt1.offsetHeight,
          h2 = elmnt2.offsetHeight,
          h,
          H,
          smallSide,
          bigSide,
          smallSideParent,
          smallSideWidth,
          smallSidePosLeft;

        if (h1 < h2) {
          smallSide = document.getElementsByClassName("side-item")[0];
          bigSide = document.getElementsByClassName("side-item")[1];
        } else {
          smallSide = document.getElementsByClassName("side-item")[1];
          bigSide = document.getElementsByClassName("side-item")[0];
        }
        smallSideParent = smallSide.parentElement;
        smallSideParent.style.position = "relative";
        smallSideParent.style.minHeight = "1px";
        h = smallSide.offsetHeight;
        H = bigSide.offsetHeight;
        smallSideWidth = smallSideParent.offsetWidth;
        smallSidePosLeft = getOffsetLeft(smallSideParent) - 1;
        if (window.innerWidth < 850) {
          smallSide.style.position = "static";
          smallSide.style.top = "";
          smallSide.style.bottom = "";
          smallSide.style.width = "";
          smallSide.style.left = "";
        } else {
          if (docTop < elmntsTop) {
            smallSide.style.position = "static";
            smallSide.style.top = "";
            smallSide.style.bottom = "";
            smallSide.style.width = "";
            smallSide.style.left = "";
          } else {
            if (docTop < elmntsTop + H - h) {
              smallSide.style.position = "fixed";
              smallSide.style.top = 0;
              smallSide.style.bottom = "";
              smallSide.style.width = smallSideWidth + "px";
              smallSide.style.left = smallSidePosLeft + "px";
            } else {
              smallSide.style.position = "absolute";
              smallSide.style.top = "";
              smallSide.style.bottom = 0;
              smallSide.style.width = "";
              smallSide.style.left = 0;
            }
          }
        }
      }
    }
    document.addEventListener('scroll', sidesEffect);
    window.addEventListener("resize", sidesEffect);

    return (() => {
      document.removeEventListener('scroll', sidesEffect);
      window.removeEventListener("resize", sidesEffect);
    });
  }, [])

  useEffect(() => {
    if (preReserveKey) {
      window.scrollTo(0, 0);
      const fetchPrereserveData = async () => {
        const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.GetValidate}?Id=${preReserveKey}`,
          {
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': 'fa-IR'
            }
          }
        );
        if (response?.data?.result) {
          setValidationData(response.data.result);
          const initialExtra = response.data.result?.rooms?.map(_ => ({ selectedChild: false, selectedExtraBed: 0, childFee: 0, extraBedFee: 0 }));
          setRoomChildAndExtraBed(initialExtra);
        }
      }
      fetchPrereserveData();
    }
  }, [preReserveKey]);


  let hotelId;
  if (validationData) {
    hotelId = validationData.accommodationId
  }
  useEffect(() => {
    if (hotelId) {
      const fetchHotelData = async () => {
        const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetHotelById}?hotelId=${hotelId}`,
          {
            headers: {
              ...Header,
              apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
              'Accept-Language': 'fa-IR'
            }
          }
        );
        if (response?.data) {
          setHotelData(response.data);
          setLoading(false);
        }
      }
      fetchHotelData();
    }
  }, [hotelId]);

  useEffect(() => {

    if (userData) {
      const [code, number] = splitCodeAndNumber(userData.phoneNumber);

      props.form.setFieldsValue({
        [`reserver.gender`]: userData.gender,
        [`reserver.firstName`]: userData.firstName,
        [`reserver.lastName`]: userData.lastName,
        [`reserver.email`]: userData.emailAddress
      });

      if (number && code) {
        props.form.setFieldsValue({
          [`phoneNumber`]: number,
          [`countryCode`]: code
        });
      }

      if (userIsPassenger) {
        props.form.setFieldsValue({
          [`passengers[0].gender`]: userData.gender,
          [`passengers[0].firstName`]: userData.firstName,
          [`passengers[0].lastName`]: userData.lastName,
        });
      }
    }

  }, [userData]);

  let hotelInformation, reserveInformation;
  if (hotelData) {
    hotelInformation = {
      image: {
        url: hotelData.ImageUrl,
        alt: hotelData.ImageAlt,
        title: hotelData.ImageTitle
      },
      name: `${hotelData.HotelCategoryName} ${hotelData.HotelName} ${hotelData.CityName}`,
      rating: hotelData.HotelRating,
      address: hotelData.Address,
      TopSelling: hotelData.TopSelling,
      Url: hotelData.Url,
      CityId: hotelData.CityId
    }
  }
  if (validationData) {
    reserveInformation = {
      checkin: validationData.checkin,
      checkout: validationData.checkout,
      duration: moment(validationData.checkout).diff(moment(validationData.checkin), 'days'),
      rooms: validationData.rooms.map(roomItem => ({
        name: roomItem.name,
        board: roomItem.boardCode,
        cancellationPolicyStatus: roomItem.cancellationPolicyStatus,
        bed: roomItem.bed,
        extraBed: roomItem.extraBed,
        pricing: roomItem.pricing,
        maxChildAge: roomItem.maxChildAge,
        maxInfantAge: roomItem.maxInfantAge
      })),
      salePrice: validationData.rooms.reduce((totalPrice, roomItem) => {
        const roomItemPrice = roomItem.pricing.find(
          item => item.type === "Room" && item.ageCategoryType === "ADL"
        )?.amount;
        if (roomItemPrice) {
          return totalPrice + +roomItemPrice
        } else {
          return null;
        }
      }, 0),
      boardPrice: validationData.rooms.reduce((totalPrice, roomItem) => {
        const roomItemPrice = roomItem.pricing.find(
          item => item.type === "RoomBoard" && item.ageCategoryType === "ADL"
        )?.amount;
        if (roomItemPrice) {
          return totalPrice + +roomItemPrice
        } else {
          return null;
        }
      }, 0)
    }
  }

  const safarmarketId = queryString.parse(props.location.search)?.smid;


  if (loading) {
    return <Loading style2={LayoutConfig.themeClassName === 'style-2'} fullPage description={Dictionary.GettingPassengerInformation + ' ...'} />;
  }

  const updateRoomChildAndExtraBed = (roomIndex, property, value, fee) => {
    if (roomChildAndExtraBed?.length) {
      setRoomChildAndExtraBed(prevState => {
        const updatedState = [...prevState];
        updatedState[roomIndex][property] = value;
        if (property === "selectedChild") {
          updatedState[roomIndex]["childFee"] = fee;
        }
        if (property === "selectedExtraBed") {
          updatedState[roomIndex]["extraBedFee"] = fee;
        }
        return updatedState
      });
    }
  }


  const updateFirstPassenger = (value, type) => {
    if (userIsPassenger) {
      switch (type) {
        case 'gender':
          props.form.setFieldsValue({
            [`passengers[0].gender`]: value
          });
          break;
        case 'firstName':
          props.form.setFieldsValue({
            [`passengers[0].firstName`]: value
          });
          break;
        case 'lastName':
          props.form.setFieldsValue({
            [`passengers[0].lastName`]: value
          });
          break;
        case 'nationalId':
          props.form.setFieldsValue({
            [`passengers[0].nationalId`]: value
          });
          break;
        default:
          return
      }
    }
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);

        let code = values.countryCode;
        let phone = values.phoneNumber;
        if (phone[0] === "0") {
          phone = phone.substring(1)
        }
        if (code.substring(0, 2) === "00") {
          code = "+" + code.substring(2)
        }
        const params = {
          passengers: values.passengers,
          reserver: {
            ...values.reserver,
            userName: userData ? userData.userName : code + phone,
            phoneNumber: code + phone
          },
          specialRequest: values.specialRequest,
          preReserveKey: preReserveKey
        }

        const reserveResponse = await DomesticHotelPreReserve(params);

        if (reserveResponse.data && reserveResponse.data.result) {
          const id = reserveResponse.data.result.id;
          const username = reserveResponse.data.result.username;
          setLoading(false);

          if (reserveResponse.data.result.status === 'Registered') {
            props.history.push(`/payment/reserve?username=${username}&reserveId=${id}`);
          } else {

            props.history.push(`/domesticHotel/capacity?reserveId=${id}&username=${username}`);
          }
        } else {
          props.showError({
            visibility: true,
            content: reserveResponse.response?.data?.error?.message || "Internal Error,Please refresh the page",
          });
        }

      }
    });
  };

  const prefixSelector = getFieldDecorator('countryCode', {
    initialValue: LAN === 'IR' ? '0098' : LAN === 'AR' ? "00971" : "0047",
  })(
    <select className="form-select" style={{ width: 120 }} dir="ltr">
      {CountryPostalCode.map(item => <option key={item.code} value={item.value}>
        {item.code}
      </option>)}
    </select>,
  );

  return (
    <div className="get-passenger-form-holder">
      <Form onSubmit={SubmitHandler}  >
        <div className="page-padding getpassenger-page">
          <div className="page-container hotel-get-passenger">
            <Steps current={1}>
              <Step title={Dictionary.selectHotel} />
              <Step title={Dictionary.PassengersInformation} />
              <Step title={"بررسی ظرفیت"} />
              <Step title={Dictionary.paymentTypeOptions} />
            </Steps>

            <div className="getPassengers">
              {!!hotelData && (
                <div className="sides-outer-holder">
                  <div className="sides-holder">
                    <div className="large-side">
                      <div className="side-item full-width">
                        <div className="side-item-inner">
                          <h5 className="normal-title">
                            {Dictionary.reserverContactInformation}({Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail})
                          </h5>
                          <div className="margin-bottom bg-white responsive-bg-transparent box-border card-padding blue-border-right">
                            <Form.Item className="form-group no-margin-bottom">
                              {getFieldDecorator('reserver.gender', {
                                initialValue: true,
                              })(
                                <Radio.Group onChange={e => { updateFirstPassenger(e.target.value, 'gender') }} >
                                  <Radio value={true} className="no-margin">{Dictionary.male}</Radio>
                                  <Radio value={false}>{Dictionary.female}</Radio>
                                </Radio.Group>,
                              )}
                            </Form.Item>
                            <div className="float-row">
                              <div className="col-small-12 col-medium-6">
                                <Form.Item label={Dictionary.firstName} className="form-group">
                                  {getFieldDecorator('reserver.firstName', {
                                    rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                  })(
                                    <Input
                                      className="form-input"
                                      placeholder={Dictionary.firstName}
                                      onChange={e => {
                                        onChangeValidation(e, 'alphabet');
                                        updateFirstPassenger(e.target.value, 'firstName');
                                      }}
                                    />,
                                  )}
                                </Form.Item>
                              </div>
                              <div className="col-small-12 col-medium-6">
                                <Form.Item label={Dictionary.lastName} className="form-group">
                                  {getFieldDecorator('reserver.lastName', {
                                    rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                  })(
                                    <Input
                                      className="form-input"
                                      placeholder={Dictionary.lastName}
                                      onChange={(e) => {
                                        onChangeValidation(e, 'alphabet');
                                        updateFirstPassenger(e.target.value, 'lastName');
                                      }}
                                    />,
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                            <div className="float-row">
                              <div className="col-small-12 col-medium-6">
                                <Form.Item className="no-margin form-group" label={Dictionary.email}>
                                  {getFieldDecorator('reserver.email', {
                                    rules: [{ required: true, message: Dictionary.enterEmail },
                                    { type: 'email', message: Dictionary.enteredEmailAddressIsNotValid }
                                    ],
                                  })(
                                    <Input placeholder={Dictionary.email} className="form-input" />
                                  )}
                                </Form.Item>
                              </div>
                              <div className="col-small-12 col-medium-6">
                                <Form.Item label={Dictionary.phoneNumber} className="no-margin form-group country-code-pair" >
                                  {getFieldDecorator('phoneNumber', {
                                    rules: [
                                      { required: true, message: Dictionary.thisFieldIsRequired },
                                      {
                                        validator: (a, value, c) => {
                                          if (!!value && (value.length < 3 || value.length > 15 || value[0] === "0")) {
                                            return Promise.reject(new Error(Dictionary.enteredPhoneNumberIsNotValid2));
                                          }
                                          return Promise.resolve();
                                        }, message: Dictionary.enteredPhoneNumberIsNotValid2
                                      }
                                    ],
                                  })(<Input
                                    addonBefore={prefixSelector}
                                    className="placeholder-right en-input-text"
                                    onChange={(e) => {
                                      onChangeValidation(e, 'number');
                                    }}
                                  />)}
                                </Form.Item>
                              </div>
                            </div>
                            <div className="float-row margin-bottom">
                              <div className="col-small-12 col-medium-6">
                                <Form.Item className="no-margin form-group" label={Dictionary.nationalCode}>
                                  {getFieldDecorator('reserver.nationalId', {
                                    rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                  })(
                                    <Input
                                      className="form-input"
                                      placeholder={Dictionary.nationalCode}
                                      onChange={e => {
                                        onChangeValidation(e, 'number');
                                        updateFirstPassenger(e.target.value, 'nationalId');
                                      }}
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </div>

                          </div>
                          <div className="box-border bg-white  responsive-bg-transparent get-passenger-form-holder margin-bottom blue-border-right">
                            <div className="hr_padding">
                              <h5 className="title">{Dictionary.PassengersInformation}</h5>
                            </div>
                            <div>

                              {reserveInformation?.rooms?.map((roomItem, roomIndex) => <DomesticHotelCheckoutRoomItem
                                key={roomIndex}
                                form={props.form}
                                roomItem={roomItem}
                                roomIndex={roomIndex}
                                reserveInformation={reserveInformation}
                                updateRoomChildAndExtraBed={updateRoomChildAndExtraBed}
                                disableUserIsPassenger={disableUserIsPassenger}
                              />)}

                              <div className="border-top padding-h-20 padding-bottom">
                                <Form.Item className="no-margin-bottom">
                                  {getFieldDecorator('acceptRuls', {
                                    valuePropName: 'checked',
                                    rules: [{ required: true, message: Dictionary.youShouldAcceptThis }]
                                  })(<Checkbox className="margin-end-half" />)}
                                  {LAN === 'IR' ? (
                                    <>
                                      <Link to='/terms' className="content-link"> قوانین و مقررات آیتورز </Link> را خوانده ام و با آن موافق هستم.
                                    </>
                                  ) : (
                                    <>
                                      {Dictionary.iHaveReadAndAgreeToThe}
                                      <Link to='/terms' className="content-link" target="_blank">
                                        {Dictionary.termsAndConditions}
                                      </Link>
                                    </>
                                  )}
                                </Form.Item>

                              </div>
                            </div>
                          </div>

                          <div className="card bg-white responsive-bg-transparent card-padding box-border blue-border-right">
                            <div className="float-row">
                              <div className="col-small-12 col-large-9">
                                <div className="form-group">
                                  <h5 className="normal-title">{Dictionary.description}</h5>
                                  <p>
                                    {Dictionary.EnterYourRequestsHereAndWeWillShareThemWithTheHotel}.
                                    {Dictionary.SpecialRequestsAreNotGuaranteedButTheHotelWillDoEverythingToMeetYourNeeds}
                                  </p>

                                  <Form.Item className="no-margin form-group" >
                                    {getFieldDecorator('specialRequest')(
                                      <textarea className="form-textarea"></textarea>
                                    )}
                                  </Form.Item>

                                </div>
                              </div>
                            </div>
                          </div>
                          {/* %%remarks */}
                        </div>
                      </div>
                    </div>
                    <div className="small-side">
                      <div className="side-item full-width">
                        <div className="side-item-inner">

                          <DomesticHotelSummery
                            roomChildAndExtraBed={roomChildAndExtraBed}
                            hotelInformation={hotelInformation}
                            reserveInformation={reserveInformation}
                            currencyCode={props.currencyCode}
                            safarmarketId={safarmarketId}
                            showButton={true}
                            cancellationPolicy={hotelInformation.cancellationPolicy}
                          />

                          <div className="box-border top-color-border orange margin-top bg-white">
                            <div className="colored">{Dictionary.pricesToRiseSoon}!</div>
                            <div className="font-12"> {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}. </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  currencyCode: state.UserData.Currency,
  userData: state.UserData.userData,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => { dispatch(showError(param)); }
});

const DomesticHotelGetPassengerForm = Form.create({ name: 'hotelPrereserveForm' })(DomesticHotelGetPassenger);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DomesticHotelGetPassengerForm));