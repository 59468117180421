import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types'
export default class Price extends React.PureComponent{
    static propTypes={
        currency:PropTypes.string.isRequired,
    }
    state={
        price:null
    }
    checkCurrency=()=>{
        if(this.props.children){
            let price=0;
            if(this.props.currency==="NOK" || this.props.currency==="SEK" || this.props.currency==="DKK"){
                price=numeral(this.props.children).format("0,0").replace(/,/g,' ');
            }else{
                price=numeral(this.props.children).format("0,0");
            }
            this.setState({
                price:price
            })
        }else{
            this.setState({
                price:0
            })
        }
    };
    componentDidMount() {
        this.checkCurrency();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!==this.props){
            this.checkCurrency()
        }
    }
    render() {
        return(
            <span className={`price ${this.props.className || ""}`}>
                <span className="value">{this.state.price}</span> <span className="currency">{this.props.currency}</span>
            </span>
        )
    }
}