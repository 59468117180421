import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getCookie,setCookie} from '../../Enum/Common';
class MessageAccept extends React.PureComponent{
    state={
        show:false
    };
    componentDidMount() {
        if(getCookie("acceptItoursRules")===""){
            this.setState({
                show:true
            })
        }
    }
    setAccept(){
        setCookie("acceptItoursRules",true,31);
        this.setState({
            show:false
        })
    }
    render() {
        const {Dictionary,LAN} = this.props;
        if(this.state.show){
            return(
                (LAN === "IR")?null:
                <div className="accept-rule-inner">
                    <div className="page-container">
                        <div className="accept-rules-flex">
                            <div className="margin-end full-width">
                                <strong>
                                    {Dictionary.acceptCookiesUseTitle}
                                </strong>
                                <p>
                                    {Dictionary.acceptCookiesUseP}
                                </p>
                            </div>
                            <button type={"button"} className="button blue-btn" onClick={this.setAccept.bind(this)}>{Dictionary.IApprove}</button>
                        </div>
                    </div>
                </div>
                    
            )
        }else{
            return null
        }

    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const RouterDom=withRouter(MessageAccept);
export default connect(mapStateToProps)(RouterDom);
