import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import adv1 from '../../image/style-2/adv-1.jpg';
import adv2 from '../../image/style-2/adv-2.jpg';


const Style2HomeAds = () =>{
    const settings = {
        arrows:false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        rtl: true
      };
    return(<div className="style-2-adv-slider-holder">
        <Slider {...settings}>
            <div className="relative">
                <div style={{backgroundImage:`url("${adv1}")`}} className="adv-item">
                    <h3 className="adv-title">قیمت های استثنایی</h3>
                </div>
            </div>
            <div className="relative">
                <div style={{backgroundImage:`url("${adv2}")`}} className="adv-item">
                    <h3 className="adv-title">قیمت های استثنایی</h3>
                </div>
            </div>

        </Slider>
        </div>)
}

export default Style2HomeAds;