import React from "react";
import Autocomplete from "../Common/Autocomplete";
import { Header, Flight, ServerAddress } from "../../Enum/Urls";
import moment from "moment-jalaali";
import MultiDatePicker from "react-multi-date-picker"
import SelectPassenger from "./selectPassenger";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import returnway from "../../image/returnway.svg";
import oneway from "../../image/oneway.svg";
import { showError } from "../../Actions/VisualDataAction";
import LayoutConfig from "../../Config/layoutConfig";
import SearchBoxTab from '../Common/SearchBoxTab';
import { Tooltip } from "antd";
class SearchForm extends React.Component {
  state = {
    isMobile:false,
    numberOfTrip: [1],
    selectPassengersShow: false,
    validation: false,
    // range:[{min:moment().subtract(0,'day'),max:null},{min:moment().subtract(0,'day'),max:null}],
    searchParam: {
      departureDateTimes: [0],
      arrivalDateTimes: [],
      originCodes: [0],
      originNames: [0],
      destinationCodes: [0],
      destinationNames: [0],
      adult: 1,
      child: 0,
      infant: 0,
      cabinClassCode: "Economy",
      airTripType: "OneWay",
      //airTripType: this.props.domesticFlight?"OneWay": "RoundTrip",
      nonStop: false,
      airlineCode: "all",
      airlineText: "",
    },
    defaultValue: {
      departureDateTimes: [0],
      arrivalDateTimes: [],
      originCodes: [0],
      originNames: [0],
      destinationCodes: [0],
      destinationNames: [0],
      adult: 1,
      child: 0,
      infant: 0,
      cabinClassCode: "Economy",
      airTripType: "OneWay",
      //airTripType: this.props.domesticFlight?"OneWay": "RoundTrip",
      nonStop: false,
      airlineCode: "all",
      airlineText: "",
    },
    showMenu: true,
    datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
    datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
  };
  closeSelectPassenger = () => {
    this.setState({
      selectPassengersShow: false,
    });
  };
  toggleSelectPassengers = () => {
    this.setState(prevState => {return{
      selectPassengersShow: !prevState.selectPassengersShow,
    }});
  };
  addPassenger = (type) => {
    let adultsNumber = this.state.searchParam.adult;
    let childsNumber = this.state.searchParam.child;
    let infantsNumber = this.state.searchParam.infant;
    if (type === "adult") {
      if (adultsNumber + childsNumber < 9) {
        this.setState({
          searchParam: {
            ...this.state.searchParam,
            adult: adultsNumber + 1,
          },
        });
      } else {
        return false;
      }
    } else if (type === "infant") {
      if (infantsNumber < adultsNumber) {
        this.setState({
          searchParam: {
            ...this.state.searchParam,
            infant: infantsNumber + 1,
          },
        });
      } else {
        return false;
      }
    } else {
      if (adultsNumber + childsNumber < 9) {
        this.setState({
          searchParam: {
            ...this.state.searchParam,
            child: childsNumber + 1,
          },
        });
      } else {
        return false;
      }
    }
  };
  removePassenger = (type) => {
    let adultsNumber = this.state.searchParam.adult;
    let childsNumber = this.state.searchParam.child;
    let infantsNumber = this.state.searchParam.infant;

    if (type === "adult") {
      if (adultsNumber > 1) {
        if (adultsNumber > infantsNumber) {
          this.setState({
            searchParam: {
              ...this.state.searchParam,
              adult: adultsNumber - 1,
            },
          });
        } else {
          this.setState({
            searchParam: {
              ...this.state.searchParam,
              adult: adultsNumber - 1,
              infant: adultsNumber - 1,
            },
          });
        }
      } else {
        return false;
      }
    } else if (type === "infant") {
      if (infantsNumber > 0) {
        this.setState({
          searchParam: {
            ...this.state.searchParam,
            infant: infantsNumber - 1,
          },
        });
      } else {
        return false;
      }
    } else if (type === "child") {
      if (childsNumber > 0) {
        this.setState({
          searchParam: {
            ...this.state.searchParam,
            child: childsNumber - 1,
          },
        });
      } else {
        return false;
      }
    }
  };
  changeTypeToReturn = () => {
    this.setState(prevState => ({
      searchParam: {
        ...prevState.searchParam,
        airTripType: "RoundTrip",
        departureDateTimes:[prevState.searchParam.departureDateTimes[0],undefined]
      },
      defaultValue: {
        ...prevState.defaultValue,
        airTripType: "RoundTrip",
        departureDateTimes:[prevState.searchParam.departureDateTimes[0],undefined]
      },
    })
    );
  };
  changeTypeToOneWay = () => {
    this.setState(prevState => ({
      searchParam: {
        ...prevState.searchParam,
        airTripType: "OneWay",
        departureDateTimes:[prevState.searchParam.departureDateTimes[0]]
      },
      defaultValue: {
        ...prevState.defaultValue,
        airTripType: "OneWay",
        departureDateTimes:[prevState.searchParam.departureDateTimes[0]]
      },
    }));
  };
  showPersianMonth = (input) => {
    let day = input.jMonth() + 1;
    switch (day) {
      case 1:
        return "فروردین";
      case 2:
        return "اردیبهشت";
      case 3:
        return "خرداد";
      case 4:
        return "تیر";
      case 5:
        return "مرداد";
      case 6:
        return "شهریور";
      case 7:
        return "مهر";
      case 8:
        return "آبان";
      case 9:
        return "آذر";
      case 10:
        return "دی";
      case 11:
        return "بهمن";
      case 12:
        return "اسفند";
      default:
        return null;
    }
  };
  showGregorianMonth = (input) => {
    let day = input.get("month") + 1;
    switch (day) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return null;
    }
  };
  ///
  showPersianDate = (input) => {
    let day = input.get("days");
    switch (day) {
      case 6:
        return "شنبه";
      case 0:
        return "یکشنبه";
      case 1:
        return "دوشنبه";
      case 2:
        return "سه شنبه";
      case 3:
        return "چهارشنبه";
      case 4:
        return "پنجشنبه";
      case 5:
        return "جمعه";
      default:
        return null;
    }
  };
  showGregorianDate = (input) => {
    let day = input.get("days");
    switch (day) {
      case 6:
        return "Saturday";
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      default:
        return null;
    }
  };
  componentDidMount() {
    if (window.innerWidth <= 767){
      this.setState({isMobile:true});
    }
    if (this.props.LAN === "IR") {
      moment.loadPersian({
        dialect: "persian-modern",
        usePersianDigits: false,
      });
    }

    if (this.props.location.search) {
      this.getParamForFill();
      let parameters = queryString.parse(this.props.location.search);
      let route = [];
      for (
        let counter = 0;
        counter < parameters.originCodes.split("|").length;
        counter++
      ) {
        route.push(1);
      }
      this.setState({
        numberOfTrip: route,
      });
    } else {
      // this.setDates(0,new Date(moment(new Date().setHours(0,0)).add(1,'d')) );
      // this.setDates(1,new Date(moment(new Date().setHours(0,0)).add(2,'d')) );
    }
    if (this.props.inline) {
      this.setState({
        showMenu: false,
      });
    }
  }
  getParamForFill = () => {
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.adult !== undefined) {
      let modelLoad = {};
      modelLoad["adult"] = parseInt(parameters.adult);
      modelLoad["child"] = parseInt(parameters.child);
      modelLoad["infant"] = parseInt(parameters.infant);
      modelLoad["airTripType"] = parameters.airTripType;
      modelLoad["airlineCode"] = parameters.airlineCode || "all";
      modelLoad["cabinClassCode"] = parameters.cabinClassCode;
      modelLoad["departureDateTimes"] = parameters.departureDateTimes.split(
        "|"
      );
      modelLoad["destinationCodes"] = parameters.destinationCodes.split("|");
      modelLoad["destinationNames"] = parameters.destinationNames.split("|");
      modelLoad["nonStop"] = parameters.nonStop;
      modelLoad["originCodes"] = parameters.originCodes.split("|");
      modelLoad["originNames"] = parameters.originNames.split("|");
      modelLoad["airlineText"] = parameters.airlineText || "";
      let freezeURL = JSON.stringify(modelLoad);
      this.setState({
        defaultValue: JSON.parse(freezeURL),
        searchParam: modelLoad,
      });
    }
  };
  disabledDate = (index, current) => {
    // Can not select days before today and today
    if (index) {
      return (
        current &&
        current <= moment(this.state.searchParam.departureDateTimes[index - 1])
      );
    } else {
      return current && current <= moment().endOf("day").subtract(1, "day");
    }
  };
  setDates = (index, value) => {
    let dates = this.state.searchParam.departureDateTimes;
    dates[index] = moment(value);
    for (let counter = index + 1; counter < dates.length; counter++) {
      if (dates[index].isAfter(dates[counter])) {
        dates[counter] = dates[index];
      }
    }

    this.setState({
      searchParam: {
        ...this.state.searchParam,
        departureDateTimes: dates,
      },
    });
    // let cashDepartureDateTimes=this.state.searchParam.departureDateTimes;
    //
    // let cashMaxMin=this.state.range;
    // cashDepartureDateTimes[index]=value;
    // if(index<Object.keys(cashDepartureDateTimes).length -1){
    //     cashDepartureDateTimes[index+1]=value;
    // }
    // //if(index<cashMaxMin.length-1){
    //     cashMaxMin[index+1]={min:0,max:0};
    //     cashMaxMin[index+1].min=moment(value);
    // //}
    // this.setState({
    //         searchParam:{
    //             ...this.state.searchParam,
    //             departureDateTimes:cashDepartureDateTimes,
    //         },
    //         range:cashMaxMin
    //     })
  };
  getCallback = (value, text, name, id) => {
    let searchParamCatch = this.state.searchParam;
    searchParamCatch[name + "Names"][id] = text;
    searchParamCatch[name + "Codes"][id] = value;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };
  getAirline = (value, text) => {
    this.setState({
      searchParam: {
        ...this.state.searchParam,
        airlineCode: value ? value : "all",
        airlineText: text ? text : "all",
      },
    });
  };
  handleSearchParam = (type, value) => {
    let searchParamCash = this.state.searchParam;
    searchParamCash[type] = value;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCash },
    });
  };
  handleSearchButton = () => {
    this.setState({
      validation: true,
    });
    let statusForm = true;
    for (let obj in this.state.searchParam.departureDateTimes) {
      if (!this.state.searchParam.departureDateTimes[obj]) {
        statusForm = false;
      }
    }
    this.state.searchParam.originCodes.forEach((item) => {
      if (!item) {
        statusForm = false;
      }
    });
    this.state.searchParam.destinationCodes.forEach((item) => {
      if (!item) {
        statusForm = false;
      }
    });
    if (statusForm) {
      //preper URL search
      let dateArray = "";
      for (let date in this.state.searchParam.departureDateTimes) {
        let item = moment(
          this.state.searchParam.departureDateTimes[date]
        ).format("YYYY-MM-DD");
        parseInt(date) ? (dateArray += "|" + item) : (dateArray += item);
      }
      if (this.state.searchParam.airTripType === "OneWay"){
        dateArray = moment(
          this.state.searchParam.departureDateTimes[0]
        ).format("YYYY-MM-DD");
      }
      let originCodes = "";
      this.state.searchParam.originCodes.forEach((item, index) => {
        index ? (originCodes += "|" + item) : (originCodes += item);
      });
      let originNames = "";
      this.state.searchParam.originNames.forEach((item, index) => {
        index ? (originNames += "|" + item) : (originNames += item);
      });
      let destinationCodes = "";
      this.state.searchParam.destinationCodes.forEach((item, index) => {
        index ? (destinationCodes += "|" + item) : (destinationCodes += item);
      });
      let destinationNames = "";
      this.state.searchParam.destinationNames.forEach((item, index) => {
        index ? (destinationNames += "|" + item) : (destinationNames += item);
      });
      //validate passenger
      //sum of passenger greater than 9
      if (
        parseInt(this.state.searchParam.adult) +
          parseInt(this.state.searchParam.child) >
        9
      ) {
        this.props.showError({
          visibility: true,
          content: this.props.Dictionary
            .TheNumberOfPassengersShouldNotBeMoreThan9person,
        });
      } else if (
        parseInt(this.state.searchParam.adult) <
        parseInt(this.state.searchParam.infant)
      ) {
        this.props.showError({
          visibility: true,
          content: this.props.Dictionary.infantsMustBeLessThanAdults,
        });
      } else {
        let travelType = 'flight';
        if (this.props.domesticFlight){
          travelType="domesticflight"
        }
        const oldSearch  = this.props.location.search;
        const newSearch = `?originCodes=${originCodes}` +
        `&originNames=${originNames}` +
        `&destinationCodes=${destinationCodes}` +
        `&destinationNames=${destinationNames}` +
        `&departureDateTimes=${dateArray}` +
        `&adult=${this.state.searchParam.adult}` +
        `&child=${this.state.searchParam.child}` +
        `&infant=${this.state.searchParam.infant}` +
        `&cabinClassCode=${this.state.searchParam.cabinClassCode}` +
        `&airTripType=${this.state.searchParam.airTripType}` +
        `&nonStop=${this.state.searchParam.nonStop}` +
        `&airlineCode=${this.state.searchParam.airlineCode}` +
        `&airlineText=${this.state.searchParam.airlineText}`;
        if (oldSearch === newSearch ){
          this.props.onSearchRepeate();
        }else{
          this.props.history.push(`/${travelType}/result${newSearch}`);
        }
      }
    }
  };
  changeTypeOfTrip = (param) => {
    if (param !== "OpenJaw") {
      this.setState(
        {
          searchParam: {
            ...this.state.searchParam,
            airTripType: param,
          },
          numberOfTrip: [1],
        },
        () => {
          let searchParamCatch = this.state.searchParam;
          if (this.state.searchParam.airTripType === "OneWay") {
            searchParamCatch.departureDateTimes = [
              searchParamCatch.departureDateTimes[0],
            ];
          } else {
            searchParamCatch.departureDateTimes = [
              searchParamCatch.departureDateTimes[0],
              searchParamCatch.departureDateTimes[1],
            ];
          }
          if (this.state.searchParam.originCodes.length > 1) {
            searchParamCatch.originCodes = [searchParamCatch.originCodes[0]];
            searchParamCatch.originNames = [searchParamCatch.originNames[0]];
            searchParamCatch.destinationCodes = [
              searchParamCatch.destinationCodes[0],
            ];
            searchParamCatch.destinationNames = [
              searchParamCatch.destinationNames[0],
            ];
          }
          this.setState({
            searchParam: { ...this.state.searchParam, ...searchParamCatch },
          });
        }
      );
    } else {
      this.setState(
        {
          searchParam: {
            ...this.state.searchParam,
            airTripType: param,
          },
          numberOfTrip: [1, 2],
        },
        () => {
          let searchParamCatch = this.state.searchParam;
          searchParamCatch.originCodes.push(0);
          searchParamCatch.originNames.push(0);
          searchParamCatch.destinationCodes.push(0);
          searchParamCatch.destinationNames.push(0);
          this.setState({
            searchParam: { ...this.state.searchParam, ...searchParamCatch },
          });
        }
      );
    }
  };
  addFlight = () => {
    let numberOfTripCash = this.state.numberOfTrip;
    if (numberOfTripCash.length < 4) {
      numberOfTripCash.push(numberOfTripCash[numberOfTripCash.length - 1] + 1);
      let searchParamCatch = this.state.searchParam;
      searchParamCatch.originCodes.push(0);
      searchParamCatch.originNames.push(0);
      searchParamCatch.destinationCodes.push(0);
      searchParamCatch.destinationNames.push(0);
      searchParamCatch.departureDateTimes.push(0);
      // let range=this.state.range;
      // range.push({min:this.state.searchParam.departureDateTimes[Object.keys(this.state.searchParam.departureDateTimes).length-1],max:null});
      this.setState({
        numberOfTrip: numberOfTripCash,
        searchParam: { ...this.state.searchParam, ...searchParamCatch },
      });
    }
  };
  destToOrigin = (index) => {
    let newSearchParam = { ...this.state.searchParam };
    let newDefaultValue = { ...this.state.defaultValue };

    let originName = newSearchParam.originNames[index];
    let originCode = newSearchParam.originCodes[index];
    let destinationName = newSearchParam.destinationNames[index];
    let destinationCode = newSearchParam.destinationCodes[index];

    newSearchParam.originCodes[index] = destinationCode;
    newSearchParam.originNames[index] = destinationName;
    newSearchParam.destinationCodes[index] = originCode;
    newSearchParam.destinationNames[index] = originName;

    newDefaultValue.originCodes[index] = destinationCode;
    newDefaultValue.originNames[index] = destinationName;
    newDefaultValue.destinationCodes[index] = originCode;
    newDefaultValue.destinationNames[index] = originName;

    this.setState({
      searchParam: newSearchParam,
      defaultValue: newDefaultValue,
    });
  };
  removeFlight = (index) => {
    let numberOfTripCash = this.state.numberOfTrip;
    numberOfTripCash.splice(index, 1);
    let searchParamCatch = this.state.searchParam;
    searchParamCatch.originCodes.splice(index, 1);
    searchParamCatch.originNames.splice(index, 1);
    searchParamCatch.destinationCodes.splice(index, 1);
    searchParamCatch.destinationNames.splice(index, 1);
    searchParamCatch.departureDateTimes.splice(index, 1);
    this.setState({
      numberOfTrip: numberOfTripCash,
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };
  toggleDatepicker = () =>{
    if (this.props.LAN ==="IR"){
      if(this.state.datepickerType === "gregorian"){
        this.setState({
          datepickerType:"persian",
          datepickerLocale:"fa"
        })
      }else{
        this.setState({
          datepickerType:"gregorian",
          datepickerLocale:"en"
        })
      }
    }else{
      debugger;
    }
  }

  setAirline = (code,text) => {
    this.setState({
      searchParam : {...this.state.searchParam,
        airlineCode: code || "all",
        airlineText: text || "all"
      }
    })
  }
  
  render = () => {
    let formWrapperClassNames ='';
    if (!this.props.alwaysShowForm && this.props.inline){
      formWrapperClassNames="full-container";
    }

    let flightSearchMethod = Flight.GetLocation;
    if (this.props.domesticFlight){
      flightSearchMethod = Flight.GetDomesticLocation;
    }
    const { Dictionary, LAN } = this.props;
    
    const frequentDestinations = [
      {
        CityIdLocation: 51698,
        Code: "IKA",
        Latitude: 35.416111,
        Longitude: 51.152222,
        Name: "Imam Khomeini (IKA)",
        ParentName: "Tehran, Iran",
        SCORE: 10,
        Type: 2        
      },
      {
        CityIdLocation: 75286,
        Code: "IST",
        Latitude: 40.976111,
        Longitude: 28.813889,
        Name: "Istanbul, Turkey(IST-All Airports)",
        ParentName: "Istanbul (province), Turkey",
        SCORE: 8,
        Type: 1        
      },
      {
        CityIdLocation: 267,
        Code: "DXB",
        Latitude: 25.252778,
        Longitude: 55.364444,
        Name: "Dubai, United Arab Emirates(DXB-All Airports)",
        ParentName: "Dubai, United Arab Emirates",
        SCORE: 8,
        Type: 1
      },
      {
        CityIdLocation: 488,
        Code: "EVN",
        Latitude: 40.147275,
        Longitude: 44.395881,
        Name: "Yerevan",
        ParentName: "Armenia",
        SCORE: 9,
        Type: 1        
      },
      {
        CityIdLocation: 10143,
        Code: "YTO",
        Latitude: 43.65,
        Longitude: -79.383,
        Name: "Toronto, Canada(YTO-All Airports)",
        ParentName: "Ontario, Canada",
        SCORE: 8,
        Type: 1        
      },
      {
        CityIdLocation: 256,
        Code: "AUH",
        Latitude: 24.432972,
        Longitude: 54.651138,
        Name: "Abu Dhabi, United Arab Emirates(AUH-All Airports)",
        ParentName: "Abu Dhabi, United Arab Emirates",
        SCORE: 10,
        Type: 1        
      },
      {
        CityIdLocation: 64153,
        Code: "AMS",
        Latitude: 52.308613,
        Longitude: 4.763889,
        Name: "Amsterdam",
        ParentName: "Amsterdam & surrounding area, Netherlands",
        SCORE: 0,
        Type: 1        
      },
      {
        CityIdLocation: 39933,
        Code: "PAR",
        Latitude: 48.867,
        Longitude: 2.333,
        Name: "Paris, France(PAR-All Airports)",
        ParentName: "Île de France, France",
        SCORE: 10,
        Type: 1        
      },
      {
        CityIdLocation: 20460,
        Code: "FRA",
        Latitude: 50.033306,
        Longitude: 8.570456,
        Name: "Frankfurt am Main, Germany(FRA-All Airports)",
        ParentName: "Rhine-Main Region, Germany",
        SCORE: 8,
        Type: 1        
      },
      {
        CityIdLocation: 40977,
        Code: "LON",
        Latitude: 51.5,
        Longitude: -0.167,
        Name: "London, United Kingdom(LON-All Airports)",
        ParentName: "London & surrounding area, United Kingdom",
        SCORE: 10,
        Type: 1        
      },
      {
        CityIdLocation: 77506,
        Code: "LAX",
        Latitude: 33.942821,
        Longitude: -118.409276,
        Name: "Los Angeles, United States(LAX-All Airports)",
        ParentName: "California, United States",
        SCORE: 8,
        Type: 1
      }
    ];

    const frequentDomesticDestinations = [
      {
        CityIdLocation: 51698,
        Code: "THR",
        Latitude: 35.689167,
        Longitude: 51.313416,
        Name: "Mehrabad (THR)",
        ParentName: "Tehran, Iran",
        SCORE: 10,
        Type: 2
      },
      {
        CityIdLocation: 51687,
        Code: "MHD",
        Latitude: 36.235195,
        Longitude: 59.640972,
        Name: "Mashhad",
        ParentName: "Iran",
        Score: 9,
        Type: 1   
      },
      {
        CityIdLocation: 51686,
        Code: "KIH",
        Latitude: 26.526156,
        Longitude: 53.980211,
        Name: "Kish Island",
        ParentName: "Iran",
        Score: 9,
        Type: 1
      },
      {
        CityIdLocation: 51696,
        Code: "SYZ",
        Latitude: 29.539242,
        Longitude: 52.589786,
        Name: "Shiraz",
        ParentName: "Iran",
        Score: 9,
        Type: 1     
      },
      {
        CityIdLocation: 51675,
        Code: "AWZ",
        Latitude: 31.337431,
        Longitude: 48.76195,
        Name: "Ahvaz",
        ParentName: "Iran",
        Score: 9,
        Type: 1
      },
      {
        CityIdLocation: 51681,
        Code: "IFN",
        Latitude: 32.750836,
        Longitude: 51.861267,
        Name: "Isfahan",
        ParentName: "Iran",
        Score: 9,
        Type: 1        
      },
      {
        CityIdLocation: 51677,
        Code: "BND",
        Latitude: 27.218317,
        Longitude: 56.37785,
        Name: "Bandar Abbas",
        ParentName: "Iran",
        Score: 9,
        Type: 1        
      },
      {
        CityIdLocation: 1311217,
        Code: "PGU",
        Latitude: 27.379589,
        Longitude: 52.737667,
        Name: "Asalouyeh",
        ParentName: "Bushehr, Iran",
        Score: 9,
        Type: 1        
      },
      {
        CityIdLocation: 51697,
        Code: "TBZ",
        Latitude: 38.133889,
        Longitude: 46.235,
        Name: "Tabriz",
        ParentName: "Iran",
        Score: 9,
        Type: 1        
      },
      {
        CityIdLocation: 51690,
        Code: "GSM",
        Latitude: 26.754639,
        Longitude: 55.902353,
        Name: "Qeshm",
        ParentName: "Iran",
        Score: 0,
        Type: 1        
      }
    ];

    const datepickerConfig = {
      calendarPosition : LAN === "IR" || LAN === "AR" ?"bottom-right":"bottom-left",
      weekDays:this.state.datepickerLocale === "fa" ? [
        "ش", 
        "ی", 
        "د", 
        "س", 
        "چ", 
        "پ", 
        "ج"
      ]:this.state.datepickerLocale === "ar" ?[
        "الأحد",
        "الإثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت"
      ]:undefined,
      digits: this.state.datepickerLocale === "ar" ? [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9"
      ]:undefined,
      className:`multi-datepicker ${this.state.isMobile?"rmdp-mobile":""} ${LAN === "IR" && this.state.datepickerLocale === "en" ? "en-font":""}`
    };

    const isNaminTravel = process.env.REACT_APP_THEME ==='NAMINTRAVELCOM';

    let destinationBlockMediumSpan = 7;
    let submitBlockMediumSpan = 2;

    if (this.props.inline && LayoutConfig.themeClassName==="style-2" && !this.props.domesticFlight){
      destinationBlockMediumSpan = 6;
      submitBlockMediumSpan = 1;
    }
    // if (isNaminTravel){
    //   destinationBlockMediumSpan = 6;
    //   submitBlockMediumSpan = 1;
    // }

    return !this.state.showMenu && !this.props.alwaysShowForm ? (
      <div className={"searchShow"}>
        <div className="originShow">
          <b>{this.state.defaultValue.originCodes[0]}</b>
          <div>{this.props.originCity}</div>
        </div>
        <div className="typeShow">
          {this.state.searchParam.airTripType === "RoundTrip" ? (
            <img
              src={returnway}
              alt="return way tip"
              title="return way trip"
              className="rotate-rtl"
            />
          ) : (
            <img
              src={oneway}
              alt={"one way trip"}
              title="one way trip"
              className="rotate-rtl"
            />
          )}
        </div>
        <div className="destinationShow text-end">
          <b>
            {
              this.state.defaultValue.destinationCodes[
                this.state.defaultValue.destinationCodes.length - 1
              ]
            }
          </b>
          <div>{this.props.destinationCity}</div>
        </div>
        <div className="seperator"></div>
        <div className="depShow text-start">
          <div className="gray-label">{Dictionary.departureDate}</div>
          {LAN === "IR" ? (
            <>
              <div className="searched-value shamsi-date">
                {" "}
                {moment(this.state.defaultValue.departureDateTimes[0]).format(
                  "jYYYY-jMM-jDD"
                )}
              </div>
              <small>
                (
                {moment(this.state.defaultValue.departureDateTimes[0]).format(
                  "YYYY-MM-DD"
                )}
                ){" "}
              </small>
            </>
          ) : (
            <div className="searched-value">
              {moment(this.state.defaultValue.departureDateTimes[0]).format(
                "YYYY-MM-DD"
              )}
            </div>
          )}
        </div>
        {this.state.searchParam.airTripType === "OpenJaw" && (
          <div className="retShow text-start">
            <div className="gray-label">{Dictionary.ArrivalDate}</div>
            {LAN === "IR" ? (
              <>
                <div className="searched-value shamsi-date">
                  {" "}
                  {moment(
                    this.state.defaultValue.departureDateTimes[
                      this.state.defaultValue.departureDateTimes.length - 1
                    ]
                  ).format("jYYYY-jMM-jDD")}
                </div>
                <small>
                  (
                  {moment(
                    this.state.defaultValue.departureDateTimes[
                      this.state.defaultValue.departureDateTimes.length - 1
                    ]
                  ).format("YYYY-MM-DD")}
                  ){" "}
                </small>
              </>
            ) : (
              <div className="searched-value">
                {moment(
                  this.state.defaultValue.departureDateTimes[
                    this.state.defaultValue.departureDateTimes.length - 1
                  ]
                ).format("YYYY-MM-DD")}
              </div>
            )}
          </div>
        )}

        {this.state.searchParam.airTripType === "RoundTrip" && (
          <div className="retShow text-start">
            <div className="gray-label">{Dictionary.returnDate}</div>
            {LAN === "IR" ? (
              <>
                <div className="searched-value shamsi-date">
                  {" "}
                  {moment(
                    this.state.defaultValue.departureDateTimes[
                      this.state.defaultValue.departureDateTimes.length - 1
                    ]
                  ).format("jYYYY-jMM-jDD")}
                </div>
                <small>
                  (
                  {moment(
                    this.state.defaultValue.departureDateTimes[
                      this.state.defaultValue.departureDateTimes.length - 1
                    ]
                  ).format("YYYY-MM-DD")}
                  ){" "}
                </small>
              </>
            ) : (
              <div className="searched-value">
                {moment(
                  this.state.defaultValue.departureDateTimes[
                    this.state.defaultValue.departureDateTimes.length - 1
                  ]
                ).format("YYYY-MM-DD")}
              </div>
            )}
          </div>
        )}

        <div className="passShow text-start">
          <div className="gray-label">{Dictionary.passengersQuantity}</div>
          <strong className="searched-value">
            <>
              <span>{this.state.defaultValue.adult} </span>
              <span> {this.state.defaultValue.adult > 1 ? Dictionary.adults : Dictionary.adult}</span>

              {this.state.defaultValue.child > 0 && <>
                  <span> + {this.state.defaultValue.child} </span>
                  <span>{this.state.defaultValue.child > 1 ? Dictionary.children : Dictionary.child}</span>
              </>}
          
              {this.state.defaultValue.infant > 0 && <>
              <span> + {this.state.defaultValue.infant} </span>
              {this.state.defaultValue.infant > 1 ? Dictionary.infants : Dictionary.infant}
              </>}

          </>
          
          </strong>
        </div>
        <div className="showSearch">
          <button
            type="button"
            className={`button ${isNaminTravel?"namin-yellow-btn":"color-1-btn"}`}
            onClick={() => this.setState({ showMenu: true })}
          >
            {Dictionary.modifySearch}
          </button>
        </div>
      </div>
    ) : (
      <div className={formWrapperClassNames}>
        <div className={this.props.inline && "search-container"}>
          <div className="search-box-holder">
            {LayoutConfig.themeClassName === 'style-2' && <SearchBoxTab searchType={this.props.domesticFlight?"flight":"foreignFlight"} />}
            <div className="searchbox-inner">
              <div className="triptype-radio-group">
                <div className="triptype-radio-item">
                  <input
                      className="triptype-radio"
                      value={"OneWay"}
                      type="radio"
                      name="triptype"
                      checked={this.state.searchParam.airTripType === "OneWay"}
                      onChange={() => this.changeTypeOfTrip("OneWay")}
                  />
                  <label className="triptype-radio-label">
                    {Dictionary.oneway}
                  </label>
                </div>                
                <div className="triptype-radio-item">
                  <input
                  className="triptype-radio"
                  value={"RoundTrip"}
                  type="radio"
                  name="triptype"
                  checked={this.state.searchParam.airTripType === "RoundTrip"}
                  onChange={() => this.changeTypeOfTrip("RoundTrip")}
                  />
                  <label className="triptype-radio-label">
                  {Dictionary.returnway}
                  </label>
                </div>
                {this.props.domesticFlight? null:
                <div className="triptype-radio-item">
                  <input
                  className="triptype-radio"
                  value={"OpenJaw"}
                  type="radio"
                  name="triptype"
                  checked={this.state.searchParam.airTripType === "OpenJaw"}
                  onChange={() => this.changeTypeOfTrip("OpenJaw")}
                  />
                  <label className="triptype-radio-label">
                  {Dictionary.openjawway}
                  </label>
                </div>
                }


                {this.props.inline && (
                  <div className="cancelShow">
                    <button
                      type={"button"}
                      className="button show-close-btn"
                      onClick={() => {
                        this.setState({ showMenu: false });
                      }}
                    >
                      <span className="itours-icon gray-close-icon icon-large" />
                    </button>
                  </div>
                )}
              </div>
              <div className="search-bar">
                <div className="float-row half-gap">
                  <div className={`col-xsmall-12 col-small-12 col-large-${destinationBlockMediumSpan} ${LayoutConfig.themeClassName==="style-2"?"margin-bottom-half":"margin-bottom"}`}>
                    {this.state.numberOfTrip.map((item, index) => {
                      return (
                        <div className={this.state.searchParam.airTripType === 'OpenJaw' ? 'multy-dest-route' : null} key={index}>
                          {index > 0 ? (
                            <label className={`itinerary-label ${isNaminTravel?"yellow":""}`}>
                              <span className={'nowrap'}>{Dictionary.flight + ' ' + (index + 1)}</span>
                              <span className={`line ${isNaminTravel?"yellow":""}`} />
                            </label>
                          ) : null}
                          <div className={index > 1 ? 'deleteable-route search-route-item' : 'search-route-item'}>
                            <div className="origin-destination-block relative">
                              <div className="flight-origin-holder relative">
                                <Autocomplete
                                  frequentDestinations={
                                    LAN !== 'IR' ? undefined : this.props.domesticFlight ? frequentDomesticDestinations : frequentDestinations
                                  }
                                  defaultValue={this.state.defaultValue.originCodes[index] ? this.state.defaultValue.originCodes[index] : null}
                                  placeholder={Dictionary.origin}
                                  defaultText={this.state.defaultValue.originNames[index] ? this.state.defaultValue.originNames[index] : null}
                                  getValue={this.getCallback.bind(this)}
                                  name={'origin'}
                                  index={index}
                                  type={'flight'}
                                  validate={true}
                                  url={ServerAddress.Type + ServerAddress.Flight + flightSearchMethod}
                                  min={3}
                                  param="value"
                                  header={Header}
                                />
                                {this.state.validation && !this.state.searchParam.originCodes[index] && (
                                  <b className="validator-label">{Dictionary.pleaseEnterOrigin}</b>
                                )}
                              </div>
                              <div className="flight-destination-holder relative">
                                <Autocomplete
                                  frequentDestinations={
                                    LAN !== 'IR' ? undefined : this.props.domesticFlight ? frequentDomesticDestinations : frequentDestinations
                                  }
                                  defaultValue={this.state.defaultValue.destinationCodes[index] && this.state.defaultValue.destinationCodes[index]}
                                  placeholder={Dictionary.destination}
                                  defaultText={this.state.defaultValue.destinationNames[index]}
                                  getValue={this.getCallback.bind(this)}
                                  name={'destination'}
                                  index={index}
                                  type={'flight'}
                                  validate={true}
                                  url={ServerAddress.Type + ServerAddress.Flight + flightSearchMethod}
                                  min={3}
                                  param="value"
                                  header={Header}
                                />
                                {this.state.validation && !this.state.searchParam.destinationCodes[index] && (
                                  <b className="validator-label">{Dictionary.pleaseEnterDestination}</b>
                                )}
                              </div>
                              <button type="button" className="swap-vert" onClick={() => this.destToOrigin(index)} />
                            </div>
                            <div
                              className={
                                this.state.searchParam.airTripType === 'OpenJaw'
                                  ? 'relative date-block departure-date multy-destination'
                                  : this.state.searchParam.airTripType === 'OneWay'
                                  ? 'relative date-block departure-date one-way'
                                  : 'relative date-block departure-date'
                              }>
                              <div className={this.state.searchParam.departureDateTimes[index] ? 'styled-date-holder' : null}>
                                {/* {LAN === "IR" || LAN === "AR" ? ( */}
                                <>
                                  <MultiDatePicker
                                    ref={element => {
                                      if (index===0){
                                        this.departureRef = element;
                                      }else if (index===1){
                                        this.departureRef2 = element;
                                      }else if (index===2){
                                        this.departureRef3 = element;
                                      }else if (index===3){
                                        this.departureRef4 = element;
                                      }
                                    }}
                                    calendarPosition={datepickerConfig.calendarPosition}
                                    weekDays={datepickerConfig.weekDays}
                                    digits={datepickerConfig.digits}
                                    inputClass="multi-datepicker-input"
                                    className={datepickerConfig.className}
                                    minDate={
                                      index && this.state.searchParam.departureDateTimes[index - 1]
                                        ? this.state.datepickerType === 'persian'
                                          ? moment(this.state.searchParam.departureDateTimes[index - 1]).format('jYYYY/jMM/jDD')
                                          : moment(this.state.searchParam.departureDateTimes[index - 1]).format('YYYY/MM/DD')
                                        : this.state.datepickerType === 'persian'
                                        ? moment().format('jYYYY/jMM/jDD')
                                        : moment().format('YYYY/MM/DD')
                                    }
                                    format={'YYYY-MM-DD'}
                                    calendar={this.state.datepickerType}
                                    locale={this.state.datepickerLocale}
                                    value={
                                      this.state.searchParam.departureDateTimes[index] &&
                                      (this.state.datepickerType === 'persian'
                                        ? moment(this.state.searchParam.departureDateTimes[index]).format('jYYYY/jMM/jDD')
                                        : moment(this.state.searchParam.departureDateTimes[index]).format('YYYY/MM/DD'))
                                    }
                                    onFocusedDateChange={d => {
                                      const e = d.toDate();
                                      this.setDates(index, e);
                                      if (index===0){
                                        this.departureRef.closeCalendar();
                                      }else if (index===1){
                                        this.departureRef2.closeCalendar();
                                      }else if (index===2){
                                        this.departureRef3.closeCalendar();
                                      }else if (index===3){
                                        this.departureRef4.closeCalendar();
                                      }
                                      
                                    }}
                                    placeholder={this.state.searchParam.airTripType === 'RoundTrip' ? Dictionary.departure : Dictionary.flightDate}>
                                    {this.state.isMobile && (
                                      <div className="date-picker-title">
                                        {this.state.searchParam.airTripType === 'RoundTrip' ? Dictionary.departureFlightDate : Dictionary.flightDate}
                                      </div>
                                    )}
                                    {LAN === 'IR' && (
                                      <button
                                        type="button"
                                        className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font"
                                        onClick={this.toggleDatepicker}>
                                        {this.state.datepickerLocale === 'fa' ? 'تقویم میلادی' : 'تقویم شمسی'}
                                      </button>
                                    )}
                                      {this.state.isMobile && (
                                        <button className={'close-datepicker-btn'} onClick={() => {
                                          if (index===0){
                                            this.departureRef.closeCalendar();
                                          }else if (index===1){
                                            this.departureRef2.closeCalendar();
                                          }else if (index===2){
                                            this.departureRef3.closeCalendar();
                                          }else if (index===3){
                                            this.departureRef4.closeCalendar();
                                          }
                                        }}>
                                          <span className={`itours-icon gray-close-icon icon-large`} />
                                        </button>
                                      )}                                    
                                  </MultiDatePicker>

                                  {this.state.searchParam.departureDateTimes[index] ? (
                                    LAN === 'IR' ? (
                                      <div className="styled-date-inner-wrapper">
                                        <div className="styled-date styled-date-month">
                                          {this.state.datepickerType === 'gregorian'
                                            ? this.showGregorianMonth(moment(this.state.searchParam.departureDateTimes[index]))
                                            : this.showPersianMonth(moment(this.state.searchParam.departureDateTimes[index]))}
                                        </div>
                                        <div className="styled-date styled-date-date">
                                          {this.state.datepickerType === 'gregorian'
                                            ? moment(this.state.searchParam.departureDateTimes[index]).format('DD')
                                            : moment(this.state.searchParam.departureDateTimes[index]).format('jDD')}
                                        </div>
                                        <div className="styled-date styled-date-day">
                                          {this.state.datepickerType === 'gregorian'
                                            ? this.showGregorianDate(moment(this.state.searchParam.departureDateTimes[index]))
                                            : this.showPersianDate(moment(this.state.searchParam.departureDateTimes[index]))}
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="styled-date-inner-wrapper">
                                        <div className="styled-date styled-date-month">
                                          {moment(this.state.searchParam.departureDateTimes[index]).format('MMMM')}
                                        </div>
                                        <div className="styled-date styled-date-date">
                                          {moment(this.state.searchParam.departureDateTimes[index]).format('DD')}
                                        </div>
                                        <div className="styled-date styled-date-day">
                                          {moment(this.state.searchParam.departureDateTimes[index]).format('dddd')}
                                        </div>
                                      </div>
                                    )
                                  ) : null}
                                </>
                                {/* ) : (
                                  <>
                                    <DatePicker
                                      defaultPickerValue={
                                        this.state.searchParam
                                          .departureDateTimes[index] !== 0
                                          ? moment(
                                              this.state.searchParam
                                                .departureDateTimes[index]
                                            )
                                          : null
                                      }
                                      placeholder={Dictionary.departure}
                                      format={"YYYY-MM-DD"}
                                      showTime={false}
                                      disabledDate={this.disabledDate.bind(
                                        this,
                                        index
                                      )}
                                      onChange={(e) => {
                                        this.setDates(
                                          index,
                                          e ? new Date(e._d) : 0
                                        );
                                      }}
                                    />
                                    {this.state.searchParam.departureDateTimes[
                                      index
                                    ] ? (
                                      <>
                                        <div className="styled-date styled-date-month">
                                          {moment(
                                            this.state.searchParam
                                              .departureDateTimes[index]
                                          ).format("MMMM")}
                                        </div>
                                        <div className="styled-date styled-date-date">
                                          {moment(
                                            this.state.searchParam
                                              .departureDateTimes[index]
                                          ).format("DD")}
                                        </div>
                                        <div className="styled-date styled-date-day">
                                          {moment(
                                            this.state.searchParam
                                              .departureDateTimes[index]
                                          ).format("dddd")}
                                        </div>
                                      </>
                                    ) : null}
                                  </>
                                )} */}
                                {this.state.validation && !this.state.searchParam.departureDateTimes[index] && (
                                  <b className="validator-label">{Dictionary.pleaseEnterDepartureDate}</b>
                                )}
                              </div>
                            </div>

                            {this.state.searchParam.airTripType === 'RoundTrip' ? (
                              <div className="date-block return-date">
                                <div className="relative">
                                  <div className={this.state.searchParam.departureDateTimes[index + 1] ? 'styled-date-holder' : null}>
                                    {/* {LAN === "IR" || LAN ==="AR" ? ( */}
                                    <>
                                      <MultiDatePicker
                                        ref={element => {this.returnRef = element;}}
                                        calendarPosition={datepickerConfig.calendarPosition}
                                        weekDays={datepickerConfig.weekDays}
                                        digits={datepickerConfig.digits}
                                        inputClass="multi-datepicker-input"
                                        className={datepickerConfig.className}
                                        minDate={
                                          this.state.datepickerType === 'persian'
                                            ? moment(this.state.searchParam.departureDateTimes[0]).format('jYYYY/jMM/jDD')
                                            : moment(this.state.searchParam.departureDateTimes[0]).format('YYYY/MM/DD')

                                          // this.state.datepickerType ==="persian"? moment().format("jYYYY/jMM/jDD") : moment().format("YYYY/MM/DD")
                                        }
                                        format={'YYYY-MM-DD'}
                                        calendar={this.state.datepickerType}
                                        locale={this.state.datepickerLocale}
                                        value={
                                          this.state.searchParam.departureDateTimes[index + 1]
                                            ? this.state.datepickerType === 'persian'
                                              ? moment(this.state.searchParam.departureDateTimes[index + 1]).format('jYYYY/jMM/jDD')
                                              : moment(this.state.searchParam.departureDateTimes[index + 1]).format('YYYY/MM/DD')
                                            : undefined

                                          //this.state.datepickerType ==="persian"? moment(this.state.searchParam.checkin,"YYYY/MM/DD").format("jYYYY/jMM/jDD"): this.state.searchParam.checkin
                                        }
                                        onFocusedDateChange={d => {
                                          const e = d.toDate();
                                          this.setDates(index + 1, e);
                                          this.returnRef.closeCalendar();
                                        }}                                        
                                        placeholder={Dictionary.return}>
                                        {this.state.isMobile && (
                                          <div className="date-picker-title">{Dictionary.returnFlightDate}</div>
                                        )} 
                                        {LAN === 'IR' && (
                                          <button
                                            type="button"
                                            className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font"
                                            onClick={this.toggleDatepicker}>
                                            {this.state.datepickerLocale === 'fa' ? 'تقویم میلادی' : 'تقویم شمسی'}
                                          </button>
                                        )}
                                        {this.state.isMobile && (
                                          <button className={'close-datepicker-btn'} onClick={() => this.returnRef.closeCalendar()}>
                                            <span className={`itours-icon gray-close-icon icon-large`} />
                                          </button>
                                        )}
                                      </MultiDatePicker>
                                      {this.state.searchParam.departureDateTimes[index + 1] ? (
                                        LAN === 'IR' ? (
                                          <div className="styled-date-inner-wrapper">
                                            <div className="styled-date styled-date-month">
                                              {this.state.datepickerType === 'gregorian'
                                                ? this.showGregorianMonth(moment(this.state.searchParam.departureDateTimes[index + 1]))
                                                : this.showPersianMonth(moment(this.state.searchParam.departureDateTimes[index + 1]))}
                                            </div>
                                            <div className="styled-date styled-date-date">
                                              {this.state.datepickerType === 'gregorian'
                                                ? moment(this.state.searchParam.departureDateTimes[index + 1]).format('DD')
                                                : moment(this.state.searchParam.departureDateTimes[index + 1]).format('jDD')}
                                            </div>
                                            <div className="styled-date styled-date-day">
                                              {this.state.datepickerType === 'gregorian'
                                                ? this.showGregorianDate(moment(this.state.searchParam.departureDateTimes[index + 1]))
                                                : this.showPersianDate(moment(this.state.searchParam.departureDateTimes[index + 1]))}
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="styled-date-inner-wrapper">
                                            <div className="styled-date styled-date-month">
                                              {moment(this.state.searchParam.departureDateTimes[index + 1]).format('MMMM')}
                                            </div>
                                            <div className="styled-date styled-date-date">
                                              {moment(this.state.searchParam.departureDateTimes[index + 1]).format('DD')}
                                            </div>
                                            <div className="styled-date styled-date-day">
                                              {moment(this.state.searchParam.departureDateTimes[index + 1]).format('dddd')}
                                            </div>
                                          </div>
                                        )
                                      ) : null}
                                    </>
                                    {/* ) : (
                                        <>
                                          <DatePicker
                                            defaultPickerValue={
                                              this.state.searchParam
                                                .departureDateTimes[index + 1] !==
                                              0
                                                ? moment(
                                                    this.state.searchParam
                                                      .departureDateTimes[
                                                      index + 1
                                                    ]
                                                  )
                                                : null
                                            }
                                            format={"YYYY-MM-DD"}
                                            placeholder={Dictionary.return2}
                                            showTime={false}
                                            disabledDate={this.disabledDate.bind(
                                              this,
                                              index + 1
                                            )}
                                            onChange={(e) => {
                                              this.setDates(
                                                index + 1,
                                                e ? new Date(e._d) : 0
                                              );
                                            }}
                                          />
                                          {this.state.searchParam
                                            .departureDateTimes[index + 1] ? (
                                            <>
                                              <div className="styled-date styled-date-month">
                                                {moment(
                                                  this.state.searchParam
                                                    .departureDateTimes[index + 1]
                                                ).format("MMMM")}
                                              </div>
                                              <div className="styled-date styled-date-date">
                                                {moment(
                                                  this.state.searchParam
                                                    .departureDateTimes[index + 1]
                                                ).format("DD")}
                                              </div>
                                              <div className="styled-date styled-date-day">
                                                {moment(
                                                  this.state.searchParam
                                                    .departureDateTimes[index + 1]
                                                ).format("dddd")}
                                              </div>
                                            </>
                                          ) : null}
                                        </>
                                      )} */}
                                  </div>
                                  {this.state.validation && !this.state.searchParam.departureDateTimes[index + 1] && (
                                    <b className="validator-label">{Dictionary.pleaseEnterReturnDate}</b>
                                  )}
                                  <span className="remove-return-btn" onClick={this.changeTypeToOneWay}>
                                    -
                                  </span>
                                </div>
                              </div>
                            ) : this.state.searchParam.airTripType === 'OneWay' ? (
                              <div className="date-block fake-return-date relative text-center uppercase" onClick={this.changeTypeToReturn}>
                                {Dictionary.addReturn}
                              </div>
                            ) : null}
                            {index > 1 && (
                              <button
                                type="button"
                                className="delete-route margin-bottom"
                                onClick={() => {
                                  this.removeFlight(index);
                                }}>
                                <span className="itours-icon close-icon icon-small" />
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {this.state.searchParam.airTripType === "OpenJaw" &&
                      this.state.numberOfTrip.length < 4 && (
                        <div className="add-destination-holder">
                          <div className="form-group">
                            <button
                              className="button link-style-btn"
                              type={"button"}
                              onClick={() => this.addFlight()}
                            >
                              <span className={`margin-end-light itours-icon ${isNaminTravel?"plus-blue-icon":"plus-simple-icon"}`} />
                              {Dictionary.addNewDestination}
                            </button>
                          </div>
                        </div>
                      )}
                  </div>                  
                  <div className={`col-xsmall-12 col-small-12 col-large-${this.props.inline && LayoutConfig.themeClassName==="style-2" && !this.props.domesticFlight ?5:3}
                   ${LayoutConfig.themeClassName==="style-2"?"margin-bottom-half":"margin-bottom"} 
                  ${this.props.domesticFlight?"":"passengers-and-cabinClass"}
                  `}>
                    {(isNaminTravel || !this.props.domesticFlight) && <div className={isNaminTravel && !this.props.domesticFlight ? "cabinClass-and-airline" :""}>

                      {isNaminTravel && <div className="search-airline-block">
                        <Tooltip title={Dictionary.airline}>
                          <div>
                            <Autocomplete
                              defaultValue={this.state.defaultValue.airlineCode}
                              placeholder={Dictionary.airline}
                              defaultText={this.state.defaultValue.airlineText}
                              getValue={this.setAirline.bind(this)}
                              name='airline'
                              type='airline'
                              url= {ServerAddress.Type + ServerAddress.Flight + Flight.GetAirline}
                              min={2}
                              param="value"
                              header={Header}
                            />
                          </div>
                        </Tooltip>
                      </div>} 

                      {!this.props.domesticFlight && <div className={`form-group ${LayoutConfig.themeClassName==="style-2"?"":"no-margin-bottom"} select-class-block`}>
                        <select
                          defaultValue={this.state.defaultValue.cabinClassCode}
                          className="form-select class-select"
                          onChange={(e) =>
                            this.handleSearchParam(
                              "cabinClassCode",
                              e.target.value
                            )
                          }
                        >
                          {/* <option value="all">{Dictionary.allClasses}</option> */}
                          <option value="Economy">{Dictionary.economy}</option>
                          <option value="Premium">{Dictionary.premium}</option>
                          <option value="Business">{Dictionary.business}</option>
                          <option value="Firstclass">
                            {Dictionary.firstClass}
                          </option>
                        </select>
                      </div>
                      }
                    </div>}

                    <SelectPassenger
                      close={this.closeSelectPassenger}
                      removePassenger={this.removePassenger}
                      addPassenger={this.addPassenger}
                      toggleSelectPassengers={this.toggleSelectPassengers}
                      handleSearchParam={this.handleSearchParam}
                      adults={this.state.searchParam.adult}
                      children={this.state.searchParam.child}
                      infants={this.state.searchParam.infant}
                      selectPassengersShow={this.state.selectPassengersShow}
                      largeSelectPassenger={this.props.domesticFlight && !isNaminTravel}
                    />
                  </div>
      
                  <div className={`col-xsmall-12 col-small-12 col-large-${submitBlockMediumSpan} margin-bottom`}>
                    <button
                      type="button"
                      className="thickSearchBtn button red-btn pull-end full-width"
                      onClick={() => this.handleSearchButton()}
                    >
                      <span className="itours-icon search-icon" />
                      <span className={"btn-text"}> {Dictionary.search} </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => dispatch(showError(param)),
});
const RouterPath = withRouter(SearchForm);
export default connect(mapStateToProps, mapDispatchToProps)(RouterPath);
