const initialFilterState = {
    name: "",
    starRating: [],
    facilities: [],
    onlyAvailable: false,
    priceRange: [],
    reviewScore: [],
    accommodationType: []
};

const initialState = {
    selectedFilters: initialFilterState,
    resrevedDomesticHotel : undefined,
    domesticHotelByIdInfo : undefined
}

export const DomesticHotel = (state = initialState, action) => {
    switch (action.type) {

        case "SetDomesticHotelFilter":
            const updatedSelectedFilters = { ...state.selectedFilters };
            switch (action.filterType) {

                case "reset":
                    return {
                        ...state,
                        selectedFilters: initialFilterState
                    }

                case "name":
                    updatedSelectedFilters.name = action.value;
                    break;

                case "availability":
                    updatedSelectedFilters.onlyAvailable = action.value;
                    break;

                case "price":
                    updatedSelectedFilters.priceRange = action.value;
                    break;

                case "rating":
                    updatedSelectedFilters.starRating = action.value;
                    break;

                case "facility":
                    updatedSelectedFilters.facilities = action.value;
                    break;

                case "accommodationType":
                    updatedSelectedFilters.accommodationType = action.value;
                    break;

                case "reviewScore":
                    updatedSelectedFilters.reviewScore = action.value;
                    break;

                default:
                    debugger;
            }

            return {
                ...state,
                selectedFilters: updatedSelectedFilters
            }
        case "SetReservedDomesticHotel":
            return {
                ...state,
                resrevedDomesticHotel: action.data
            }
        case "SetDomesticHotelByIdInfo":
            return {
                ...state,
                domesticHotelByIdInfo: action.data
            }            
        default:
            return state;
    }
}