import {UserModel,Reducers} from '../Enum/Models';
import Currency from '../Enum/Currency';
const userDataStorage=localStorage.getItem("userData");
const userData=userDataStorage? JSON.parse(userDataStorage):UserModel;

const DefaultUserData={token:null,userData:userData,Currency:Currency.getCurrency(),userBalance:null};
export const UserData=(state=DefaultUserData,action)=>{
    switch(action.type){
        case Reducers.LOGOUT:
            return {
                ...state,
                token:null,
                userData:null,
                userBalance:null
            };
        case Reducers.CREATEUSER:
            return {
                ...state,
                userData:action.userData
            };

        case Reducers.SETCURRENCY:
            return{
                ...state,
                Currency:action.Currency
            }
        case Reducers.GET_BALANCE:
            return {
                ...state,
                userBalance: action.data
        }
        default:
            return state;

    }
}