import { useState, useEffect, useRef } from 'react';
import moment from 'moment-jalaali';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';

import Loading from '../../Common/Loading';
import { showError, toggleVarification } from '../../../Actions/VisualDataAction';
import VerificationTimer from './VerificationTimer';
import { sendOTP, RegisterOrLogin, getUserAsync,getBalance } from '../../../Actions/UserAction';

const Verification = (props) => {
  const [loading, setLoading] = useState(false);
  const [timeIsOver, setTimeIsOver] = useState(false);
  const [timerValue, setTimerValue] = useState();
  const [incorectCode,setIncorectCode] = useState(false);

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();

  const dispatch = useDispatch();

  let userNameValue = props.userName;
  if (/^\d+$/.test(userNameValue)) {
    if (userNameValue[0] === '0') {
      userNameValue = userNameValue.substring(1);
    }
    userNameValue = `+98${userNameValue}`;
  }

  const sendOtpRequest = async () => {
    setLoading('sendingCode');
    setTimerValue();
    setIncorectCode(false);
    const otpRes = await sendOTP({ emailOrPhoneNumber: userNameValue },process.env.REACT_APP_SERVER_CULTURE === "IR" ? 'fa-IR' : 'en-US');
    if (otpRes.data?.success) {
      localStorage.setItem('otpExp', JSON.stringify({ username: props.userName, time: moment().add(1, 'minutes').format() }));
      setTimerValue(60);
      setTimeIsOver(false);
      setLoading(false);
    } else {
      setLoading(false);
      props.changeModal('usernameModal');
      dispatch(
        showError({
          visibility: true,
          content: otpRes.message,
          closable:true
        })
      );
    }
  };

  useEffect(() => {
    const lastRequestExp = JSON.parse(localStorage.getItem('otpExp'));
    if (!lastRequestExp || props.userName !== lastRequestExp.username || moment().isAfter(lastRequestExp.time)) {
      sendOtpRequest();
    } else {
      const remainedSecounds = -moment().diff(lastRequestExp.time, 'seconds');
      setTimerValue(remainedSecounds);
    }
    inputRef1.current?.focus();
  }, []);

  const resendCode = () => {
    sendOtpRequest();
    inputRef1.current.value = '';
    inputRef2.current.value = '';
    inputRef3.current.value = '';
    inputRef4.current.value = '';
    inputRef5.current.value = '';
    inputRef6.current.value = '';

    inputRef1.current.focus();
  };

  const changeValue = (e) => {
    switch (e) {
      case 1:
        inputRef2.current.focus();
        break;
      case 2:
        inputRef3.current.focus();
        break;
      case 3:
        inputRef4.current.focus();
        break;
      case 4:
        inputRef5.current.focus();
        break;
      case 5:
        inputRef6.current.focus();
        break;
      default:
    }
  };

  const fields = [
    { ref: inputRef1, index: 1 },
    { ref: inputRef2, index: 2 },
    { ref: inputRef3, index: 3 },
    { ref: inputRef4, index: 4 },
    { ref: inputRef5, index: 5 },
    { ref: inputRef6, index: 6 },
  ];

  const { getFieldDecorator } = props.form;

  const SubmitVerificationHandler = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading("true");

        const code = `${values.character1}${values.character2}${values.character3}${values.character4}${values.character5}${values.character6}`;

        const response = await RegisterOrLogin({ code: code, emailOrPhoneNumber: userNameValue });
        setLoading(false);
        if (response.data?.success) {
          const user = response.data.result.user;

          localStorage.setItem('userData', JSON.stringify(user));
          localStorage.setItem('userToken', response.data.result.accessToken);
          console.log('accessToken = ', response.data.result.accessToken)
          localStorage.setItem('userTokenCreateTime', moment().format());

          const AppAddress = process.env.REACT_APP_SERVER_LOCALSERVER; 
          if (AppAddress ==="santana.imt.as" || AppAddress ==="itours.ir"){
            dispatch(getBalance(response.data.result.accessToken));
          }
          dispatch(getUserAsync(response.data.result.user));
          dispatch(toggleVarification(true));
          props.changeModal(undefined);

          setTimerValue();
          setTimeIsOver(false);
        } else {
          setIncorectCode(true);
          const lastRequestExp = JSON.parse(localStorage.getItem('otpExp'));
          const remainedSecounds = -moment().diff(lastRequestExp.time, 'seconds');
          setTimerValue(remainedSecounds);
        }
      }
    });
  };
  return (
    <>
      {loading ? (
        <div className="margin-top-xlarge margin-bottom-xlarge padding-20 ">
          <Loading description={loading === 'sendingCode'?"ارسال کلمه عبور یک بار مصرف ...":undefined}/>
        </div>
      ) : (
        <>
          <h4 className="medium-title margin-bottom-large-mobile">{props.userNameIsEmail ? 'تایید ایمیل' : 'تایید شماره موبایل'}</h4>
          <p>کد ارسال شده به <b> {props.userName} </b> را وارد کنید.</p>
          <Form onSubmit={SubmitVerificationHandler}>
            <div className="float-row verification-inputs-wrapper ltr">
              {fields.map((item) => (
                <div key={item.index} className={`col-small-2 ${(incorectCode && !timeIsOver)?"has-error":""}`}>
                  <Form.Item className="no-margin">
                    {getFieldDecorator(`character${item.index}`, {
                      rules: [{ required: true, message: ' ' }],
                    })(
                      <input
                        ref={item.ref}
                        type="text"
                        maxLength="1"
                        onChange={() => {
                          changeValue(item.index);
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              ))}
            </div>
            {(incorectCode && !timeIsOver) ?<p className='purple margin-top-light margin-bottom'>کد وارد شده صحیح نمی باشد.</p>:null}
            {timerValue && (
              <VerificationTimer
                timerValue={timerValue}
                onFinish={() => {
                  setTimeIsOver(true);
                }}
                onResendCode={resendCode}
              />
            )}
            <button disabled={timeIsOver} type="submit" className="button blue-btn margin-top margin-bottom large-btn full-width">
              ورود
            </button>
          </Form>
        </>
      )}
    </>
  );
};

const WrappedVerificationForm = Form.create({ name: 'vrification' })(Verification);

export default WrappedVerificationForm;
