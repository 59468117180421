import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { userExist } from '../../../Actions/UserAction';
import { showError } from '../../../Actions/VisualDataAction';
import Loading from '../../Common/Loading';

const UserName = (props) => {
  const [validateMessage, setValidateMessage] = useState('');
  const [touched, setTouched] = useState(false);
  const [mobileHint, setMobileHint] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const usernameRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      usernameRef.current.focus();
    }, 50);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (touched) {
      validate(props.userName);
    }
  }, [props.userName, touched]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTouched(true);
    const formState = validate(props.userName);
    if (formState) {
      setLoading(true);
      let userNameValue = props.userName;
      if (/^\d+$/.test(userNameValue)) {
        if (userNameValue[0] === '0') {
          userNameValue = userNameValue.substring(1);
        }
        userNameValue = `+98${userNameValue}`;
      }
      const result = await userExist({ emailOrPhoneNumber: userNameValue });
      if (result?.data?.success) {
        if (result.data.result) {
          props.changeModal('login');
        } else if (userNameValue[0] === '+') {
          props.changeModal('verification');
        } else {
          setTouched(false);
          props.changeUserName('');
          setMobileHint(true);
        }
      } else {
        dispatch(
          showError({
            visibility: true,
            content: result?.message || "Internal Error,Please refresh the page",
            closable:true
          })
        );
      }
      setLoading(false);
    }
  };

  const userNameChangeHandle = (e) => {
    props.changeUserName(e.target.value);
  };

  const validate = (val) => {
    if (val.length > 6) {
      if (/[a-zA-Z]/g.test(val)) {
        //using email address:
        const testEmail = val
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        if (testEmail) {
          setValidateMessage('');
          return true;
        } else {
          setValidateMessage('ایمیل خود را به درستی وارد کنید');
          return false;
        }
      } else {
        //using mobile number:
        const testNumber = /^\d+$/.test(val);
        if (testNumber && (val.length === 10 || val.length === 11)) {
          setValidateMessage('');
          return true;
        } else {
          setValidateMessage('شماره موبایل خود را به درستی وارد کنید (نمونه : 091xxxxxxxx)');
          return false;
        }
      }
    }
    if (val.length > 0) {
      setValidateMessage('شماره موبایل یا ایمیل خود را به درستی وارد کنید');
      return false;
    }
    setValidateMessage('شماره موبایل یا ایمیل خود را وارد کنید');
    return false;
  };

  return (
    <>
      {loading ? (
        <div className="margin-top-xlarge margin-bottom-xlarge padding-20 ">
          <Loading />
        </div>
      ) : (
        <>
          <h4 className="medium-title margin-bottom-large-mobile">ورود / ثبت‌نام آیتورز</h4>
          {mobileHint && (
            <p className="purple margin-top-large">
              <span className="itours-icon red-info-icon margin-end-light icon-small" />
              کاربری با ایمیل وارد شده وجود ندارد. لطفا برای ایجاد حساب کاربری شماره موبایل خود را وارد کنید.
            </p>
          )}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="form-label">شماره موبایل یا ایمیل</label>
              <input
                ref={usernameRef}
                className="form-input placeholder-right en-input-text"
                type="text"
                name="emailOrPhoneNumber"
                onChange={userNameChangeHandle}
                value={props.userName}
              />
              {validateMessage && <div class="validator-label">{validateMessage}</div>}
            </div>
            <p className="margin-top-large margin-bottom-large">
              با ایجاد حساب کاربری جدید، تأیید می کنم که{' '}
              <Link to="/terms" onClick={() => props.changeModal(null)}>
                قوانین و مقررات
              </Link>{' '}
              استفاده از آیتورز را خوانده ام و با آن موافقم.
            </p>
            {/* <div className="checkbox-holder has-link margin-top-large margin-bottom-large">
              <input className="custom-checkbox" type="checkbox" />
              <label className="checkbox-label font-14">
                با ایجاد حساب کاربری جدید، تأیید می کنم که{' '}
                <Link to="/terms" onClick={()=>props.changeModal(null)}>
                  قوانین و مقررات
                </Link>{' '}
                استفاده از آیتورز را خوانده و با آن موافقم.
              </label>
            </div> */}
            <button type="submit" className="button blue-btn full-width large-btn">
              ادامه
            </button>
          </form>
        </>
      )}
    </>
  );
};

export default UserName;
