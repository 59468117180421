import React from "react";
export default class ShowWeekDay extends React.PureComponent{
    convertToPersian(value){
        switch (value) {
            case "Monday":
                return "دوشنبه"
            case "Tuesday":
                return "سه شنبه"
            case "Wednesday":
                return "چهارشنبه"
            case "Thursday":
                return "پنجشنبه"
            case "Friday":
                return "جمعه"
            case "Saturday":
                return "شنبه"
            case "Sunday":
                return "یکشنبه"
            default:
                return ""

        }
    }
    render() {
        return(
            <div>{this.convertToPersian(this.props.children)}</div>
        )
    }
}