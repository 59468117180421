import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Checkbox, Slider, InputNumber } from 'antd';

import LayoutConfig from "../../Config/layoutConfig";
import Rating from "../Common/Rating";
import Price from "../Common/Price";
import { setDomesticHotelFilter } from "../../Actions/DomesticHotelAction";

const DomesticHotelFilter = props => {
    const { Dictionary } = props;

    const [filteredPrice, setFilteredPrice] = useState();

    const [availableFilters, setAvailableFilters] = useState()

    //set possible filter options:
    useEffect(() => {
        const starRating = [];
        const facilities = [];
        const types = [];
        let priceRange;

        if (props.hotels.length) {

            for (const hotel of props.hotels) {
                if (!starRating.includes(hotel.HotelRating)) {
                    starRating.push(hotel.HotelRating);
                }

                for (const facility of hotel.Facilities) {
                    if (!(facilities.find(item => item.Keyword === facility.Keyword))) {
                        facilities.push(facility);
                    }
                }

                if (!(types.find(item => item.HotelTypeId === hotel.HotelTypeId))) {
                    types.push({ HotelTypeId: hotel.HotelTypeId, HotelTypeName: hotel.HotelTypeName });;
                }

                if (hotel.priceInformation && hotel.priceInformation.salePrice) {
                    if (priceRange) {
                        if (hotel.priceInformation.salePrice > priceRange[1]) {
                            priceRange[1] = hotel.priceInformation.salePrice;
                        }
                        if (hotel.priceInformation.salePrice < priceRange[0]) {
                            priceRange[0] = hotel.priceInformation.salePrice;
                        }
                    } else {
                        priceRange = [hotel.priceInformation.salePrice, hotel.priceInformation.salePrice]
                    }
                }

            }
        }
        setAvailableFilters({
            starRating: starRating.sort((a, b) => a - b),
            facilities,
            priceRange,
            types
        })

    }, [props.hotels]);

    useEffect(() => {
        if (availableFilters?.priceRange && !filteredPrice) {
            setFilteredPrice(availableFilters.priceRange);
        }
    }, [availableFilters?.priceRange]);

    const guestRatingOptions = [
        { label: "90+ " + Dictionary.Excellent, value: `90-100` },
        { label: "80+ " + Dictionary.VeryGood, value: `80-89` },
        { label: "70+ " + Dictionary.Good, value: `70-79` },
        { label: "50+ " + Dictionary.Pleasant, value: `50-69` },
        // { label:"50- " + Dictionary.Bad , value: `0-49` }
    ];

    return (
        <>
            <strong className={`filter-header ${LayoutConfig.themeClassName === 'naminTravel-theme' ? "" : "margin-top"}`}>{Dictionary.filters}</strong>
            <div className='padding-h-10'>
                <div className="sidebar-item">

                    <h5 className="normal-title"><span>{Dictionary.searchByName}</span></h5>
                    <div className="form-group has-fake-magnifirer-icon">
                        <input
                            className="form-input"
                            placeholder={Dictionary.enterHotelName}
                            type="text"
                            onChange={e => { props.changeFiltersHandle("name", e.target.value) }}
                        />
                    </div>
                </div>

                <div className="sidebar-item">
                    <h5 className="normal-title"> <span className='okr-bg'>{Dictionary.Availability}</span> </h5>
                    <Checkbox
                        className="custom-antd-checkbox"
                        onChange={e => { props.changeFiltersHandle("availability", e.target.checked) }}
                    >
                        {Dictionary.ShowAvailableHotelsOnly}
                    </Checkbox>
                </div>

                {availableFilters?.priceRange && filteredPrice && availableFilters.priceRange[0] !== availableFilters.priceRange[1] && <div className="sidebar-item">
                    <h5 className="normal-title"><span>{Dictionary.price}</span></h5>
                    <Slider
                        step={1}
                        reverse
                        range
                        value={filteredPrice}
                        tipFormatter={value => <Price currency={props.currencyCode}>{value}</Price>}
                        onChange={setFilteredPrice}
                        onAfterChange={value => {
                            props.changeFiltersHandle("price", value);
                        }}
                        min={availableFilters.priceRange[0]}
                        max={availableFilters.priceRange[1]}
                    />
                    <div className="float-row margin-top margin-bottom">
                        <div className="col-small-6">
                            <label>{Dictionary.from}</label>
                            <InputNumber
                                className="full-width"
                                value={filteredPrice[0]}
                                min={availableFilters.priceRange[0]}
                                max={availableFilters.priceRange[1]}
                                onChange={value => {
                                    setFilteredPrice(prevState => {
                                        props.changeFiltersHandle("price", [value, prevState[1]]);
                                        return ([value, prevState[1]]);
                                    });
                                }}
                            />
                        </div>
                        <div className="col-small-6">
                            <label>{Dictionary.until}</label>
                            <InputNumber
                                className="full-width"
                                value={filteredPrice[1]}
                                onChange={value => {
                                    setFilteredPrice(prevState => {
                                        props.changeFiltersHandle("price", [prevState[0], value]);
                                        return ([prevState[0], value]);
                                    });
                                }}
                                min={availableFilters.priceRange[0]}
                                max={availableFilters.priceRange[1]}
                            />
                        </div>
                    </div>
                </div>}

                <div className="sidebar-item">
                    <h5 className="normal-title"> <span className='okr-bg'>{Dictionary.starRating}</span> </h5>
                    <Checkbox.Group
                        onChange={value => { props.changeFiltersHandle("rating", value) }}
                        className="custom-antd-checkbox"
                    >
                        {availableFilters?.starRating?.map(item => (
                            <div key={item}>
                                <Checkbox value={item}><Rating number={parseFloat(item)} /></Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </div>

                <div className="sidebar-item">
                    <h5 className="normal-title"><span className='okr-bg'>{Dictionary.facilities}</span></h5>
                    <Checkbox.Group
                        onChange={value => { props.changeFiltersHandle("facility", value) }}
                        className="custom-antd-checkbox"
                    >
                        {availableFilters?.facilities?.map(item => (
                            <div key={item.Keyword} >
                                <Checkbox value={item.Keyword}>{item.Title}</Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </div>

                <div className="sidebar-item">
                    <h5 className="normal-title"><span className='okr-bg'>{Dictionary.AccommodationType}</span></h5>
                    <Checkbox.Group
                        onChange={value => { props.changeFiltersHandle("accommodationType", value) }}
                        className="custom-antd-checkbox"
                    >
                        {availableFilters?.types?.map(item => (
                            <div key={item.HotelTypeId} >
                                <Checkbox value={item.HotelTypeId}>{item.HotelTypeName}</Checkbox>
                            </div>
                        ))}
                    </Checkbox.Group>
                </div>

                <div className="sidebar-item">
                    <h5 className="normal-title"><span className='okr-bg'>{Dictionary.ReviewScore}</span></h5>
                    <Checkbox.Group
                        options={guestRatingOptions}
                        onChange={value => { props.changeFiltersHandle("reviewScore", value) }}
                        className="custom-antd-checkbox"
                    />
                </div>

            </div>
        </>
    )
}
const mapDispatchToProps = (dispatch) => ({
    changeFiltersHandle: (type, value) => dispatch(setDomesticHotelFilter(type, value))
});

const mapStateToProps = (state) => ({
    currencyCode: state.UserData.Currency,
    Dictionary: state.VisualData.Dictionary
});

export default connect(mapStateToProps, mapDispatchToProps)(DomesticHotelFilter);