import { useState, useEffect, useRef } from 'react';

const VerificationTimer = (props) => {
  const [remaindTime, setRemaindTime] = useState(props.timerValue);

  const intervalRef = useRef();

  useEffect(() => {
    setRemaindTime(props.timerValue);
    intervalRef.current = setInterval(() => {
      setRemaindTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [props.timerValue]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(()=>{
    if (remaindTime < 1){
        props.onFinish();
        clearInterval(intervalRef.current);
    }
  },[remaindTime])

  if (remaindTime < 1) {
    return (
      <p className="text-center margin-top margin-bottom">
        کد را دریافت نکردم، <button type="button" className='button link-style-btn' onClick={props.onResendCode}> ارسال مجدد کد</button>
      </p>
    );
  }

  return <p className="text-center margin-top margin-bottom">ارسال مجدد کد در {remaindTime} ثانیه دیگر</p>;
};

export default VerificationTimer;
