import moment from "moment-jalaali";
import parse from 'html-react-parser';

import Bar from "../Common/Bar";

const DomesticHotelDetailCommentItem = props => {
    const { comment } = props;
    return (
        <div key={comment.CommentId} className="comment-item">
            <span className="avatar-holder margin-end"><span className="itours-icon profile" /></span>
            <div className="flex-auto">
                <div className="pair-row">
                    <div>
                        <span className="semi-bold"> {comment.FullName} </span>
                        {!!comment.CityName && <span className="font-12">({comment.CityName})</span>}
                    </div>
                    <span className="font-13 gray">
                        {comment.CreateDate && moment(comment.CreateDate).format("jYYYY/jMM/jDD")}
                    </span>
                </div>
                <Bar persentage={comment.Satisfaction} className="margin-end-half" />
                {comment.Satisfaction} از 100

                <div className="font-13 margin-top-half">{parse(comment.Comment)}</div>
            </div>
        </div>
    )
}

export default DomesticHotelDetailCommentItem;