import { Input, Form, Radio, Divider, Tooltip } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import { useState, useEffect } from "react";

import { CountryCode } from "../../Enum/Models";
import { onChangeValidation } from "../Common/Validation";
import QuantityInput from "../Common/QuantityInput";
import Price from "../Common/Price";

const DomesticHotelCheckoutRoomItem = props => {

    const { roomItem, roomIndex, reserveInformation, Dictionary } = props;

    const { getFieldDecorator } = props.form;

    const [hasChild, setHasChild] = useState(false);
    const [childAge, setChildAge] = useState();
    const [extraBedCount, setExtraBedCount] = useState(0);

    const extraBedPrice = roomItem.pricing?.find((item) => item.type === 'ExtraBed' && item.ageCategoryType === 'ADL')?.amount || 0;
    const childPrice = roomItem.pricing?.find((item) => item.type === 'Room' && item.ageCategoryType === 'CHD')?.amount || 0;

    useEffect(() => {
        props.updateRoomChildAndExtraBed(roomIndex, "selectedChild", hasChild, childPrice);
        if (hasChild) {
            props.form.setFieldsValue({ [`passengers[${roomIndex}].childrenAge`]: [childAge] });
        } else {
            props.form.setFieldsValue({ [`passengers[${roomIndex}].childrenAge`]: [] });
        }
    }, [hasChild, childAge]);

    useEffect(() => {
        props.updateRoomChildAndExtraBed(roomIndex, "selectedExtraBed", extraBedCount, extraBedPrice);
        props.form.setFieldsValue({ [`passengers[${roomIndex}].extraBed`]: extraBedCount });
    }, [extraBedCount]);


    return (<>
            <div className="bg-white rounded padding-v-15 relative margin-bottom" key={roomIndex}>
                <div className="padding-h-15">
                    <h5 className="no-margin-bottom font-16 md-font-18 semi-bold">
                    {Dictionary.room} {roomIndex + 1}
                    </h5>
                </div>
                <hr className="margin-top-half margin-bottom-half" />
                <div className="padding-h-15">
                    <div className="card-padding box-border border-2 border-dashed margin-top margin-bottom">
                        <div className="flex-ends middle margin-bottom-light">
                            <span className="inline-block font-16 semi-bold" dir="ltr">
                            {roomItem.name}
                            </span>
                            <span className="gray-tag small-tag font-12 no-wrap">
                            {roomItem.board}
                            </span>
                        </div>
                        {roomItem.cancellationPolicyStatus === "Refundable"?(
                            <div className="flex-ends middle">
                                <div className="gray-tag font-12">قابل استرداد</div>
                            </div>                          
                        ):
                        <div className="flex-ends middle">
                            <div className="gray-tag font-12">غیر قابل استرداد</div>
                        </div>                                
                        }
                    </div>

                    <div className='margin-bottom-15'>
                        <b>مشخصات سرپرست</b>
                    </div>
                    <Form.Item className="form-group">
                        {getFieldDecorator(`passengers[${roomIndex}].roomNumber`, {
                            initialValue: roomIndex + 1,
                        })(<Input hidden />,
                        )}
                    </Form.Item>

                    <Form.Item className="form-group no-margin-bottom">
                        {getFieldDecorator(`passengers[${roomIndex}].gender`, {
                            initialValue: true,
                        })(
                            <Radio.Group onChange={props.disableUserIsPassenger} >
                                <Radio className="no-margin" value={true}>{Dictionary.male}</Radio>
                                <Radio value={false}>{Dictionary.female}</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>

                    <div className="float-row">
                        <div className="col-small-12 col-medium-6 clear-medium-2">
                            <Form.Item label={Dictionary.firstName} className="form-group">
                                {getFieldDecorator(`passengers[${roomIndex}].firstName`, {
                                    rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                })(
                                    <Input
                                        className="form-input"
                                        placeholder={Dictionary.firstName}
                                        onChange={(e) => {
                                            onChangeValidation(e, 'alphabet');
                                            props.disableUserIsPassenger();
                                        }}
                                    />,
                                )}
                            </Form.Item>
                        </div>
                        <div className="col-small-12 col-medium-6 clear-medium-2">
                            <Form.Item label={Dictionary.lastName} className="form-group">
                                {getFieldDecorator(`passengers[${roomIndex}].lastName`, {
                                    rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                })(
                                    <Input
                                        className="form-input"
                                        placeholder={Dictionary.lastName}
                                        onChange={(e) => {
                                            onChangeValidation(e, 'alphabet');
                                            props.disableUserIsPassenger();
                                        }}
                                    />,
                                )}
                            </Form.Item>
                        </div>
                        <div className="col-small-12 col-medium-6 clear-medium-2">
                            <Form.Item className="no-margin form-group" label={Dictionary.nationalCode}>
                                {getFieldDecorator(`passengers[${roomIndex}].nationalId`, {
                                    rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                })(
                                    <Input
                                        className="form-input"
                                        placeholder={Dictionary.nationalCode}
                                        onChange={e => {
                                            onChangeValidation(e, 'number');
                                            props.disableUserIsPassenger();
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </div>
                        <div className="col-small-12 col-medium-6 clear-medium-2">
                            <Form.Item className="no-margin form-group" label={Dictionary.nationality}>
                                {getFieldDecorator(`passengers[${roomIndex}].nationality`, {
                                    rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                                })(
                                    <select className="form-select">
                                        <option disabled selected value="false">--</option>
                                        {CountryCode.map((item) => (
                                            <option value={item.value} key={item.value}>
                                                {item.code}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            </Form.Item>
                        </div>
                    </div>                    

                    {!!roomItem.maxChildAge && (
                    <div className='margin-top'>
                        <div className="flex-ends middle">
                            <strong>
                                <Tooltip title={`برای کودکان بالای ${roomItem.maxChildAge} سال باید از تخت اضافه استفاده کنید.`} >
                                    <span className="itours-icon info-icon icon-small" /> {Dictionary.child}
                                </Tooltip>
                            </strong>
                            <div className="domestic-hotel-child-control">
                                <span className="no-wrap margin-end-half">سن کودک (سال)</span>
                                <Tooltip title="سن کودک" >
                                    <select
                                        placeholder="سن کودک"
                                        onChange={e => { setChildAge(+e.target.value) }}>
                                        {_.range(1, roomItem.maxChildAge + 1).map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </Tooltip>

                                <button
                                    type="button"
                                    className="button blue-btn inline-btn medium-button margin-start no-wrap"
                                    onClick={() => { setHasChild(true) }}
                                    disabled={hasChild || !childAge}
                                >
                                    {hasChild ? "افزوده شد" : "افزودن"}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <Form.Item hidden>
                    {getFieldDecorator(`passengers[${roomIndex}].childrenAge`, {
                        initialValue: []
                    })(<Input />)}
                </Form.Item>

                <Form.Item hidden>
                    {getFieldDecorator(`passengers[${roomIndex}].extraBed`, {
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>

                {!!roomItem.extraBed && (<>
                    <div className='margin-top'>

                        <div className="flex-ends middle">
                            <strong>
                                {Dictionary.ExtraBed}
                                {!!extraBedPrice && <small className="margin-start-light">
                                    (<Price currency={props.currencyCode}>{extraBedPrice}</Price> برای هر شب)
                                </small>}
                            </strong>
                            <QuantityInput min={0} max={roomItem.extraBed} onChange={setExtraBedCount} small />
                        </div>
                    </div>

                </>
                )}


                </div>

            </div>


    </>
    )
}

const mapStateToProps = (state) => ({
    error: state.VisualData.error,
    currencyCode: state.UserData.Currency,
    userData: state.UserData.userData,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
});

export default connect(mapStateToProps)(DomesticHotelCheckoutRoomItem);