import { useRef, useState, useEffect } from 'react';
import moment from 'moment-jalaali';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';

import Loading from '../../Common/Loading';
import { ResetPasswordByPhoneNumber, recoverPasswordByMobile } from '../../../Actions/UserAction';
import { showError } from '../../../Actions/VisualDataAction';
import VerificationTimer from './VerificationTimer';

const MobileForgetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [timeIsOver, setTimeIsOver] = useState(false);
  const [timerValue, setTimerValue] = useState();
  const [state, setState] = useState();

  const dispatch = useDispatch();

  const codeRef = useRef();

  let userNameValue = props.userName;
  if (/^\d+$/.test(userNameValue)) {
    if (userNameValue[0] === '0') {
      userNameValue = userNameValue.substring(1);
    }
    userNameValue = `+98${userNameValue}`;
  }

  const sendCode = async () => {
    setLoading(`ارسال کد بازیابی به شماره ${props.userName}`);
    setTimerValue();
    setState();
    const sendCodeResponse = await recoverPasswordByMobile({ phoneNumber: userNameValue });
    setLoading();
    if (sendCodeResponse.data?.success) {
      setUserId(sendCodeResponse.data.result.userId);
      localStorage.setItem('forgetExp', JSON.stringify({ userId: sendCodeResponse.data.result.userId, time: moment().add(1, 'minutes').format() }));
      setTimerValue(60);
      setTimeIsOver(false);
    } else {
      props.changeModal('usernameModal');
      dispatch(
        showError({
          visibility: true,
          content: sendCodeResponse.error?.message,
          closable:true
        })
      );
    }
  };

  useEffect(() => {
    const lastRequestExp = JSON.parse(localStorage.getItem('forgetExp'));
    if (!lastRequestExp || moment().isAfter(lastRequestExp.time)) {
      sendCode();
    } else {
      const remainedSecounds = -moment().diff(lastRequestExp.time, 'seconds');
      setTimerValue(remainedSecounds);
    }
    codeRef.current?.focus();
  }, []);

  const resendCode = () => {
    sendCode();
    props.form.resetFields();
  };

  const SubmitForgetPasswordHandler = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setLoading('ذخیره کلمه عبور جدید...');
        let id;
        if (userId) {
          id = userId;
        } else {
          const lastRequestExp = JSON.parse(localStorage.getItem('forgetExp'));
          id = lastRequestExp.userId;
        }
        setTimerValue();
        const response = await ResetPasswordByPhoneNumber({
          userId: id,
          password: values.newPassword,
          code: values.code,
        });
        setLoading();
        if (response.data?.success) {
          setTimerValue();
          setState('passwordChanged');
        } else {
          setState('incorrectCode');
          const lastRequestExp = JSON.parse(localStorage.getItem('forgetExp'));
          const remainedSecounds = -moment().diff(lastRequestExp.time, 'seconds');
          setTimerValue(remainedSecounds);
        }
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('تکرار کلمه عبور با کلمه عبور یکسان نیست!');
    } else {
      callback();
    }
  };

  const { getFieldDecorator } = props.form;

  if (state && state === 'passwordChanged') {
    return (
      <div className="margin-top-xlarge margin-bottom-xlarge text-center">
        <span className="check-green itours-icon icon-large margin-bottom" />
        <p>
          <strong>کلمه عبور جدید شما با موفقیت ذخیره شد.</strong>
        </p>
        <button
          type="button"
          className="link-style-btn"
          onClick={() => {
            props.changeModal('login');
          }}>
          بازگشت به صفحه ورود
        </button>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className="margin-top-xlarge margin-bottom-xlarge padding-20 ">
          <Loading description={loading} />
        </div>
      ) : (
        <>
          <Form onSubmit={SubmitForgetPasswordHandler} autoComplete="off">
            <Form.Item className="no-margin form-group" label="کلمه عبور جدید">
              {getFieldDecorator('newPassword', {
                rules: [
                  { required: true, message: 'کلمه عبور خود را وارد کنید' },
                  { min: 6, message: 'کلمه عبور باید حد اقل 6 کاراکتر باشد' },
                ],
                initialValue: '',
              })(<input className="form-input" type="password" />)}
            </Form.Item>

            <Form.Item className="no-margin form-group" label="تکرار کلمه عبور جدید">
              {getFieldDecorator('passwordRepeat', {
                rules: [{ required: true, message: 'کلمه عبور خود را وارد کنید' }, { validator: compareToFirstPassword }],
                initialValue: '',
              })(<input className="form-input" type="password" />)}
            </Form.Item>

            <Form.Item className="no-margin form-group" label="کد بازیابی">
              {getFieldDecorator('code', {
                rules: [{ required: true, message: ` کد بازیابی ارسال شده به شماره ${props.userName} را وارد کنید.` }],
                initialValue: '',
              })(
                <input
                  ref={codeRef}
                  className="form-input"
                  type="text"
                  onChange={() => {
                    setState();
                  }}
                />
              )}
            </Form.Item>
            {state && state === 'incorrectCode' && <span className="purple">کد وارد شده نادرست است، لطفا دوباره تلاش کنید.</span>}

            {timerValue && (
              <VerificationTimer
                timerValue={timerValue}
                onFinish={() => {
                  setTimeIsOver(true);
                }}
                onResendCode={resendCode}
              />
            )}

            <button disabled={timeIsOver} type="submit" className="margin-top margin-bottom button blue-btn large-btn full-width">
              ثبت
            </button>
          </Form>
          <div className="text-end">
            <button
              type="button"
              className="link-style-btn"
              onClick={() => {
                props.changeModal('login');
              }}>
              بازگشت به ورود
            </button>
          </div>
        </>
      )}
    </>
  );
};
const ForgetPasswordForm = Form.create({ name: 'ForgetPassword' })(MobileForgetPassword);

export default ForgetPasswordForm;
