import moment from 'moment';
import { connect } from 'react-redux';

import LayoutConfig from '../../Config/layoutConfig';
import { showError } from '../../Actions/VisualDataAction';
import DatePickerDrop from '../Common/datePickerDrop';
import { updateUser, getUserAsync } from '../../Actions/UserAction';
import Form from '../Common/Form';
import { CountryCode, CountryPostalCode } from '../../Enum/Models';
import Loading from '../Common/Loading';
import Validation from '../Common/Validation';

class ProfileEditNamin extends Form {
  state = {
    ...this.state,
    loading: false,
    toched:false,
    edit:false
  };

  componentDidMount() {
    super.componentDidMount(this.FormDOM);
  }

  FormDOM = [];

  fixNumbers = (str) => {
    const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g];
    const arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

  handleSubmit(event) {
    event.preventDefault();
    super.validateForm(event, async () => {
      let dataModelInput = this.state.DataInput;

      let phoneNumber;
      if(this.props.LAN === "IR" && this.props.userData?.phoneNumber){
        phoneNumber = this.props.userData.phoneNumber;
      }else if(dataModelInput.phoneNumber) {
        let countryCode = dataModelInput.countryCallingCode;
        if (countryCode.substring(0, 2) === '00') {
          countryCode = '+' + countryCode.substring(2);
        }
        phoneNumber = countryCode + this.fixNumbers(dataModelInput.phoneNumber);
      }else{
        phoneNumber=null;
      }

      const parameters = {
        ...this.props.userData,
        birthDay: dataModelInput.birthDay,
        displayName: dataModelInput.firstName + ' ' + dataModelInput.lastName,
        emailAddress: dataModelInput.email,
        firstName: dataModelInput.firstName,
        gender: dataModelInput.gender,
        lastName: dataModelInput.lastName,
        nationalityId: dataModelInput.nationalityId,
        phoneNumber: phoneNumber,
      };

      this.setState({ loading: true });
      const response = await updateUser(parameters);
      this.setState({ loading: false });
      if (response?.data?.success) {
        this.setState({edit:false,toched:false})
        this.props.getUserAsync(response.data.result);
      } else {
        if (response?.error) {
          this.props.showError({ visibility: true, content: response.error.message });
        } else {
          this.props.showError({ visibility: true, content: 'Internal Error,Please refresh the page' });
        }
      }
    });
  }
  render() {
    const { Dictionary, LAN, userData ,activationLoading ,activationMessage,resendEmail,showModal,closeModal} = this.props;
    const { loading,edit,toched } = this.state;

    let userPhoneNumber;

    return (
      <div>
        {loading ? (
          <Loading style2={LayoutConfig.themeClassName === 'style-2'} />
        ) : (
          <form id="editUser" onSubmit={this.handleSubmit.bind(this)}>
            <div className="float-row">
              <div className="col-small-12 col-medium-3 clear-medium-4">
                {edit?<div className="form-group">
                  <label className="form-label required">{Dictionary.firstName}</label>
                  <input
                    onChange={()=>{this.setState({toched:true})}}
                    data-validator={true}
                    className="form-input"
                    placeholder={Dictionary.firstName}
                    name="firstName"
                    type="text"
                    defaultValue={userData.firstName || ''}
                  />
                  <Validation model={this.state} feild="ValidatorList" name="firstName" />
                </div>
                :
                <div className="margin-bottom"><b>{Dictionary.firstName} : </b><span className='blue semi-bold'>{userData.firstName || "Not Registered"}</span></div>
                }
              </div>
              <div className="col-small-12 col-medium-3 clear-medium-4">
                {edit?<div className="form-group">
                  <label className="form-label">{Dictionary.lastName}</label>
                  <input
                    onChange={()=>{this.setState({toched:true})}}
                    data-validator={true}
                    //data-validator-type="faAlphabet"
                    className="form-input"
                    placeholder={Dictionary.lastName}
                    name="lastName"
                    type="text"
                    defaultValue={userData.lastName || ''}
                  />
                  <Validation model={this.state} feild="ValidatorList" name="lastName" />
                </div>
                :
                <div className="margin-bottom"><b>{Dictionary.lastName} : </b><span className='blue semi-bold'>{userData.lastName || "Not Registered"}</span></div>
                }
              </div>
              <div className="col-small-12 col-medium-3 clear-medium-4">
                {edit?<div className="form-group">
                  <label className="form-label">{Dictionary.gender}</label>
                    <select className="form-select" name="gender" defaultValue={userData.gender} onChange={()=>{this.setState({toched:true})}}>
                      <option value={true}>{Dictionary.male}</option>
                      <option value={false}>{Dictionary.female}</option>
                    </select>
                </div>
                :
                <div className="margin-bottom"><b>{Dictionary.gender} : </b><span className='blue semi-bold'>{userData.gender===undefined?"Not Registered":userData.gender?"male":"female" }</span></div>
                }                
              </div>
              <div className="col-small-12 col-medium-3 clear-medium-4">
                {edit?<div className="form-group">
                  <label htmlFor="" className="form-label">
                    {Dictionary.phoneNumber}
                  </label>
                  <div className="select-input-pair-holder" dir="ltr">
                    <select 
                      onChange={()=>{this.setState({toched:true})}}
                      className="form-select" 
                      name={'countryCallingCode'}
                      dir="ltr"
                      >
                      <option disabled selected value> -- </option>
                      {CountryPostalCode.sort((a, b) => {
                        let x = a.code.toLowerCase();
                        let y = b.code.toLowerCase();
                        if (x < y) {
                          return -1;
                        }
                        if (x > y) {
                          return 1;
                        }
                        return 0;
                      }).map((item) => {
                        let seleceted = false;
                        if (userData && userData.phoneNumber) {
                          let code = item.value;
                          if (code.substring(0, 2) === '00') {
                            code = '+' + code.substring(2);
                          }
                          if (userData.phoneNumber.includes(code)) {
                            seleceted = true;
                            let phone_Number = userData.phoneNumber.replace(code, '');
                            userPhoneNumber = phone_Number;
                          }
                        }
                        return (
                          <option key={item.code} value={item.value} selected={seleceted}>
                            {item.code}
                          </option>
                        );
                      })}
                    </select>

                    <input
                      onChange={()=>{this.setState({toched:true})}}
                      className="form-input placeholder-right full-width"
                      placeholder="9XXXXXXXXX"
                      defaultValue={userPhoneNumber ? userPhoneNumber : undefined}
                      name="phoneNumber"
                      type="tel"
                    />
                  </div>
                  <Validation model={this.state} feild="ValidatorList" name="phoneNumber" />
                </div>
                :
                <div className="margin-bottom"><b>{Dictionary.phoneNumber} : </b><span className='blue semi-bold'>{userData.phoneNumber || "Not Registered"}</span></div>
                }                
              </div>
              <div className="col-small-12 col-medium-3 clear-medium-4">
                {edit?<div className="form-group">
                  <label htmlFor="" className="form-label">
                    {Dictionary.email}
                  </label>
                  <input
                    readOnly
                    disabled
                    data-validator={true}
                    className="form-input placeholder-right"
                    placeholder={Dictionary.enterYourEmail}
                    name="email"
                    type="email"
                    defaultValue={userData.emailAddress || ''}
                  />
                  <Validation model={this.state} feild="ValidatorList" name="email" />
                </div>
                :
                <div className="margin-bottom"><b>{Dictionary.email} : </b><span className='blue semi-bold'>{userData.emailAddress || "Not Registered"}</span></div>
                }                 
              </div>            
              <div className="col-small-12 col-medium-3 clear-medium-4">
                {edit?<div className="form-group">
                  <label htmlFor="" className="form-label">
                    {Dictionary.birthdayMiladi}
                  </label>
                  <DatePickerDrop
                    onTouch={()=>{this.setState({toched:true})}}
                    emptyDefault={true}
                    defaultValue={userData.birthDay || undefined}
                    format={'YYYY-MM-DD'}
                    name="birthDay"
                    min={'1900-01-01'}
                    max={moment().format('YYYY-MM-DD')}
                  />
                  <Validation model={this.state} feild="ValidatorList" name="birthDay" />
                </div>
                :
                <div className="margin-bottom"><b>{Dictionary.birthdayMiladi} : </b><span className='blue semi-bold'>{userData.birthDay?moment(userData.birthDay).format("YYYY-MM-DD") : "Not Registered"}</span></div>
                }  
              </div>
              <div className="col-small-12 col-medium-3 clear-medium-4">
                {edit?<div className="form-group">
                  <label htmlFor="" className="form-label">
                    {Dictionary.nationality}
                  </label>
                  <select 
                  onChange={()=>{this.setState({toched:true})}}
                    data-validator={true}
                    className="form-select" 
                    defaultValue={userData.nationalityId} 
                    name="nationalityId" 
                    dir="ltr"
                  >
                    <option disabled selected value="false"> -- </option>
                    {CountryCode.sort((a, b) => {
                      let x = a.code.toLowerCase();
                      let y = b.code.toLowerCase();
                      if (x < y) {
                        return -1;
                      }
                      if (x > y) {
                        return 1;
                      }
                      return 0;
                    }).map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.code}
                      </option>
                    ))}
                  </select>
                  <Validation model={this.state} feild="ValidatorList" name="nationalityId" />
                </div>
                :
                <div className="margin-bottom"><b>{Dictionary.nationality} : </b><span className='blue semi-bold'>{userData.nationalityId || "Not Registered"}</span></div>
                } 
              </div>
              <div className="col-small-12">
                <div className="varified">
                  {!userData.isEmailConfirmed ? (
                    <>
                      <div className={`itours-modal ${showModal ? "itours-modal visible" : ""}`}>
                        <div className="modal-overlay" onClick={closeModal} />
                        <div className="modal-inner small-width text-center">
                          <div className="modal-main">
                            {activationLoading ? 
                              <div className='padding-v-20'>
                                <Loading />
                              </div>
                            : (
                              activationMessage && 
                              <div>
                                <br/><br/>
                                  {activationMessage.success?
                                  <span className="itours-icon check-green icon-xlarge" />
                                  :
                                  <span className="itours-icon error-icon icon-xlarge" />
                                  }
                                <div className="margin-top margin-bottom">
                                {activationMessage.text}.
                                </div>
                                <br/>
                              </div>
                            )}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="button color-1-btn"
                              onClick={closeModal}
                              disabled={activationLoading}
                            >
                              {Dictionary.ok}
                            </button>
                          </div>
                        </div>
                      </div>
                      <span className="purple">
                        {Dictionary.yourEmailAddressIsNotConfirmedPleaseCheckEmailrecivedFromUs}
                        <button type="button" className="link-style-btn margin-start" onClick={resendEmail} >
                          {Dictionary.resendEmail}
                        </button>
                      </span>
                    </>
                  ) : (
                    <span className="green">
                      {Dictionary.YourEmailAddressIsConfirmed}
                    </span>
                  )}
                </div>               
              </div>
              <div className="col-small-12 text-end">
                {edit?<>
                <button className="button grey-btn margin-top margin-end" type="button" onClick={()=>{this.setState({edit:false})}}>
                  {Dictionary.cancel}
                </button>
                <button className="button red-btn margin-top" type="submit" disabled={!toched}>
                  {Dictionary.saveChanges}
                </button>
                </>
                :
                <span className="button red-btn margin-top" onClick={()=>{this.setState({edit:true})}}>
                  {Dictionary.editInformation}
                </span>
                }
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

const stateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});

const mapDispatchToProps = (dispatch) => ({
  getUserAsync: (params) => dispatch(getUserAsync(params)),
  showError: (params) => dispatch(showError(params)),
});

export default connect(stateToProps, mapDispatchToProps)(ProfileEditNamin);
