import React from 'react';
import {connect} from 'react-redux';
import {ApplyFilterFlight,flightFilterSelectNaminTravelAirlineStopEthnic,flightFilterSelectAirlineStop} from '../../Actions/FlightAction';
import Price from "../Common/Price";
import LayoutConfig from '../../Config/layoutConfig';


class AirlineFilter extends React.Component{
    handleSelectNaminTravelFilter =(evt,stop,ethnic)=>{
        const airline=evt.target.value;
        const checked = evt.target.checked;
        this.props.naminFilterSelect({isAdd:checked,airline,stop,ethnic});   
        this.props.ApplyFilterFlight(); 
    }
    handleSelectFilter =(evt,stop)=>{
        const airline=evt.target.value;
        const checked = evt.target.checked;
        this.props.publicfilterSelect({isAdd:checked,airline,stop});   
        this.props.ApplyFilterFlight(); 
    }
    render() {
        const {Dictionary}=this.props;
        const {airlinePrice,airlinePriceEthnic,airlinePriceNoEthnic}=this.props.filtershow;
        const naminEthnicAirlineFilterList = [
            "Free professional support",
            "Rebookable",
            "Easier to change rules",
            "Free extra Baggage",
            "Flexible ticket service",
            "Possibility to change dates or refund"
        ];
        const AirlineFilterList = [
            "Free professional support",
            "Rebookable (some airlines)",
            "Flexible ticket service",
            "Possibility to change dates (some airlines)"
        ];

        return(
            <>
                {
                    (airlinePrice)?  
                        LayoutConfig.themeClassName === 'naminTravel-theme' ?
                        <>
                            {airlinePriceEthnic && airlinePriceEthnic.length>0  ?
                                <div className="bg-pink padding-v-10">
                                    <div className="page-container">
                                        <div className="airline-filter-with-list">
                                            <ul className="styled-list small white">
                                                {naminEthnicAirlineFilterList.map(item => <li key={item}>{item}</li>)}
                                            </ul>
                                            <div className="clearfix airline-filter margin-start">
                                                <div className={"airline-table-header pull-start margin-end-light"}>
                                                    <div className="airline">{Dictionary.airline}</div>
                                                    <div className="cell">{Dictionary.direct}</div>
                                                    <div className="cell">1+ {Dictionary.stop}</div>
                                                </div>
                                                <div className="airline-table-holder">
                                                    <div className="airline-filter-row">
                                                        {airlinePriceEthnic.map((item,index)=>{
                                                            const airlineObj = item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline;
                                                            return(
                                                            <div className="airline-table-cell" key={index}>
                                                                <div  className={"airline-filter-item"}>
                                                                    <img className="airline-logo" alt={airlineObj.name} title={airlineObj.name} src={airlineObj.photoUrl} />
                                                                    {item.noneStop?
                                                                        <div className="cell">
                                                                            <div className={"checkbox-holder ltr"}>
                                                                                <input className="custom-checkbox" id={"none" + index} type="checkbox" value={item.noneStop.airline} onChange={(e)=>{this.handleSelectNaminTravelFilter(e,false,true)}}/>
                                                                                <label className="checkbox-label" htmlFor={"none" + index}><Price currency={this.props.Currency}>{item.noneStop.priceInfo.totalFare}</Price></label>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        <div className="cell"> - </div>
                                                                    }
                                                                    {item.multiStop?
                                                                        <div className="cell">
                                                                            <div className={"checkbox-holder ltr"}>
                                                                                <input className="custom-checkbox" id={"multi" + index} type="checkbox" value={item.multiStop.airline} onChange={(e)=>{this.handleSelectNaminTravelFilter(e,true,true)}}/>
                                                                                <label className="checkbox-label" htmlFor={"multi" + index}><Price currency={this.props.Currency}>{item.multiStop.priceInfo.totalFare}</Price></label>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        <div className="cell"> - </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :null}

                            {airlinePriceNoEthnic && airlinePriceNoEthnic.length>0 ?
                                <div className="bg-gray2 padding-v-10">
                                    <div className="page-container">
                                        <div className="airline-filter-with-list">
                                            <ul className="styled-list small">
                                                {AirlineFilterList.map(item => <li key={item}>{item}</li>)}
                                            </ul>
                                            <div className="clearfix airline-filter margin-start">
                                                <div className={"airline-table-header pull-start margin-end-light"}>
                                                    <div className="airline">{Dictionary.airline}</div>
                                                    <div className="cell">{Dictionary.direct}</div>
                                                    <div className="cell">1+ {Dictionary.stop}</div>
                                                </div>
                                                <div className="airline-table-holder">
                                                    <div className="airline-filter-row">
                                                        {airlinePriceNoEthnic.map((item,index)=>{
                                                            const airlineObj = item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline;
                                                            return(
                                                            <div className="airline-table-cell" key={index}>
                                                                <div  className={"airline-filter-item"}>
                                                                    <img className="airline-logo" alt={airlineObj.name} title={airlineObj.name} src={airlineObj.photoUrl} />
                                                                    {item.noneStop?
                                                                        <div className="cell">
                                                                            <div className={"checkbox-holder ltr"}>
                                                                                <input className="custom-checkbox" id={"none" + index} type="checkbox" value={item.noneStop.airline} onChange={(e)=>{this.handleSelectNaminTravelFilter(e,false,false)}}/>
                                                                                <label className="checkbox-label" htmlFor={"none" + index}><Price currency={this.props.Currency}>{item.noneStop.priceInfo.totalFare}</Price></label>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        <div className="cell"> - </div>
                                                                    }
                                                                    {item.multiStop?
                                                                        <div className="cell">
                                                                            <div className={"checkbox-holder ltr"}>
                                                                                <input className="custom-checkbox" id={"multi" + index} type="checkbox" value={item.multiStop.airline} onChange={(e)=>{this.handleSelectNaminTravelFilter(e,true,false)}}/>
                                                                                <label className="checkbox-label" htmlFor={"multi" + index}><Price currency={this.props.Currency}>{item.multiStop.priceInfo.totalFare}</Price></label>
                                                                            </div>
                                                                        </div>
                                                                    :
                                                                        <div className="cell"> - </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>                        
                                        </div>
                                    </div>
                                </div>
                            :null}                                
                            
                        </>
                        :
                        <div className={"clearfix page-container"}>
                            <div className={"airline-table-header pull-start margin-end-light"}>
                                <div className="airline">{Dictionary.airline}</div>
                                <div className="cell">{Dictionary.direct}</div>
                                <div className="cell">1+ {Dictionary.stop}</div>
                            </div>
                            <div className="airline-table-holder">
                                <div className="airline-filter-row">
                                    {
                                        airlinePrice.map((item,index)=>{
                                            return(
                                            <div className="airline-table-cell" key={index}>
                                                <div  className={"airline-filter-item"}>
                                                    <img 
                                                    className="airline-logo" 
                                                    alt={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.name:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.name}
                                                    title={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.name:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.name}
                                                    src={item.noneStop?item.noneStop.airItinerary[0].flightSegments[0].marketingAirline.photoUrl:item.multiStop.airItinerary[0].flightSegments[0].marketingAirline.photoUrl} />
                                                    {
                                                        item.noneStop?
                                                        <div className="cell">
                                                            <div className={"checkbox-holder ltr"}>
                                                                <input className="custom-checkbox" id={"none" + index} type="checkbox" value={item.noneStop.airline} onChange={(e)=>{this.handleSelectFilter(e,false)}}/>
                                                                <label className="checkbox-label" htmlFor={"none" + index}><Price currency={this.props.Currency}>{item.noneStop.priceInfo.totalFare}</Price></label>
                                                            </div>
                                                        </div>
                                                            :
                                                        <div className="cell"> - </div>
                                                    }
                                                    {
                                                        item.multiStop?
                                                        <div className="cell">
                                                            <div className={"checkbox-holder ltr"}>
                                                                <input className="custom-checkbox" id={"multi" + index} type="checkbox" value={item.multiStop.airline} onChange={(e)=>{this.handleSelectFilter(e,true)}}/>
                                                                <label className="checkbox-label" htmlFor={"multi" + index}><Price currency={this.props.Currency}>{item.multiStop.priceInfo.totalFare}</Price></label>
                                                            </div>
                                                        </div>
                                                            :
                                                        <div className="cell"> - </div>
                                                    }

                                                </div>
                                            </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <span>loading</span>
                }
            </>
        )
    }
}
const dispatchToProps=(dispatch)=>({
    publicfilterSelect:(data)=>dispatch(flightFilterSelectAirlineStop(data)),
    naminFilterSelect:(data)=>dispatch(flightFilterSelectNaminTravelAirlineStopEthnic(data)),
    ApplyFilterFlight:()=>dispatch(ApplyFilterFlight())

});
const stateToProps=(state)=>({
    filtershow:state.FlightSearch.filterShow,
    selectedFilter:state.FlightSearch.filterSelect,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN,
    Currency:state.UserData.Currency
});

export default connect(stateToProps,dispatchToProps)(AirlineFilter)