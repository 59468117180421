import React from 'react';
import GatewayItem from "./GatewayItem";
class PaymentList extends React.Component{
    render() {
        const maximumAmount = this.props.maxAmount;
        if (this.props.totalPrice && maximumAmount && this.props.totalPrice > maximumAmount){
            return(
                <div className='card yellow-card card-padding message-block box-border margin-bottom'>
                    <span className="itours-icon yellow-warning margin-end-half" />
                    به دلیل محدودیت سیستم بانک امکان پرداخت بیشتر از 50 میلیون تومان از یک حساب امکان پذیر نمی باشد. لطفا کیف پول خود را با کارت های مختلف شارژ بفرمایید و از طریق کیف پول رزرو خود را پرداخت کنید.
                </div>
            );
        }
        return(
            <div>
                {/*<div className="payment-radio form-group inline-radio">*/}
                {/*{*/}
                {/*    this.props.banks.map((item,key) => {*/}
                {/*        return (*/}
                {/*            <div className="radio-item" key={key}>*/}
                {/*                <input className="custom-radio"  type="radio" name="triptype" */}
                {/*                        checked={this.state.showSegment === item.keyword} */}
                {/*                        onClick={() => {this.setState({showSegment: item.keyword})}} */}
                {/*                />*/}
                {/*                <label className="radio-label">*/}
                {/*                    <span className="radio-mark" />*/}
                {/*                        {item.title}*/}
                {/*                </label>*/}
                {/*            </div>*/}
                {/*        )*/}
                {/*    })*/}
                {/*}*/}
                {/*</div>*/}
                {
                    this.props.banks.map((item)=><div key={item} className="bg-white box-border margin-bottom bank-group-item">
                        <h5 className="normal-title hr_padding">
                            <img 
                                src={item.image.path} 
                                alt={item.image.altAttribute || item.name } 
                                title={item.image.titleAttribute || item.name } 
                                className="bank-group-icon margin-end"
                            />
                            <div>
                                {item.name } <small>({item.title})</small>
                            <p>{item.description}</p>
                            {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'
                            && item.keyword==="Dibs"?<div className="font-14">at least 2 percent or amount equal to 150 NOK will be added to this price</div>:null}
                        </div>
                        </h5>
                        <div className="card-padding">                            
                        {item.gateways.map((gatewayItem,keyItem)=><GatewayItem 
                            key={gatewayItem.name} 
                            bankItem={item} 
                            gatewayItem={gatewayItem}
                            selectedBankthis={this.props.selectedBankthis}
                            />)}
                        </div>
                    </div>
                    )
                }
                {/*{*/}
                {/*    this.props.banks.map((item,key) => {*/}
                {/*        return (*/}
                {/*            <div className={"payment-method-item"} key={key}>*/}
                {/*                <div className={"paymentItem"}>*/}
                {/*                    <div*/}
                {/*                        className={(this.state.showSegment === item.keyword) ? "paymentDetails" : "hide paymentDetails"}>*/}

                {/*                        <div>*/}
                {/*                            <strong>{item.title}</strong>*/}
                {/*                            <div>{item.name}</div>*/}
                {/*                            <p>{item.description}</p>*/}
                {/*                        </div>*/}
                {/*                        <div className="box-border card-padding">*/}
                {/*                            {item.gateways.map(gatewaysItem => {*/}
                {/*                                return (*/}
                {/*                                    <div className={(item.keyword==="ResursBankPartPayment")?"resurs-form":"gateway-item"} key={gatewaysItem.name}>*/}
                {/*                                        <b>{gatewaysItem.name}</b>*/}
                {/*                                        <form>*/}
                {/*                                            {gatewaysItem.form.elements.map((formItem,key) => {*/}
                {/*                                                let fieldLabel;*/}
                {/*                                                switch(formItem.key) {*/}
                {/*                                                    case "Firstname":*/}
                {/*                                                        fieldLabel = Dictionary.firstName;*/}
                {/*                                                        break;*/}
                {/*                                                    case "Lastname":*/}
                {/*                                                        fieldLabel = Dictionary.lastName;*/}
                {/*                                                        break;*/}
                {/*                                                    case "Fullname":*/}
                {/*                                                        fieldLabel = Dictionary.fullName;*/}
                {/*                                                        break;*/}
                {/*                                                    case "Email":*/}
                {/*                                                        fieldLabel = Dictionary.email;*/}
                {/*                                                        break;*/}
                {/*                                                    case "PhoneNumber":*/}
                {/*                                                        fieldLabel = Dictionary.phoneNumber;*/}
                {/*                                                        break;*/}
                {/*                                                    case "CountryCode":*/}
                {/*                                                        fieldLabel = Dictionary.CountryCode;*/}
                {/*                                                        break;*/}
                {/*                                                    case "PostalArea":*/}
                {/*                                                        fieldLabel = Dictionary.PostalArea;*/}
                {/*                                                        break;*/}
                {/*                                                    case "PostalCode":*/}
                {/*                                                        fieldLabel = Dictionary.PostalCode;*/}
                {/*                                                        break;*/}
                {/*                                                    case "Address":*/}
                {/*                                                        fieldLabel = Dictionary.Address;*/}
                {/*                                                        break;*/}
                {/*                                                    default:*/}
                {/*                                                        fieldLabel = formItem.key;*/}
                {/*                                                    }*/}
                {/*                                                if (formItem.type !== "select") {*/}
                {/*                                                    return (<div key={key} className="form-group">*/}
                {/*                                                        <label htmlFor={formItem.key} className="form-label">{fieldLabel}</label>*/}
                {/*                                                        {*/}
                {/*                                                            formItem.key==="PhoneNumber" &&*/}
                {/*                                                            <select className="form-select country-code" onChange={(e)=>this.setState({postalCode:e.target.value})} value={this.state.postalCode}>*/}
                {/*                                                                {CountryPostalCode.map((item,index)=><option key={index}  value={item.value}>{item.code}</option>)}*/}
                {/*                                                            </select>*/}
                {/*                                                        }*/}
                {/*                                                        <input id={formItem.key} className="form-input"*/}
                {/*                                                                    type={formItem.type}*/}
                {/*                                                                    onChange={(e)=>this.bindData(formItem.key,e.target.value)}*/}
                {/*                                                                    name={formItem.key} defaultValue={formItem.defaultValue}/>*/}
                {/*                                                                    {(this.state.formStatus&&!this.state.formFeilds[formItem.key])&&<div style={{color:'red'}}>{Dictionary.thisFieldIsRequired}</div>}*/}
                {/*                                                    </div>)*/}
                {/*                                                } else {*/}
                {/*                                                    return (*/}
                {/*                                                        <div key={key} className="form-group">*/}
                {/*                                                            <label*/}
                {/*                                                                htmlFor={formItem.key} className="form-label">{fieldLabel}</label>*/}
                {/*                                                            <select className="form-select" onChange={(e)=>this.bindData(formItem.key,e.target.value)} id={formItem.key} name={formItem.key}*/}
                {/*                                                                    defaultValue={formItem.defaultValue}>*/}
                {/*                                                                <option value={null}></option>*/}
                {/*                                                                {formItem.values.map((optItem,optIndex) => <option key={optIndex} value={optItem.value}>{optItem.label}</option>)}*/}
                {/*                                                            </select>*/}
                {/*                                                            {(this.state.formStatus&&!this.state.formFeilds[formItem.key])&&<div style={{color:'red'}}>{Dictionary.thisFieldIsRequired}</div>}*/}

                {/*                                                        </div>*/}
                {/*                                                    )*/}
                {/*                                                }*/}
                {/*                                            })}*/}
                {/*                                        </form>*/}
                {/*                                        <button className="button green-btn" type="button" onClick={this.handlePaymentChoice.bind(this,gatewaysItem)}>{(item.keyword==="ResursBank")?Dictionary.send:Dictionary.select}</button>*/}

                {/*                                    </div>*/}
                {/*                                )*/}
                {/*                            })}*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        )*/}
                {/*    })*/}
                {/*}*/}
            </div>
        )

    }
}
export default PaymentList;