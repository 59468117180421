import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { showError } from "../../Actions/VisualDataAction";
import { connect } from "react-redux";
class SelectRoomTOF extends React.Component {
  static propTypes = {
    defaultAdult: PropTypes.any,
    defaultChildren: PropTypes.any,
    defaultAges: PropTypes.any,
    onSelectItem: PropTypes.any.isRequired,
  };
  state = {
    room: [{ id: 0, adult: 2, children: [] }],
  };
  //convert paramters to format
  inputToRoomJSON = () => {
    let roomInit = [];
    let baseCounter = 0;
    this.props.defaultAdult.forEach((item, index) => {
      let roomUnit = { adult: item, children: [] };
      for (
        let childCounter = baseCounter;
        childCounter <= this.props.defaultChildren[index] + baseCounter - 1;
        childCounter++
      ) {
        roomUnit.children.push(this.props.defaultAges[childCounter]);
      }
      baseCounter += this.props.defaultChildren[index];
      roomInit.push(roomUnit);
    });
    this.setState({
      room: roomInit,
    });
  };
  outputToRoomJSON = () => {
    let adult = [];
    let children = [];
    let age = [];
    this.state.room.forEach((value, index) => {
      adult[index] = value.adult;
      children[index] = value.children.length;
      age.push(...value.children);
    });
    this.props.onSelectItem({ adult, children, age });
  };
  //handle input
  handleEvent = (type, number, index, indexChild) => {
    number = Number(number);
    let cash = JSON.stringify(this.state.room);
    let roomItem = JSON.parse(cash);
    if (type === "room") {
      let roomLength = roomItem.length;
      if (number > roomLength) {
        for (let cnt = 1; cnt <= number - roomLength; cnt++) {
          roomItem.push({ adult: 2, children: [] });
        }
      } else {
        roomItem = roomItem.slice(0, number);
      }
    } else if (type === "children") {
      let childrenLength = roomItem[index].children.length;
      if (number > childrenLength) {
        for (let cnt = 1; cnt <= number - childrenLength; cnt++) {
          roomItem[index].children.push(0);
        }
      } else {
        roomItem[index].children = roomItem[index].children.slice(0, number);
      }
    } else if (type === "adult") {
      roomItem[index].adult = number;
    } else if (type === "age") {
      roomItem[index].children[indexChild] = number;
    }
    this.setState(
      {
        room: roomItem,
      },
      () => {
        this.outputToRoomJSON();
      }
    );
  };
  componentDidMount() {
    this.inputToRoomJSON();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.defaultAdult !== this.props.defaultAdult ||
      prevProps.defaultChildren !== this.props.defaultChildren ||
      prevProps.defaultAges !== this.props.defaultAges
    ) {
      this.inputToRoomJSON();
    }
  }
  render() {
    const { Dictionary } = this.props;
    return (
      <>
        <div className="col-xsmall-12 col-small-2">
          <div className="form-group">
            <label className="form-label" htmlFor={"SelectNumber"}>
              {Dictionary.room}
            </label>
            {
              <select
                id={"roomSelect"}
                className="itours-select"
                value={this.state.room.length}
                onChange={(e) => this.handleEvent("room", e.target.value)}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </select>
            }
          </div>
        </div>
        <div className="col-xsmall-12 col-small-10">
          {/* each room guests */}
          {this.state.room.map((item, index) => {
            return (
              <div
                className={
                  this.state.room.length > 1
                    ? "room-guests multyroom"
                    : "room-guests"
                }
                key={index}
              >
                {this.state.room.length > 1 && (
                  <div className="room-number">
                    {Dictionary.room} {index + 1}
                  </div>
                )}
                <div>
                  <div className={"float-row"}>
                    <div className="col-xsmall-3 col-small-2">
                      <div className="form-group">
                        {!index && (
                          <label
                            className="form-label"
                            htmlFor={"adultNumber" + index}
                            title="Voksen (12+)"
                          >
                            {Dictionary.adult}
                          </label>
                        )}
                        <select
                          id={"adultNumber" + index}
                          className="itours-select"
                          value={item.adult}
                          onChange={(e) =>
                            this.handleEvent("adult", e.target.value, index)
                          }
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xsmall-3 col-small-2">
                      <div className="form-group">
                        {!index && (
                          <label
                            className="form-label"
                            htmlFor={"childNumber" + index}
                            title="Barn (Under 12 år)"
                          >
                            {Dictionary.child}
                          </label>
                        )}
                        <select
                          id={"childNumber" + index}
                          className="itours-select"
                          value={item.children.length}
                          onChange={(e) =>
                            this.handleEvent("children", e.target.value, index)
                          }
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xsmall-6 col-small-8">
                      <div className="children-ages">
                        <div className="form-group">
                          {!index && item.children.length > 0 && (
                            <label className="form-label">
                              {Dictionary.age}
                            </label>
                          )}

                          <div className="children-ages-block">
                            {item.children.map((age, ageIndex) => {
                              return (
                                <select
                                  key={index.toString() + ageIndex.toString()}
                                  id={
                                    "childAge" +
                                    index.toString() +
                                    ageIndex.toString()
                                  }
                                  className="itours-select"
                                  value={age}
                                  onChange={(e) =>
                                    this.handleEvent(
                                      "age",
                                      e.target.value,
                                      index,
                                      ageIndex
                                    )
                                  }
                                >
                                  <option value={0}>0</option>
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>5</option>
                                  <option value={6}>6</option>
                                  <option value={7}>7</option>
                                  <option value={8}>8</option>
                                  <option value={9}>9</option>
                                  <option value={10}>10</option>
                                  <option value={11}>11</option>
                                  <option value={12}>12</option>
                                  <option value={13}>13</option>
                                  <option value={14}>14</option>
                                  <option value={15}>15</option>
                                  <option value={16}>16</option>
                                  <option value={17}>17</option>
                                  <option value={18}>18</option>
                                </select>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => dispatch(showError(param)),
});
const RouterPath = withRouter(SelectRoomTOF);
export default connect(mapStateToProps, mapDispatchToProps)(RouterPath);
