import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Steps } from 'antd';

import { ServerAddress, Header, DomesticHotelApiUrls } from "../../Enum/Urls";
import DomesticHotelSummery from "./DomesticHotelSummery";
import Timer from "../Common/Timer";
import LayoutConfig from "../../Config/layoutConfig";
import Loading from "../Common/Loading";

const DomesticHotelCapacityCheck = props => {

    const { Step } = Steps;
    const { Dictionary } = props;

    const [reserveInfo, setReserveInfo] = useState();
    const [HotelInfo, setHotelInfo] = useState();
    const [timeUp, setTimeUp] = useState(false);

    const queryParams = props.location.search?.split("#")[0];
    const reserve_id = queryParams.split("reserveId=")[1]?.split("&")[0];
    const reserve_userName = queryParams.split("username=")[1]?.split("&")[0];
    const smid = queryParams.split("smid=")[1]?.split("&")[0]; 

    let hotelId;
    if (reserveInfo) {
        hotelId = reserveInfo.accommodation?.id;
    }

    useEffect(() => {
        let fetchReserveInterval;
        const fetchReserveData = async () => {
            const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelB}${DomesticHotelApiUrls.GetReserveById}?reserveId=${reserve_id}&username=${reserve_userName}`,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            );
            if (response?.data?.result) {
                if (response.data.result.status && response.data.result.status === "Pending") {
                    clearInterval(fetchReserveInterval);
                    if(smid){
                        props.history.push(
                            `/payment/reserve?username=${reserve_userName}&reserveId=${reserve_id}&smid=${smid}`
                        );
                    }else{
                        props.history.push(
                            `/payment/reserve?username=${reserve_userName}&reserveId=${reserve_id}`
                        );
                    }
                } else {
                    setReserveInfo(prevReserveInfo => {
                        if (prevReserveInfo) {
                            return prevReserveInfo;
                        } else {
                            return response.data.result
                        }
                    });
                }
            }
        }
        fetchReserveData();
        fetchReserveInterval = setInterval(fetchReserveData, 5000);

        return (() => {
            clearInterval(fetchReserveInterval);
        })

    }, [reserve_id]);

    useEffect(() => {

        const fetchHotelData = async () => {
            const response = await axios.get(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${DomesticHotelApiUrls.GetHotelById}?hotelId=${hotelId}`,
                {
                    headers: {
                        ...Header,
                        apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                        'Accept-Language': 'fa-IR'
                    }
                }
            );
            if (response?.data) {
                setHotelInfo(response.data);
            }
        }

        if (hotelId) {
            fetchHotelData();
        }

    }, [hotelId]);

    let reserveInformation, hotelInformation;

    if (HotelInfo) {
        hotelInformation = {
            image: {
                url: HotelInfo.ImageUrl,
                alt: HotelInfo.ImageAlt,
                title: HotelInfo.ImageTitle
            },
            name: `${HotelInfo.HotelCategoryName} ${HotelInfo.HotelName} ${HotelInfo.CityName}`,
            rating: HotelInfo.HotelRating,
            address: HotelInfo.Address,
            TopSelling: HotelInfo.TopSelling,
            Url: HotelInfo.Url,
            CityId: HotelInfo.CityId
        }
    }
    if (reserveInfo) {
        reserveInformation = {
            checkin: reserveInfo.checkin,
            checkout: reserveInfo.checkout,
            duration: reserveInfo.count,
            rooms: reserveInfo.rooms.map(roomItem => ({
                name: roomItem.name,
                board: roomItem.boardCode,
                cancellationPolicyStatus: roomItem.cancellationPolicyStatus,
                bed: roomItem.bed,
                extraBed: roomItem.extraBed,
                pricing: roomItem.pricing,
                maxChildAge: roomItem.maxChildAge,
                maxInfantAge: roomItem.maxInfantAge
            })),
            salePrice: reserveInfo.rooms.reduce((totalPrice, roomItem) => {
                const roomItemPrice = roomItem.pricing.find(
                    item => item.type === "Room" && item.ageCategoryType === "ADL"
                )?.amount;
                if (roomItemPrice) {
                    return totalPrice + +roomItemPrice
                } else {
                    return null;
                }
            }, 0),
            boardPrice: reserveInfo.rooms.reduce((totalPrice, roomItem) => {
                const roomItemPrice = roomItem.pricing.find(
                    item => item.type === "RoomBoard" && item.ageCategoryType === "ADL"
                )?.amount;
                if (roomItemPrice) {
                    return totalPrice + +roomItemPrice
                } else {
                    return null;
                }
            }, 0)
        }
    }

    let message = "در حال بررسی ظرفیت هتل هستیم، لطفا  منتظر بمانید ...";
    let communicationIcon = (<span className="communication margin-x">
        {[1, 2, 3, 4, 5, 6, 7, 8].map(item => <span className="dot" key={item} />)}
    </span>);

    if (reserveInfo?.status && reserveInfo.status === 13) {
        message = "ظرفیت برای این هتل تکمیل شده است، کارشناسان ما برای راهنمایی بیشتر با شما تماس خواهند گرفت";
        communicationIcon = <span className="itours-icon red-close icon-xlarge margin-x" />
    } else if (timeUp) {
        message = "با عرض پوزش بابت تاخیر در بررسی ظرفیت این هتل، همکاران ما در حال پیگیری رزرو شما هستند و به زودی با شما تماس می گیرند";
        communicationIcon = <span className="itours-icon red-close icon-xlarge margin-x" />
    }

    if (!reserveInfo || !HotelInfo){
        return (
            <Loading style2={LayoutConfig.themeClassName === "style-2"} fullPage />
        )
    }
    return (
        <div className="page-padding">
            <div className="page-container hotel-capacity">
                <Steps current={2}>
                    <Step title={Dictionary.selectHotel} />
                    <Step title={Dictionary.PassengersInformation} />
                    <Step title={"بررسی ظرفیت"} />
                    <Step title={Dictionary.paymentTypeOptions} />
                </Steps>
                <div className="getPassengers">
                    {!!hotelInformation && (
                        <div className="sides-outer-holder">
                            <div className="sides-holder">
                                <div className="large-side">
                                    <div className="side-item full-width">
                                        <div className="side-item-inner">
                                            <div className="card-padding box-border bg-white text-center">
                                                <br />
                                                <div className="margin-bottom">
                                                    <Timer initialTime={600} callBack={() => { setTimeUp(true) }} />
                                                </div>

                                                <p>{message}</p>

                                                <div className="margin-top-large margin-bottom-large communication-wrapper">
                                                    <span className="itours-icon headset-support-gray icon-xlarge" />
                                                    {communicationIcon}
                                                    <span className="itours-icon style-2-hotel-line-gray icon-xlarge" />
                                                </div>

                                                <p>
                                                    شما میتوانید از طریق کد زیر رزرو خود را پیگیری کنید
                                                </p>

                                                <span className="capicity-track-code">{reserve_id}</span>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="small-side">
                                    <div className="side-item full-width">
                                        <div className="side-item-inner">

                                            <DomesticHotelSummery
                                                hotelInformation={hotelInformation}
                                                reserveInformation={reserveInformation}
                                                currencyCode={props.currencyCode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    error: state.VisualData.error,
    currencyCode: state.UserData.Currency,
    userData: state.UserData.userData,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
});


export default withRouter(connect(mapStateToProps)(DomesticHotelCapacityCheck));