import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
//redux
import {applyMiddleware,createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import createRootReducer from "./Reducer";
import thunk from "redux-thunk";
const history=createBrowserHistory();
const store=createStore(
    createRootReducer(history),composeWithDevTools(applyMiddleware(routerMiddleware(history),thunk))
);
//const store=createStore(createRootReducer(history),composeWithDevTools(applyMiddleware(routerMiddleware(history),thunk)));
export {store,history}