import React from "react";
import Autocomplete from "../Common/Autocomplete.js";
import { Link } from "react-router-dom";
import { ServerAddress, Hotel, Header } from "../../Enum/Urls";
import SelectRoomTOF from "./SelectRoom-TOF";
// import { Rangepicker } from "react-itours-rangepicker";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-jalaali";
import MultiDatePicker from 'react-multi-date-picker';
import { connect } from "react-redux";
import LayoutConfig from '../../Config/layoutConfig';

/* import flightIcon from '../../image/flight.svg';
import hotelIcon from '../../image/hotel.svg'; */
class HotelFormSearchTOF extends React.Component {
  state = {
    validation: false,
    searchParam: {
      locationId: "",
      location: "",
      searchType: "",
      adults: [2],
      children: [0],
      ages: [0],
      checkin: null,
      checkout: null,
    },
    night: 0,
    searchMode: false,
    calendarIsGregorian: false,
    selectPassengersOptionVisibility: false,
    roomOptionSelect: "2ADT",
    datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
    datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
  };

  fillFromURL() {
    if (this.props.location.search !== "") {
      this.setState({ calendarIsGregorian: true });
      let parameters = queryString.parse(this.props.location.search);
      if (this.props.location.pathname === "/hotel/details") {
        this.setState(
          {
            searchParam: {
              ...parameters,
              checkin: parameters.Checkin,
              checkout: parameters.Checkout,
              adults: parameters.Adults.split(",").map((item) => Number(item)),
              children: parameters.Children.split(",").map((item) =>
                Number(item)
              ),
              ages: parameters.Ages
                ? parameters.ages.split(",").map((item) => Number(item))
                : [],
            },
          },
          () => {
            this.calculateDuration();
            this.checkSelectedPassengers();
          }
        );
      } else {
        this.setState(
          {
            searchParam: {
              ...parameters,
              checkin: parameters.checkin,
              checkout: parameters.checkout,
              adults: parameters.adults.split(",").map((item) => Number(item)),
              children: parameters.children
                .split(",")
                .map((item) => Number(item)),
              ages: parameters.ages
                ? parameters.ages.split(",").map((item) => Number(item))
                : [],
            },
          },
          () => {
            this.calculateDuration();
          }
        );
      }
    }
  }

  autoCompleteCallback = (id, text , type) => {
    if (id){
      let searchParamCatch = this.state.searchParam; 
        searchParamCatch.locationId = id;
        searchParamCatch.location = text; 
        searchParamCatch.searchType = type;   
      this.setState({
        searchParam: { ...this.state.searchParam, ...searchParamCatch },
      });
    }else{
      let searchParamCatch = this.state.searchParam;      
      searchParamCatch.locationId = undefined;
      searchParamCatch.location = undefined; 
      searchParamCatch.searchType = undefined;   
      this.setState({
        searchParam: { ...this.state.searchParam, ...searchParamCatch },
      });
    }
  };


  componentDidMount() {
    this.fillFromURL();
    if (this.props.collapseMode) {
      this.setState({
        searchMode: false,
      });
    } else {
      this.setState({
        searchMode: true,
      });
      if (this.props.location.search === "") {
        this.setState({
          searchParam: {
            ...this.state.searchParam,
            checkin: moment().format("YYYY-MM-DD"),
            checkout: moment().add(1, "day").format("YYYY-MM-DD"),
          },
        });
      }
    }
  }
  changeCalendarType = () => {
    this.setState((prevState) => {
      return { calendarIsGregorian: !prevState.calendarIsGregorian };
    });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.fillFromURL();
    }
  }
  disabledDate = (index, current) => {
    // Can not select days before today and today

    if (index === "checkout") {
      return current && current <= moment(this.state.searchParam.checkin);
    } else {
      return current && current <= moment().endOf("day").subtract(1, "day");
    }
  };
  resultRef = () => {
    console.log(this.myRef);
  };
  // searchCatch = {
  //   location: { selectedValue: null, selectedText: null, typingValue: null },
  //   passengers: null,
  //   dates: null,
  // };
  defaultDay = new Date();
  toggleSearchMode = () => {
    this.setState((prevState) => ({
      searchMode: !prevState.searchMode,
    }));
  };
  submitHandler = () => {
    this.setState({
      validation: true,
    });
    let statusForm = true;
    if (!this.state.searchParam.location || !this.state.searchParam.checkin || !this.state.searchParam.checkout) {
      statusForm = false;
    }
    if (statusForm){
      
      if (this.state.searchParam.searchType === 6) {
        this.props.history.push(
          `/hotel/details?Id=${this.state.searchParam.locationId}&Adults=${this.state.searchParam.adults}&Children=${this.state.searchParam.children}&Ages=${this.state.searchParam.ages}&Checkin=${this.state.searchParam.checkin}&Checkout=${this.state.searchParam.checkout}`
        );
      } else {
        this.props.history.push(
          `/hotel/result?locationId=${this.state.searchParam.locationId}&location=${this.state.searchParam.location}&checkin=${this.state.searchParam.checkin}&checkout=${this.state.searchParam.checkout}&adults=${this.state.searchParam.adults}&children=${this.state.searchParam.children}&ages=${this.state.searchParam.ages}`
        );
      }
      
    }
  };


//   handleClick = () => {
//     debugger;
//     this.setState(
//       {
//         searchParam: {
//           ...this.state.searchParam,
//           locationId: this.state.searchParam.locationId,
//           location: this.state.searchParam.location,
// //          searchType: this.searchCatch.location.typingValue,
//           // checkin:(this.searchCatch.dates!==null) ? moment(this.searchCatch.dates[0]._d).format("YYYY-MM-DD") : null,
//           // checkout:(this.searchCatch.dates!==null) ? moment(this.searchCatch.dates[1]._d).format("YYYY-MM-DD") : null,
//         },
//       },
//       () => {
//         let locationValidator;
//         let dateValidator;
//         if (
//           this.state.searchParam.location != null &&
//           this.state.searchParam.locationId != null
//         ) {
//           locationValidator = true;
//           this.setState((prevState) => ({
//             searchValidator: { ...prevState.searchValidator, location: true },
//           }));
//         } else {
//           locationValidator = false;
//           this.setState((prevState) => ({
//             searchValidator: { ...prevState.searchValidator, location: false },
//           }));
//         }
//         if (
//           this.state.searchParam.checkin != null &&
//           this.state.searchParam.checkout != null
//         ) {
//           dateValidator = true;
//           this.setState((prevState) => ({
//             searchValidator: { ...prevState.searchValidator, dates: true },
//           }));
//         } else {
//           dateValidator = false;
//           this.setState((prevState) => ({
//             searchValidator: { ...prevState.searchValidator, dates: false },
//           }));
//         }

//         if (locationValidator && dateValidator) {
//           if (this.state.searchParam.searchType === 6) {
//             this.props.history.push(
//               `/hotel/details?Id=${this.state.searchParam.locationId}&Adults=${this.state.searchParam.adults}&Children=${this.state.searchParam.children}&Ages=${this.state.searchParam.ages}&Checkin=${this.state.searchParam.checkin}&Checkout=${this.state.searchParam.checkout}`
//             );
//           } else {
//             this.props.history.push(
//               `/hotel/result?locationId=${this.state.searchParam.locationId}&location=${this.state.searchParam.location}&checkin=${this.state.searchParam.checkin}&checkout=${this.state.searchParam.checkout}&adults=${this.state.searchParam.adults}&children=${this.state.searchParam.children}&ages=${this.state.searchParam.ages}`
//             );
//           }
//         }
//       }
//     );
//   };

  handlePassenger = (e) => {
    let passenger = JSON.stringify(this.state.searchParam);
    passenger = JSON.parse(passenger);
    passenger.adults = e.adult;
    passenger.children = e.children;
    passenger.ages = e.age;
    this.setState({
      searchParam: { ...this.state.searchParam, ...passenger },
    });
  };
  calculateDuration = () => {
    let night = moment(this.state.searchParam.checkout).diff(
      moment(this.state.searchParam.checkin),
      "days"
    );
    this.setState({
      night: night,
    });
  };
  toggleDatepicker = () =>{
    if (this.props.LAN ==="IR"){
      if(this.state.datepickerType === "gregorian"){
        this.setState({
          datepickerType:"persian",
          datepickerLocale:"fa"
        })
      }else{
        this.setState({
          datepickerType:"gregorian",
          datepickerLocale:"en"
        })
      }
    }
  }  
  render() {
    const { Dictionary, LAN } = this.props;
    let wrapperClass = "";
    if (this.props.collapseMode){
      if(this.state.searchMode){
        wrapperClass="inline float-window";
      }else{
        wrapperClass="inline";
      }
    }

    return (
      <div className={`search-box-holder clearfix ${wrapperClass}`}>
        {this.props.inline || LayoutConfig.themeClassName === "style-2" || (
          <div className="clearfix tab-holder text-center hidden-small hidden-xsmall">
            <Link to="/" className="tab-s">
              <span className="margin-end-light">{Dictionary.flight}</span>
              <span className="itours-icon top-plane-gray margin-start-light" />
            </Link>
            <button type="button" className="tab-s active-tab-s">
              <span className="margin-end-light">{Dictionary.hotel}</span>
              <span className="itours-icon hotel-white margin-start-light" />
            </button>
            <Link to="/package" className="tab-s">
            <span className="margin-end-light">
                {Dictionary.hotel} + {Dictionary.flight}
              </span>
              <span className="itours-icon top-plane-gray margin-start-light" />
              <span className="itours-icon hotel-gray margin-start-light" />
            </Link>
          </div>
        )}

        {
          this.state.searchMode ? (
            <>
              <div className="searchbox-inner">
                <div className="searchBar hotel float-row">
                  {this.props.collapseMode && (
                    <>
                      {this.state.searchMode && (
                        <div className="close close-research">
                          <button
                            type="button"
                            className={"closeEdit"}
                            onClick={this.toggleSearchMode}
                          >
                            {Dictionary.close}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  <div
                    className={
                      this.props.inline
                        ? "col-small-12 col-medium-10"
                        : "col-small-12"
                    }
                  >
                    <div className="float-row">
                      <div
                        className={
                          this.props.inline
                            ? "col-small-12 col-medium-6 col-large-3"
                            : "col-small-12"
                        }
                      >
                        <div className="form-group hotel-destination-holder">
                          <label className="form-label no-margin-top">
                            {Dictionary.destination}
                          </label>
                          <Autocomplete
                            defaultText={this.state.searchParam.location}
                            defaultValue={this.state.searchParam.locationId}
                            getValue={this.autoCompleteCallback}
                            placeholder={Dictionary.enterYourDestination}
                            url={
                              ServerAddress.Type +
                              ServerAddress.Hotel +
                              Hotel.GetLocation
                            }
                            min={3}
                            name="destination"
                            param="value"
                            header={Header}
                          />
                          {this.state.validation &&
                              !this.state.searchParam.locationId && (
                              <b className="validator-label">
                                  {Dictionary.pleaseEnterDestination}
                              </b>
                          )}

                        </div>
                      </div>

                      <div
                        className={
                          this.props.inline
                            ? "col-small-12 col-medium-6 col-large-4"
                            : "col-small-12"
                        }
                      >
                        <div className="float-row">
                          {/*<div className="col-small-10">*/}
                          {/*    <div className="form-group date-holder">*/}
                          {/*        <label className="form-label">Innsjekking og Utsjekking</label>*/}
                          {/*        <Rangepicker showTime={false} isPersian={false} disablePastDays={true} onChange={value=>{this.searchCatch.dates=value;this.calculateDuration(value)}} />*/}
                          {/*        {*/}
                          {/*            (this.state.searchValidator.dates || <div className="validator-label">*/}
                          {/*            Angi start- og sluttdato for reservasjonen*/}
                          {/*            </div>)*/}
                          {/*        }*/}
                          {/*    </div>*/}
                          {/*</div>*/}
                          <div className="col-small-5 form-group">
                            <label className="form-label">
                              {Dictionary.checkIn}
                            </label>
                            <div className="relative">
                              <MultiDatePicker 
                                calendarPosition={LAN === "IR" || LAN === "AR" ?"bottom-right":"bottom-left"}
                                weekDays={this.state.datepickerLocale === "fa" ? [
                                  "ش", 
                                  "ی", 
                                  "د", 
                                  "س", 
                                  "چ", 
                                  "پ", 
                                  "ج"
                                ]:this.state.datepickerLocale === "ar" ?[
                                  "الأحد",
                                  "الإثنين",
                                  "الثلاثاء",
                                  "الأربعاء",
                                  "الخميس",
                                  "الجمعة",
                                  "السبت"
                                ]:undefined}
                                digits={this.state.datepickerLocale === "ar" ? [
                                  "0",
                                  "1",
                                  "2",
                                  "3",
                                  "4",
                                  "5",
                                  "6",
                                  "7",
                                  "8",
                                  "9"
                                ]:undefined}
                                inputClass="multi-datepicker-input"
                                className={`multi-datepicker ${LAN === "IR" && this.state.datepickerLocale === "en" ? "en-font":""}`}
                                minDate={this.state.datepickerType ==="persian"? moment().format("jYYYY/jMM/jDD") : moment().format("YYYY/MM/DD")}
                                format={"YYYY-MM-DD"}
                                calendar={this.state.datepickerType}
                                locale={this.state.datepickerLocale}
                                value={this.state.datepickerType ==="persian"? moment(this.state.searchParam.checkin,"YYYY/MM/DD").format("jYYYY/jMM/jDD"): this.state.searchParam.checkin}
                                onChange={(d) => {
                                  const e = d.toDate();
                                  this.setState(
                                    {
                                      searchParam: {
                                        ...this.state.searchParam,
                                        checkin: moment(e).format(
                                          "YYYY-MM-DD"
                                        ),
                                        checkout: moment(e)
                                          .add(1, "day")
                                          .format("YYYY-MM-DD"),
                                      },
                                    },
                                    () => {
                                      this.calculateDuration();
                                    }
                                  );
                                }}
                              >
                                {LAN==="IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی":"تقویم شمسی"}</button>}
                              </MultiDatePicker>


                              {this.state.validation &&
                                !this.state.searchParam.checkin && (
                                  <b className="validator-label">
                                    {Dictionary.enterYourDestination}
                                  </b>
                                )}
                            </div>
                          </div>
                          <div className="col-small-5 form-group">
                            <label className="form-label">
                              {Dictionary.checkOut}
                            </label>
                            <div className="relative">
                     <MultiDatePicker 
                                        calendarPosition={LAN === "IR" || LAN === "AR" ?"bottom-right":"bottom-left"}
                                        weekDays={this.state.datepickerLocale === "fa" ? [
                                          "ش", 
                                          "ی", 
                                          "د", 
                                          "س", 
                                          "چ", 
                                          "پ", 
                                          "ج"
                                        ]:this.state.datepickerLocale === "ar" ?[
                                          "الأحد",
                                          "الإثنين",
                                          "الثلاثاء",
                                          "الأربعاء",
                                          "الخميس",
                                          "الجمعة",
                                          "السبت"
                                        ]:undefined}
                                        digits={this.state.datepickerLocale === "ar" ? [
                                          "0",
                                          "1",
                                          "2",
                                          "3",
                                          "4",
                                          "5",
                                          "6",
                                          "7",
                                          "8",
                                          "9"
                                        ]:undefined}
                                        inputClass="multi-datepicker-input"
                                        className={`multi-datepicker ${LAN === "IR" && this.state.datepickerLocale === "en" ? "en-font":""}`}
                                        minDate={
                                          this.state.datepickerType ==="persian"? 
                                            moment(this.state.searchParam.checkin).add(1, "day").format("jYYYY/jMM/jDD") 
                                          :
                                            moment(this.state.searchParam.checkin).add(1, "day").format("YYYY/MM/DD")
                                        }
                                        format={"YYYY-MM-DD"}
                                        calendar={this.state.datepickerType}
                                        locale={this.state.datepickerLocale}
                                        value={this.state.datepickerType ==="persian"? moment(this.state.searchParam.checkout,"YYYY/MM/DD").format("jYYYY/jMM/jDD"): this.state.searchParam.checkout}
                                        onChange={(d) => {
                                          const e = d.toDate();
                                          this.setState(
                                            {
                                              searchParam: {
                                                ...this.state.searchParam,
                                                checkout: moment(e).format(
                                                  "YYYY-MM-DD"
                                                )
                                              },
                                            },
                                            () => {
                                              this.calculateDuration();
                                            }
                                          );
                                        }}
                                      >
                                        {LAN==="IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی":"تقویم شمسی"}</button>}
                                      </MultiDatePicker>

                              {this.state.validation &&
                                !this.state.searchParam.checkout && (
                                  <b className="validator-label">
                                    {Dictionary.pleaseSelectCheckinDate}
                                  </b>
                                )}
                            </div>
                          </div>
                          <div className="col-small-2 form-group">
                            <label className="form-label">
                              {" "}
                              {Dictionary.nights}
                            </label>
                            <div className="relative">
                              <div className="relative">
                                <span className="form-control">
                                  {this.state.night}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          this.props.inline
                            ? "col-small-12 col-medium-12 col-large-5"
                            : "col-small-12"
                        }
                      >
                        <div className="float-row">
                          <SelectRoomTOF
                            onSelectItem={(e) => {
                              this.handlePassenger(e);
                            }}
                            defaultAdult={this.state.searchParam.adults}
                            defaultChildren={this.state.searchParam.children}
                            defaultAges={this.state.searchParam.ages}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.props.inline
                        ? "col-small-12 col-medium-2"
                        : "col-small-12"
                    }
                  >
                    <div
                      className={
                        this.props.inline
                          ? "clearfix form-group"
                          : "clearfix search-btn-holder form-group"
                      }
                    >
                      <button
                        className="searchBtn button red-btn pull-end full-width hotel-search-btn"
                        type="button"
                        onClick={() => {
                          this.submitHandler();
                        }}
                      >
                        {Dictionary.search}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null
          //   this.state.searchParam.checkin == null || (
          //     <>
          //       <div className={"headerInformation"}>
          //         <div className={"title"}>
          //           <span className="icons-sprite bed-icon icon-a margin-right-light" />{" "}
          //           søkeoppsummering
          //         </div>
          //         <div className={"buttonContainer"}>
          //           <button
          //             type="button"
          //             className={"button blue-btn pull-end"}
          //             onClick={this.toggleSearchMode}
          //           >
          //             endre søk
          //           </button>
          //         </div>
          //       </div>
          //       <div className="searched-information">
          //         <div className={"fakeForm"}>
          //           <div className="textbox">
          //             <span className="icons-sprite map-marker-icon icon-a margin-right-light" />{" "}
          //             {this.state.searchParam.location}
          //           </div>
          //           <div className="textbox">
          //             {" "}
          //             <span className="icons-sprite checkin-date-icon icon-a margin-right-light" />{" "}
          //             {this.state.searchParam.checkin}
          //           </div>
          //           <div className="textbox">
          //             <span className="icons-sprite checkout-date-icon icon-a margin-right-light" />{" "}
          //             {this.state.searchParam.checkout}
          //           </div>
          //           <div className="textbox">
          //             <span className="icons-sprite dark-moon-icon icon-a margin-right-light" />{" "}
          //             {moment(this.state.searchParam.checkout).diff(
          //               moment(this.state.searchParam.checkin),
          //               "days"
          //             )}
          //           </div>
          //           <div className="textbox">
          //             <span className="icons-sprite bed-icon icon-a margin-right-light" />{" "}
          //             {this.state.searchParam.adults.length.toString()} rom
          //           </div>
          //         </div>
          //       </div>
          //     </>
          //   )
        }
      </div>
    );
  }
}
const RouterDOM = withRouter(HotelFormSearchTOF);
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(RouterDOM);
