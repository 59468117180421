//import libraries
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setCurrency } from "../../Actions/UserAction";
//import redux actions
import { toggleAccountWindow } from "../../Actions/VisualDataAction";
import { SwitchAccountWindow } from "../../Actions/VisualDataAction";
import { GetReserveById } from "../../Actions/PaymentAction";
//load UI components
import ProfileBallon from "../Admin/ProfileBallon";
import IrLogin from "../Admin/IrLogin/IrLogin";
//load Assets files

import LayoutConfig from "../../Config/layoutConfig";

//declaration Class Component
class Header extends React.Component {
  state = {
    routerInfo: null,
    disableLink: false,
    trackEmail: null,
    trackReserveID: null,
    showTrack: false,
    MenuClass: "",
  };

  handleClickOutsideTrackingOrder = (e) => {
    if (this.trackingOrderBox && !this.trackingOrderBox.contains(e.target)) {
      this.setState((prevState) => ({
        showTrack: false,
        trackEmail: null,
        trackReserveID: null
      }));
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideTrackingOrder);

    if (this.props.LAN === "IR"){
      this.props.setCurrency("IRR");
    }
    this.setState({
      routerInfo: this.props.location.pathname.split("/")[1],
    });
    let path = this.props.history.location.pathname.split("/");
    if (path[1] === "payment") {
      this.setState({
        disableLink: true,
      });
    } else {
      this.setState({
        disableLink: false,
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        routerInfo: this.props.location.pathname.split("/")[1],
      });
    }
    if (this.props.currency !== prevProps.currency) {
      window.location.reload();
    }
    if (this.props !== prevProps) {
      let path = this.props.history.location.pathname.split("/");
      if (path[1] === "payment") {
        this.setState({
          disableLink: true,
        });
      } else {
        this.setState({
          disableLink: false,
        });
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideTrackingOrder);
  }
  changeCurrency = (currency) => {
    this.props.getCurrency(currency);
  };

  setToggleTopMenuClass = () => {
    if (this.state.MenuClass !== "expanded") {
      this.setState({
        MenuClass: "expanded",
      });
    } else {
      this.setState({
        MenuClass: "",
      });
    }
  };
  trackOrderHandler = () => {
    this.setState({
      showTrack: false,
      trackEmail: null,
      trackReserveID: null
     });
     let queryParams = this.props.location.search?.split("#")[0];
     const pathReserveId = queryParams.split("reserveId=")[1]?.split("&")[0];
     const pathUserName = queryParams.split("userName=")[1]?.split("&")[0]; 
     const enterdReserveId = this.state.trackReserveID;
     const enterdUserName = this.state.trackEmail;
     if (pathReserveId && pathUserName && pathReserveId===enterdReserveId && pathUserName===enterdUserName){
      let params = new URLSearchParams();
      params.append("reserveId", enterdReserveId);
      params.append("userName", enterdUserName);
      this.props.GetReserveById(params);
     }else{
       this.props.history.push(
         `/profile/details?userName=${this.state.trackEmail}&reserveId=${this.state.trackReserveID}`
       );
     }
  }

  render() {
    const culture = window.location.origin;
    const { Dictionary, LAN } = this.props;
    const {
      SwitchAccountWindow,
      Verification,
      toggleAccountWindow,
      UserData,
      smPixel,
      smHotelPixel
    } = this.props;


    const logo = <div className="header-logo-holder">
      <Link className="logo" to={"/"}>
        <img src={LayoutConfig.logo} alt={LayoutConfig.companyName} />
      </Link>
    </div> ;

    const account =
      LayoutConfig.themeClassName === 'padide-com-theme' ? null : (        
          Verification ? (
            <ProfileBallon model={UserData} />
          ) : process.env.REACT_APP_THEME === 'ITOURS_IR' ? (
            <IrLogin />
          ) : (
            <div className="account">
              <div
                className="link inline-block"
                onClick={() => {
                  SwitchAccountWindow(false);
                  toggleAccountWindow();
                }}>
                {LayoutConfig.themeClassName === 'style-2' ? `${Dictionary.login} / ${Dictionary.register}` : Dictionary.login}
              </div>
            </div>
          )
      );

    const trackingOrder = (<div className={`relative ${LayoutConfig.themeClassName==="style-2"?"margin-end":""}`} ref={(element)=>{this.trackingOrderBox=element}}>
      <button
        type="button"
        className="button color-1-btn small-btn track-order-btn"
        onClick={() => {
          this.setState((prevState) => ({
            showTrack: !prevState.showTrack,
            trackEmail: null,
            trackReserveID: null
          }));
        }}
      >
        {Dictionary.trackOrder}
      </button>
      {this.state.showTrack && (
        <div className={"tracking"}>
          <div className={"trackingContainer"}>
            <div className={"username form-group"}>
              <label htmlFor="trackingUsername">
                *{Dictionary.email}
              </label>
              <input
                className="form-input placeholder-right"
                id={"trackingUsername"}
                value={this.state.trackEmail}
                onChange={(e) => {
                  this.setState({ trackEmail: e.target.value });
                }}
                type={"text"}
                placeholder={Dictionary.enterYourEmail}
              />
            </div>
            <div className={"reserveNumber form-group"}>
              <label htmlFor="trackingUsername">
                *{Dictionary.reservationCode}{" "}
              </label>
              <input
                className="form-input placeholder-right"
                id={"trackingUsername"}
                value={this.state.trackReserveID}
                onChange={(e) => {
                  this.setState({ trackReserveID: e.target.value });
                }}
                type="text"
                placeholder={
                  Dictionary.enterTheReservationCodeYouReceived
                }
              />
            </div>
            <div className="clearfix">
              <button
                type="button"
                className="button color-1-btn"
                disabled={
                  !(this.state.trackEmail && this.state.trackReserveID)
                }
                onClick={this.trackOrderHandler}
              >
                {Dictionary.send}
              </button>
              <button
                type="button"
                className="button red-btn pull-end"
                onClick={() => {
                  this.setState({ 
                    showTrack: false,
                    trackEmail:null,
                    trackReserveID:null
                  });
                }}
              >
                {Dictionary.close}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>);

    const mainMenu =(<div className="main-menu-wrapper">
      <button
        type="button"
        className="menu-toggle-btn"
        onClick={this.setToggleTopMenuClass}
      >
        <span className="menu-btn-bar icon-top-bar"></span>
        <span className="menu-btn-bar icon-middle-bar"></span>
        <span className="menu-btn-bar icon-bottom-bar"></span>
      </button>
      <div>
        {this.state.MenuClass === "expanded" && (
          <span
            className={"menu-overlay"}
            onClick={this.setToggleTopMenuClass}
          />
        )}
        <div className={`main-menu-holder ${this.state.MenuClass}`}>
          <button
            type="button"
            className="opened menu-toggle-btn red-arrow-right itours-icon mirror-ltr"
            onClick={this.setToggleTopMenuClass}
          />
          <ul className="main-menu">
            {LayoutConfig.hasDomesticFlight ?
              <li
                className={
                  this.state.routerInfo === "domesticflight" ||  (this.state.routerInfo === "" && (LayoutConfig.hasDomesticFlight)) 
                    ? "active menu-item"
                    : "menu-item"
                }
              >
                <Link
                  to={"/"}
                  className="menu-link"
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="itours-icon top-plane-gray hidden-medium hidden-large margin-end-light" />
                  {Dictionary.domesticFlight}
                </Link>
              </li>
            :null}                      
            <li
              className={
                (this.state.routerInfo === "" && (!LayoutConfig.hasDomesticFlight)) ||
                this.state.routerInfo === "flight" ||
                this.state.routerInfo === "foreignflight"
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Link
                to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"}
                className="menu-link"
                onClick={this.setToggleTopMenuClass}
              >
                <span className="itours-icon top-plane-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.foreignFlight}
              </Link>
            </li>
            
            {!!LayoutConfig.hasDomesticHotel && <li
              className={
                this.state.routerInfo === "domesticHotel"
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Link to="/domesticHotel" className="menu-link" onClick={this.setToggleTopMenuClass} >
                <span className="itours-icon hotel-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.domesticHotel}
              </Link>
            </li>}

            <li
              className={
                this.state.routerInfo === "hotel"
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Link
                to={"/hotel"}
                className="menu-link"
                onClick={this.setToggleTopMenuClass}
              >
                <span className="itours-icon hotel-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.foreignHotel}
              </Link>
            </li>

            <li
              className={
                this.state.routerInfo === "package"
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Link
                to={"/package"}
                className="menu-link"
                onClick={this.setToggleTopMenuClass}
              >
                <span className="itours-icon package-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.package}
              </Link>
            </li>                      

            {LayoutConfig.themeClassName==="style-2" || <>
              <li
                className={
                  this.state.routerInfo === "terms"
                    ? "active menu-item"
                    : "menu-item"
                }
              >
                <Link
                  to={"/terms"}
                  className="menu-link"
                  disabled={
                    LayoutConfig.themeClassName === "padide-com-theme" ||
                    LayoutConfig.themeClassName === "naminTravel-theme__"
                  }
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="itours-icon terms hidden-medium hidden-large margin-end-light" />
                  {Dictionary.termsAndConditions}
                </Link>
              </li>
              <li
                className={
                  this.state.routerInfo === "about"
                    ? "active menu-item"
                    : "menu-item"
                }
              >
                <Link
                  to={"/about"}
                  className="menu-link"
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="itours-icon about-us hidden-medium hidden-large margin-end-light" />
                  {Dictionary.aboutUs}
                </Link>
              </li>
              <li
                className={
                  this.state.routerInfo === "contact"
                    ? "active menu-item"
                    : "menu-item"
                }
              >
                <Link
                  to={"/contact"}
                  className="menu-link"
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="itours-icon contact-us hidden-medium hidden-large margin-end-light" />
                  {Dictionary.contactUs}
                </Link>
              </li>
            </>
          }
          </ul>
        </div>
      </div>
    </div>);


    let currencyElement = <div className="link margin-end-half inline-block">
      <select
        disabled={this.state.disableLink}
        defaultValue={this.props.userData.Currency}
        className="itours-select"
        onChange={(e) => {
          this.props.setCurrency(e.target.value);
        }}
      >
        {LAN === "IR" && <option value="IRR" selected={this.props.userData.Currency === "IRR"}> IRR </option>}
        <option value="EUR" selected={this.props.userData.Currency === "EUR"}>EUR </option>
        <option value="AED" selected={this.props.userData.Currency === "AED"} > AED </option>
        <option value="DKK" selected={this.props.userData.Currency === "DKK"}> DKK </option>
        <option value="SEK" selected={this.props.userData.Currency === "SEK"}> SEK </option>
        <option value="USD" selected={this.props.userData.Currency === "USD"}> USD </option>
        <option value="NOK" selected={this.props.userData.Currency === "NOK"}> NOK </option>
        <option value="TRY" selected={this.props.userData.Currency === "TRY"}> TRY </option>
      </select>
    </div>;

    if (LayoutConfig.themeClassName === "padide-com-theme" || LAN === "IR" || LayoutConfig.themeClassName==='naminTravel-theme__'){
      currencyElement = null;
    }

    let LanguageElement = <div className="link margin-end-half inline-block">
      <select
        className="itours-select"
        onChange={(e) => {
          window.location = e.target.value;
        }}
      >
        {culture === "https://itours.ir" && (
          <option value="https://itours.ir" selected >فارسی</option>
        )}

        <option value="https://itours.co" selected={LAN === "US"}>English</option>
        <option value="https://itours.no" selected={LAN === "NO"}>Norsk</option>
        <option value="https://itours.se" selected={LAN === "SE"} >Sverige</option>
        <option value="https://itours.dk" selected={LAN === "DK"}>Dansk</option>
        <option value="https://itours.ae" selected={LAN === "AR"} >العربية</option>
        <option value="https://travelport.as" selected={LAN === "TR"} >Turkish</option>
      </select>
    </div>

    if (
      process.env.REACT_APP_SERVER_LOCALSERVER === "nortravel.se"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "padide81.com"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "sunhotel.ir"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "namintravel.com"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "santana.imt.as"
      ||
      process.env.REACT_APP_SERVER_LOCALSERVER === "itours.ir") {
      LanguageElement = null;
    }





    return LayoutConfig.themeClassName === "style-2"?(<header className="style-2-header relative">
        {smPixel && <img src={smPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}
        {smHotelPixel && <img src={smHotelPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}        
        <div className="page-container">
          <div className="style-2-header-inner">
            <div className="logo-menu">
              {logo}
              {mainMenu}
            </div>
            <div className="header-left-links">
              {trackingOrder}
              {account}
            </div>
          </div>
        </div>
      </header>)
      :
      (<header className="homeNavbar navbar box-shadow">
        {smPixel && <img src={smPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}
        {smHotelPixel && <img src={smHotelPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}        

        <div className="page-container">
          <div className="header-main-section">
            <div className="top-header">
              
              {currencyElement && LanguageElement ?
                <div>
                  {currencyElement}
                  {LanguageElement}
                </div> : <>
                  {currencyElement}
                  {LanguageElement}
                </>}

              {account}
            </div>
            {logo}
          </div>
        </div>
        <div className="header-bottom-section">
          <div className="page-container">
            <div className="clearfix">
              <div className="pull-end tracking-order-holder">
                {trackingOrder}
              </div>
              <div className="pull-start">
                {mainMenu}
              </div>
            </div>
          </div>
        </div>
      </header>)
  }
}
//map state to Props
const MapStateToProps = (state) => ({
  Verification: state.VisualData.Verification,
  userData: state.UserData,
  currency: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  smPixel:state.FlightSearch.safarmarketPixel,
  smHotelPixel:state.HotelSearch.safarmarketPixel
});
//map action to Props
const MapAction = (dispatch) => ({
  toggleAccountWindow: () => dispatch(toggleAccountWindow()),
  SwitchAccountWindow: (mode) => {
    dispatch(SwitchAccountWindow(mode));
  },
  setCurrency: (value) => {
    dispatch(setCurrency(value));
  },
  GetReserveById: (param) => {
    dispatch(GetReserveById(param));
  },
});
const routerInfo = withRouter(Header);
export default connect(MapStateToProps, MapAction)(routerInfo);
