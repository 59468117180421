import React from "react";

const NaminAmazingOffers = () =>{
    const list = [
        "Free professional support",
        "Free extra baggage",
        "Rebookable",
        "Easier to change rules",
        "Flexible ticket service",
        "Possibility to change dates or refund"
    ];
    return(<section className="namintravel-amazing-offer-section">
    <div className="page-container">
    <h3 className="namintravel-amazing-offer-title">AMAZING OFFERS</h3>
        <section className="namintravel-amazing-offer-inner">
            <div className="float-row">
                <div className="col-small-12 col-medium-6 min-height-1" />
                
                <div className="col-small-12 col-medium-6 min-height-1" >
                    <ul className="namintravel-amazing-offer-list">
                        {list.map(item => <li key={item}>{item}</li>)}
                    </ul>
                </div>
            </div>
        </section>
    </div>
</section>)
}

export default NaminAmazingOffers;