import axios from "axios";
import {showError,editMessage} from "./VisualDataAction";
import {Header,Flight as FlightURL,ServerAddress} from "../Enum/Urls";

//async method
export const setError=(data)=>({type:'FLIGHTERROR',result:data})
export const searchFlightAsync=(data)=>({type:'FLIGHTSEARCH',result:data});
export const ApplyFilterFlight=()=>({type:'ApplyFilterFlight'});
export const filterSelect=(data)=>{
    return {type:'FlightFilterSelect',input:data}
};
export const resetFilters = ()=>{
    return{type:'resetFilters'}
}
export const flightFilterSelectAirlineStop = (data)=>{
    return {type:'flightFilterSelectAirlineStop',input:data}   
}

export const flightFilterSelectNaminTravelAirlineStopEthnic = (data)=>{
    return {type:'FlightFilterSelectNaminTravelAirlineStopEthnic',input:data}   
}

export const sortFlightSearch=(input)=>({type:'sortFlightSearch',input:{...input}});
export const setFlightSelected=(data)=>({
    type:'setFlightData',
    result: data
});
export const setNaminTravelSelectedFlight=(data)=>({
    type:'naminTravelSelectedFlight',
    data: data
});

export const setReserveID=(id)=>({type:'setReserveID',id});
//sync method
export const searchFlight=(params)=>{
  return (dispatch)=>{
      axios({
          method:"post",
          url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetFlights}`,
          data:{...params},
          headers:{...Header}
      }).then((response)=>{

          dispatch(searchFlightAsync(response.data.result));
          dispatch(editMessage(true));


      }).catch((error)=>{
          try{
              dispatch(showError({visibility:true,content:error.response.data.error.message}))
          }catch (e) {
              dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
          }
      })
  }
};
export const validateFlight=(key)=>{
    return(dispatch)=>{
        axios({
            method:"post",
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${key.key}`,
            headers:{...Header}
        }).then((response)=>{
            dispatch(setFlightSelected(response.data.result));
            dispatch(editMessage(true));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
};
export const getPassengerFlight=(params,token)=>{
    return(dispatch)=>{
        let HeaderAthu={...Header};
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }
        axios({
            method:"post",
            data:{...params},
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetPNR}`,
            headers:{...HeaderAthu}
        }).then((response)=>{
            if(response.data.success){
                if(!response.data.result){
                    dispatch(showError({visibility:true,content:"Flight selection failed,please select another one"}))
                }else{
                    dispatch(setReserveID(response.data.result));

                }
            }
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
};
export const getReserveByIdFlight=(params)=>{
    return(dispatch)=>{
        axios({
            method:"get",
            params,
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetReserveByID}`,
            headers:{...Header}
        })
        .then((response)=>{
            dispatch(setFlightSelected(response.data.result))
            dispatch(editMessage(true));
        })
        .catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
};
export const getDirectTicketByID=(params)=>{
    //get directticket for booking flight
    return (dispatch => {
        //handle header
        let token=localStorage.getItem("userToken");
        let HeaderAthu=null;
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }else{
            HeaderAthu={...Header};
        }
        //
        axios({
            method:"get",
            params,
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetDirectTicketByID}`,
            headers:HeaderAthu
        }).then((responsive)=>{
            dispatch(getDirectTicketByIdLoading(false));
            if (responsive.data.result==="PNRIssued" || responsive.data.result===0 ){
                dispatch(setDirectTicketByIdResult("PNRIssued"));
            }else if (responsive.data.result==="PnrNotMade" || responsive.data.result===2){
                dispatch(setDirectTicketByIdResult("PnrNotMade"));
            }else{
                dispatch(setDirectTicketByIdResult("Other"));
            }

            // if(parseInt(responsive.data.result.PNRIssued)===2){
            //     dispatch( push(`/profile/details?userName=${params.userName}&reserveId=${params.reserveId}&hasError=true`))

            // }else{
            //     dispatch( push(`/profile/details?userName=${params.userName}&reserveId=${params.reserveId}`))
            // }

        }).catch((error)=>{
            dispatch(setDirectTicketByIdResult("Error"));
            try{
                if (error.response) {
                    dispatch(showError({visibility:true,content:error.response.data.error.message,closable:true}))
                }
                //dispatch( push(`/profile/details?userName=${params.userName}&reserveId=${params.reserveId}`))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    })
    // return(dispatch)=>{
    //     axios({
    //         method:"get",
    //         params,
    //         url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetDirectTicketByID}`,
    //         headers:{...Header}
    //     })
    //     .then((response)=>{
    //         dispatch(setError(response.data.result))
    //     })
    //     .catch((error)=>{
    //         try{
    //             dispatch(showError({visibility:true,content:error.response.data.error.message}))
    //         }catch (e) {
    //             dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
    //         }
    //     });
    // }

};


//domestic flight
export const searchDomesticFlight=(params)=>{
    return (dispatch)=>{
        axios({
            method:"post",
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetFlights}`,
            data:{...params},
            headers:{...Header}
        }).then((response)=>{
  
            dispatch(searchFlightAsync(response.data.result));
            dispatch(editMessage(true));
  
  
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
  };
export const validateDomesticFlight=(params)=>{
    let url = `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${params.key}`;
    if (params.metaId && params.source){
        url = `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.ValidateFlight}?key=${params.key}&metaId=${params.metaId}&source=${params.source}`;
    }
    return(dispatch)=>{
        axios({
            method:"post",
            url:url,
            headers:{...Header}
        }).then((response)=>{
            dispatch(setFlightSelected(response.data.result));
            dispatch(editMessage(true));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
};
export const getPassengerDomesticFlight=(params,token)=>{
    return(dispatch)=>{
        let HeaderAthu={...Header};
        if(token){
            HeaderAthu={Authorization:`Bearer ${token}`,...Header};
        }
        axios({
            method:"post",
            data:{...params},
            url:`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetPNR}`,
            headers:{...HeaderAthu}
        }).then((response)=>{
            if(response.data.success){
                if(!response.data.result){
                    dispatch(showError({visibility:true,content:"Flight selection failed,please select another one"}))
                }else{
                    dispatch(setReserveID(response.data.result));

                }
            }
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:"An error occurred"}))
                //dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
};


export const getFlightReserveAsync=(data)=>({type:'FLIGHTRESERVE',result:data});

export const bankStatus = async (param) => {
    try {
      let response = await axios.post(`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.BankStatus}`,
       param,
       {headers: {...Header}}
       );
      return response;
    } catch (error) {
      return error;
    }
};
export const getTime = async () => {
    try {
      let response = await axios.get(`${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetTime}`, 
        {headers: {...Header}}
        );
      return response;
    } catch (error) {
      return error;
    }
};


export const getDirectTicketByIdLoading = (data)=>({type:"getDirectTicketByIdLoading",data:data});

export const setSafarmarketPixel = (data)=>({type:"safarmarketPixel",data:data});

export const setDirectTicketByIdResult = data => ({type:"directTicketByIdResult",data:data});