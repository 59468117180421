//import libraries
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setCurrency } from "../../Actions/UserAction";
//import redux actions
import { toggleAccountWindow } from "../../Actions/VisualDataAction";
import { SwitchAccountWindow } from "../../Actions/VisualDataAction";
import { GetReserveById } from "../../Actions/PaymentAction";
//load UI components
import ProfileBallon from "../Admin/ProfileBallon";
import IrLogin from "../Admin/IrLogin/IrLogin";
//load Assets files

import LayoutConfig from "../../Config/layoutConfig";

//declaration Class Component
class SafarmarketHeader extends React.Component {
  state = {
    routerInfo: null,
    disableLink: false,
    MenuClass: "",
  };


  componentDidMount() {

    this.props.setCurrency("IRR");

    this.setState({
      routerInfo: this.props.location.pathname.split("/")[1],
    });
    let path = this.props.history.location.pathname.split("/");
    if (path[1] === "payment") {
      this.setState({
        disableLink: true,
      });
    } else {
      this.setState({
        disableLink: false,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        routerInfo: this.props.location.pathname.split("/")[1],
      });
    }
    if (this.props.currency !== prevProps.currency) {
      window.location.reload();
    }
    if (this.props !== prevProps) {
      let path = this.props.history.location.pathname.split("/");
      if (path[1] === "payment") {
        this.setState({
          disableLink: true,
        });
      } else {
        this.setState({
          disableLink: false,
        });
      }
    }
  }

  changeCurrency = (currency) => {
    this.props.getCurrency(currency);
  };

  setToggleTopMenuClass = () => {
    if (this.state.MenuClass !== "expanded") {
      this.setState({
        MenuClass: "expanded",
      });
    } else {
      this.setState({
        MenuClass: "",
      });
    }
  };
  trackOrderHandler = () => {
    this.setState({
      showTrack: false,
      trackEmail: null,
      trackReserveID: null
     });
     let queryParams = this.props.location.search?.split("#")[0];
     const pathReserveId = queryParams.split("reserveId=")[1]?.split("&")[0];
     const pathUserName = queryParams.split("userName=")[1]?.split("&")[0]; 
     const enterdReserveId = this.state.trackReserveID;
     const enterdUserName = this.state.trackEmail;
     if (pathReserveId && pathUserName && pathReserveId===enterdReserveId && pathUserName===enterdUserName){
      let params = new URLSearchParams();
      params.append("reserveId", enterdReserveId);
      params.append("userName", enterdUserName);
      this.props.GetReserveById(params);
     }else{
       this.props.history.push(
         `/profile/details?userName=${this.state.trackEmail}&reserveId=${this.state.trackReserveID}`
       );
     }
  }

  render() {
    const culture = window.location.origin;
    const { Dictionary, LAN } = this.props;
    const {
      SwitchAccountWindow,
      Verification,
      toggleAccountWindow,
      UserData,
      smPixel,
      smHotelPixel
    } = this.props;


    const logo = ( <Link className="logo" to={"/"}>
      <img src={LayoutConfig.logo} alt={LayoutConfig.companyName} />
    </Link>);

    const account =
      LayoutConfig.themeClassName === 'padide-com-theme' ? null : (
        <div className="account-links inline-block">
          {Verification ? (
            <ProfileBallon model={UserData} />
          ) : process.env.REACT_APP_THEME === 'ITOURS_IR' ? (
            <IrLogin />
          ) : (
            <div className="account">
              <div
                className="link inline-block"
                onClick={() => {
                  SwitchAccountWindow(false);
                  toggleAccountWindow();
                }}>
                {LayoutConfig.themeClassName === 'style-2' ? `${Dictionary.login} / ${Dictionary.register}` : Dictionary.login}
              </div>
            </div>
          )}
        </div>
      );


    const mainMenu =(<div className="main-menu-wrapper">
      <button
        type="button"
        className="menu-toggle-btn"
        onClick={this.setToggleTopMenuClass}
      >
        <span className="menu-btn-bar icon-top-bar"></span>
        <span className="menu-btn-bar icon-middle-bar"></span>
        <span className="menu-btn-bar icon-bottom-bar"></span>
      </button>
      <div>
        {this.state.MenuClass === "expanded" && (
          <span
            className={"menu-overlay"}
            onClick={this.setToggleTopMenuClass}
          />
        )}
        <div className={`main-menu-holder ${this.state.MenuClass}`}>
          <button
            type="button"
            className="opened menu-toggle-btn red-arrow-right itours-icon mirror-ltr"
            onClick={this.setToggleTopMenuClass}
          />
          <ul className="main-menu">
            {LayoutConfig.hasDomesticFlight ?
              <li
                className={
                  this.state.routerInfo === "domesticflight" ||  (this.state.routerInfo === "" && (LayoutConfig.hasDomesticFlight)) 
                    ? "active menu-item"
                    : "menu-item"
                }
              >
                <Link
                  to={"/"}
                  className="menu-link"
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="itours-icon top-plane-gray hidden-medium hidden-large margin-end-light" />
                  {Dictionary.domesticFlight}
                </Link>
              </li>
            :null}                      
            <li
              className={
                (this.state.routerInfo === "" && (!LayoutConfig.hasDomesticFlight)) ||
                this.state.routerInfo === "flight" ||
                this.state.routerInfo === "foreignflight"
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Link
                to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"}
                className="menu-link"
                onClick={this.setToggleTopMenuClass}
              >
                <span className="itours-icon top-plane-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.foreignFlight}
              </Link>
            </li>

            <li
              className={
                this.state.routerInfo === "hotel"
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Link
                to={"/hotel"}
                className="menu-link"
                onClick={this.setToggleTopMenuClass}
              >
                <span className="itours-icon hotel-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.foreignHotel}
              </Link>
            </li>

            <li
              className={
                this.state.routerInfo === "package"
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Link
                to={"/package"}
                className="menu-link"
                onClick={this.setToggleTopMenuClass}
              >
                <span className="itours-icon package-gray hidden-medium hidden-large margin-end-light" />
                {Dictionary.package}
              </Link>
            </li>                      

            {LayoutConfig.themeClassName==="style-2" || <>
              <li
                className={
                  this.state.routerInfo === "terms"
                    ? "active menu-item"
                    : "menu-item"
                }
              >
                <Link
                  to={"/terms"}
                  className="menu-link"
                  disabled={
                    LayoutConfig.themeClassName === "padide-com-theme" ||
                    LayoutConfig.themeClassName === "naminTravel-theme__"
                  }
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="itours-icon terms hidden-medium hidden-large margin-end-light" />
                  {Dictionary.termsAndConditions}
                </Link>
              </li>
              <li
                className={
                  this.state.routerInfo === "about"
                    ? "active menu-item"
                    : "menu-item"
                }
              >
                <Link
                  to={"/about"}
                  className="menu-link"
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="itours-icon about-us hidden-medium hidden-large margin-end-light" />
                  {Dictionary.aboutUs}
                </Link>
              </li>
              <li
                className={
                  this.state.routerInfo === "contact"
                    ? "active menu-item"
                    : "menu-item"
                }
              >
                <Link
                  to={"/contact"}
                  className="menu-link"
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="itours-icon contact-us hidden-medium hidden-large margin-end-light" />
                  {Dictionary.contactUs}
                </Link>
              </li>
            </>
          }
          </ul>
        </div>
      </div>
    </div>);

    return (
        <header className="safarmarket-header bg-white relative">
        {smPixel && <img src={smPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}
        {smHotelPixel && <img src={smHotelPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}        
        <div className="page-container">
          <div className="safarmarket-header-inner relative">
            {mainMenu}
            {logo}
            {account}
          </div>
        </div>
      </header>
    )
  }
}
//map state to Props
const MapStateToProps = (state) => ({
  Verification: state.VisualData.Verification,
  userData: state.UserData,
  currency: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  smPixel:state.FlightSearch.safarmarketPixel,
  smHotelPixel:state.HotelSearch.safarmarketPixel
});
//map action to Props
const MapAction = (dispatch) => ({
  toggleAccountWindow: () => dispatch(toggleAccountWindow()),
  SwitchAccountWindow: (mode) => {
    dispatch(SwitchAccountWindow(mode));
  },
  setCurrency: (value) => {
    dispatch(setCurrency(value));
  },
  GetReserveById: (param) => {
    dispatch(GetReserveById(param));
  },
});
const routerInfo = withRouter(SafarmarketHeader);
export default connect(MapStateToProps, MapAction)(routerInfo);
