import React from 'react';
import Form from '../Common/Form';
import PropTypes from 'prop-types';
import Validation from '../Common/Validation';
import {connect} from 'react-redux';
import axios from "axios";
import { User, Header, ServerAddress } from "../../Enum/Urls";
import { CountryCode, CountryPostalCode } from "../../Enum/Models";
import {
    toggleAccountWindow,
    toggleErrorWindow,
    showError,
    editMessage,
    SwitchAccountWindow
} from '../../Actions/VisualDataAction';
import {loginUser,registerUser,registerNaminUser} from '../../Actions/UserAction';
import {toggleVarification} from '../../Actions/VisualDataAction';
import close from '../../image/close.svg';

class Account extends Form{
    state={
        ...this.state,
        forgetPassword:false,
        loading:false,
        showMessage:false
    };
    static propTypes={
        mode:PropTypes.bool.isRequired,
    };
    FormDOM=null;
    closeError=()=>{
        this.props.hideError(false,null);
        this.state.Loading=false;
        this.setState({...this.state,loading:false});
    };
    forgetPassword=()=>{
        this.setState({
            forgetPassword:true
        })
    };
    componentDidMount(){
        this.setState({loading:false});
        // this.props.editMessage(false);
        super.componentDidMount(this.FormDOM);
        //set message false
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.error!==prevProps.error){
            this.setState({
                loading:false
            })
        }
    }
    forgerPassword = (params) => {
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.Forgot}`,
            data:{...params},
            headers:{...Header}
        }).then((response)=>{
            this.setState({loading:false,showMessage:true});
        }).catch((error)=>{
            this.setState({loading:false});
            try{
                this.props.showError({visibility:true,content:error.response.data.error.message})
            }catch (e) {
                this.props.showError({visibility:true,content:"Internal Error,Please refresh the page"})
            }
        });
    };

    handleSubmit(event){
        if(this.props.mode){
            super.validateForm(event,()=>{
                this.setState({loading:true});
                if(process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'){
                    let parameters = {...this.state.DataInput};
                    let code = this.state.DataInput.countryCode;
                    let gender;
                    if (this.state.DataInput.gender === "true"){
                        gender = true;
                    }else{
                        gender = false;
                    }
                    if (code.substring(0, 2) === "00"){
                        code = "+" + this.state.DataInput.countryCode.substring(2)
                    }
                    parameters.phoneNumber = code+this.state.DataInput.phoneNumber
                    parameters.gender = gender;
                    delete parameters.countryCode;
                    this.props.registerNaminUser(parameters);
                }else{
                    this.props.registerUser(this.state.DataInput);
                }
            })
        }
        else{//login
            if(this.state.forgetPassword){
                super.validateForm(event,()=>{
                    this.setState({loading:true});
                    this.forgerPassword(this.state.DataInput);
                })
            }else{
                super.validateForm(event,()=>{
                    this.setState({loading:true});
                    this.props.loginUser(this.state.DataInput);
                })
            }

        }
    }
    // countryCodeSelect = this.getFieldDecorator("reserver.postalcode", {
    //     initialValue: LAN === "IR" ? "0098" : LAN === "AR"? "00971" : "0047",
    //   })(
    //     <select className="form-select" dir="ltr" >
    //       {CountryPostalCode.map((item) => (
    //         <option
    //           key={item.code}
    //           value={item.value}
    //         >
    //           {item.code}
    //         </option>
    //       ))}
    //     </select>,
    //   );
    render(){
        const {Dictionary} = this.props;
        const {check,SwitchAccountWindow} = this.props;
        return(
            <div className={"accountWindow"}>
                <div className="page-container">
                    <div className={`Form ${process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' && this.props.mode ?"naminTravel-register-form":""}`} >
                        <div className="login-tabs-holder">
                            <button type="button" className={!this.props.mode?"tab-btn active":"tab-btn"} onClick={() => { SwitchAccountWindow(false) }}>{Dictionary.login}</button>
                            <button type="button" className={this.props.mode?"tab-btn active":"tab-btn"}  onClick={() => { SwitchAccountWindow(true) }}>{Dictionary.registration}</button>
                        </div>
    {/*                     {(error.visibility)?
                            <div className="showMessage">
                                <div  className="content">
                                    {error.content}
                                </div>
                                <div className="buttonContainer">
                                    <button type="button" className="button blue-btn" onClick={()=>{this.props.hideError({visibility:false,content:null});this.setState({Loading:false})}}>بازگشت</button>
                                </div>
                            </div>
                            :
                            null
                        } */}
                        {
                            (this.state.showMessage)?
                                <div className="section-vertical-padding text-center bg-white">
                                    <div className="margin-bottom">
                                        <div>
                                            <span className="check-green itours-icon icon-large" />
                                        </div>
                                        <strong> {Dictionary.operationDoneSuccessfully}</strong>
                                    </div>
                                    <div className="buttonContainer">
                                        <button type="button" className="button blue-btn" onClick={()=>{this.props.check()}}>{Dictionary.ok}</button>
                                    </div>
                                </div>
                                :
                                <div className="register">
                                    <div className="validatorSummary">
                                        {this.state.ValidatorSummary.map((item,key)=><div key={key} className="item">{item}</div>)}
                                    </div>
                                    <div className="close-btn">
                                        <img src={close} alt="close" className="closeBtn"  onClick={()=>check()}/>
                                    </div>

                                    <form onSubmit={this.handleSubmit.bind(this)} ref={(node) => this.FormDOM=node} noValidate>

                                        {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' && this.props.mode && <>
                                            <b>Already signed up? </b>
                                            <b className="link-style-btn" onClick={()=>{
                                                this.setState({forgetPassword:false});
                                                SwitchAccountWindow(false)
                                                }
                                            }> {Dictionary.login} </b>
                                            <p className="margin-top-light">Your personal details</p>
                                            
                                            <div>
                                                <div className="form-group">
                                                    <label className="form-label">{Dictionary.gender}*</label>
                                                    <select className="form-select" name="gender" defaultValue={true} >
                                                        <option value={true}>{Dictionary.male}</option>
                                                        <option value={false}>{Dictionary.female}</option>
                                                    </select>
                                                </div>
                                            </div> 
                                            <div>
                                                <div className="form-group">
                                                    <label className="form-label">{Dictionary.firstName}*</label>
                                                    <input className="placeholder-right form-input en-input-text" data-validator={true} placeholder={Dictionary.firstName}  name="firstname" type="text" />
                                                    <Validation model={this.state} feild="ValidatorList" name='firstname' />
                                                </div>
                                            </div>  
                                            <div>
                                                <div className="form-group">
                                                    <label className="form-label">{Dictionary.lastName}*</label>
                                                    <input className="placeholder-right form-input en-input-text" data-validator={true} placeholder={Dictionary.lastName}  name="lastname" type="text" />
                                                    <Validation model={this.state} feild="ValidatorList" name='lastname' />
                                                </div>
                                            </div>   
                                            <div>
                                                <div className="form-group">
                                                    <label className="form-label">{Dictionary.Country}*</label>
                                                    <select
                                                      className="form-select"
                                                      name="country"
                                                      data-validator={false}
                                                      defaultValue={
                                                        this.props.LAN === "IR"
                                                          ? "IR"
                                                          : "NO"
                                                      }
                                                    >
                                                      {CountryCode.map(
                                                        (item) => (
                                                          <option
                                                            value={item.value}
                                                            key={item.value}
                                                          >
                                                            {item.code}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                    <Validation model={this.state} feild="ValidatorList" name='country' />
                                                </div>
                                            </div>                                          
                                            <div>
                                                <div className="form-group">
                                                    <label htmlFor="" className="form-label">{Dictionary.phoneNumber}*</label>
                                                    <div className="select-input-pair-holder-outer">
                                                        <div className="select-input-pair-holder" dir="ltr">
                                                            <select
                                                                className="form-select"
                                                                name={"countryCode"}
                                                                defaultValue={
                                                                    this.props.LAN === "IR" ? "0098" : "0047"
                                                                }
                                                                dir="ltr"
                                                            >
                                                                {CountryPostalCode.sort((a,b)=>{
                                                                let x = a.code.toLowerCase();
                                                                let y = b.code.toLowerCase();
                                                                if (x < y) {return -1;}
                                                                if (x > y) {return 1;}
                                                                return 0;
                                                                }).map((item) => (
                                                                <option
                                                                    key={item.code}
                                                                    value={item.value}
                                                                >
                                                                    {item.code}
                                                                </option>
                                                                ))}
                                                            </select>

                                                            <input
                                                                className="form-input placeholder-right"
                                                                data-validator={true}
                                                                placeholder="9XXXXXXXXX"
                                                                name="phoneNumber"
                                                                type="tel"
                                                            />
                                                        </div>
                                                    </div>
                                                    <Validation
                                                    model={this.state}
                                                    feild="ValidatorList"
                                                    name="phoneNumber"
                                                    />
                                                </div>
                                            </div>                                        
                                        </>
                                        }

                                        <div>
                                            <div className="form-group">
                                                <label className="form-label">{Dictionary.email}{this.props.mode && "*"}</label>
                                                <input className="placeholder-right form-input en-input-text" data-validator={true} placeholder={Dictionary.enterYourEmail}  
                                                name={this.state.forgetPassword? "emailAddress":process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' && this.props.mode?"emailAddress":"emailOrPhoneNumber"}
                                                type="email" />
                                                <Validation model={this.state} feild="ValidatorList" name={process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' && this.props.mode?"emailAddress":"emailOrPhoneNumber"} />
                                            </div>
                                        </div>

                                        {(!this.state.forgetPassword || this.props.mode)?
                                            <div className="form-group">
                                                <label className="form-label">{Dictionary.password} {this.props.mode && "*"} </label>
                                                <input  className="form-input placeholder-right en-input-text" data-validator={true} placeholder={Dictionary.enterYourPassword} name="password" type="Password" />
                                                <Validation model={this.state} feild="ValidatorList" name='password' />
                                            </div>
                                            :
                                            null
                                        }
                                        {(!this.state.loading)?
                                            <div className={`margin-bottom ${process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' && this.props.mode?"text-end":""}`}>
                                                {(this.props.mode)?
                                                    <div>
                                                        <button type="submit" className="button blue-btn full-width"> {Dictionary.register} </button>
                                                    </div>
                                                    :
                                                    <div>

                                                        {
                                                            (this.state.forgetPassword)?
                                                            <>
                                                            <button type="submit" className="button blue-btn margin-bottom margin-end" >{Dictionary.sendResetLink}</button>
                                                            <button type="button" className="button grey-btn" onClick={()=>{this.setState({forgetPassword:false})}} >{Dictionary.returnToLogin}</button>
                                                            </>
                                                            :
                                                            <div>
                                                                <button type="submit" className="button blue-btn full-width">{Dictionary.login}</button>
                                                                <div className="text-end margin-top">
                                                                    <b className="link-style-btn" onClick={this.forgetPassword}> {Dictionary.ForgetYourPassword} </b>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div>
                                                <p>
                                                {Dictionary.signingIn}
                                                <span className="dots-loading">
                                                    <span className="dot">.</span>
                                                    <span className="dot">.</span>
                                                    <span className="dot">.</span>
                                                </span>
                                                {/* Please wait ... */}
                                                </p>
                                            </div>
                                        }

                                    </form>
                                   
{/*                                     {(this.props.mode)?

                                        <div className="command">
                                            Allerede registrert?                                            
                                            Klikk <b className="link-style-btn" onClick={() => { SwitchAccountWindow(false) }}> her </b> for Logg inn
                                        </div>
                                        :
                                        <div className="command">
                                            Har du ikke en konto? 
                                            Klikk <b  className="link-style-btn" onClick={() => { SwitchAccountWindow(true) }}> her </b> for registrere
                                        </div>
                                    } */}
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    error:state.VisualData.error,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
const MapDispatch=(dispatch)=>({
    check:()=>dispatch(toggleAccountWindow()),
    showError:(text)=>dispatch(toggleErrorWindow(text)),
    toggleVarification:(data)=>dispatch(toggleVarification(data)),
    loginUser:(params)=>dispatch(loginUser(params)),
    registerUser:(params)=>dispatch(registerUser(params)),
    registerNaminUser:(params)=>dispatch(registerNaminUser(params)),
    hideError:(params)=>dispatch(showError(params)),
    editMessage:(param)=>dispatch(editMessage(param)),
    SwitchAccountWindow:(mode)=>{dispatch(SwitchAccountWindow(mode))}
});
export default connect(mapStateToProps,MapDispatch)(Account)