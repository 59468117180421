import { Tooltip } from "antd";
import { useState } from "react";
import { connect } from "react-redux";

import Price from "../Common/Price";
import QuantityInput from "../Common/QuantityInput";

const DomesticHotelSmRoom = props => {

  const { room, rate, duration, Dictionary } = props;

  const isSelectedRoom = props.selectedRoomToken && props.selectedRoomToken === rate.bookingToken ;
  const prices = {
    roomPrice: rate.pricing.find((item) => item.type === 'Room' && item.ageCategoryType === 'ADL')?.amount,
    boardPrice: rate.pricing.find((item) => item.type === 'RoomBoard' && item.ageCategoryType === 'ADL')?.amount,
    extraBedPrice: rate.pricing.find((item) => item.type === 'ExtraBed' && item.ageCategoryType === 'ADL')?.amount,
    childPrice: rate.pricing.find((item) => item.type === 'Room' && item.ageCategoryType === 'CHD')?.amount,
  };

  const nightly = [];
  for (const [key, value] of Object.entries(rate.nightly)) {
    nightly.push({
      date: key,
      amount: (isSelectedRoom ? props.count : 1) * value.amount,
      board: value.board ? (isSelectedRoom ? props.count : 1) * value.board : 0,
    });
  }

  let capacity = null;
  if (room.capacity?.count) {
    capacity = <div className="font-12 sm-font-14 room-capacity">
      <div className="block medium-inline-block margin-bottom-light">
        <span className="itours-icon guests-dark margin-end-light icon-small" />
        <span className="margin-end-light">ظرفیت: </span>
        <span className="inline-block margin-end">
          {room.capacity.count} نفر
        </span>
      </div>
      {!!room.capacity.extraBed && room.capacity.extraBed > 0 && <div className="block medium-inline-block margin-bottom-light">
        <Tooltip title={!!prices.extraBedPrice && (
          <div className="margin-top-light">
            <span className="margin-end-light">{Dictionary.ExtraBedPrice}:</span>
            <Price currency={props.currency}>
              {prices.extraBedPrice}
            </Price>
          </div>
        )}>
          <span className="itours-icon hotel-dark margin-end-light icon-small" />
          <span className="margin-end-light">تخت اضافه: </span>
          <span className="inline-block margin-end-light">
            {room.capacity.extraBed}  عدد
          </span>
        </Tooltip>
      </div>}
    </div>
  }

  return (
    <div className="margin-bottom bg-white rounded-medium">
      <div className="border-bottom">
        <div className="card-padding">
          <div className='sm-room-item-content'>
            <div className='sm-room-item-rooms'>
              <div className="sm-room-item" >
                <strong className="font-14 sm-font-22 room-name margin-end-half no-select-highlight ">{room.name}</strong>
                {capacity}
                {!!rate.description && <div className="margin-top-light">{rate.description}</div>}
              </div>
            </div>

            <div className='sm-board-cancellation'>
              <span className="gray-tag font-12 no-wrap margin-bottom-half">{rate.board.name}<span className="pipe margin-start-light margin-end-light" />{rate.board.code}</span>
              {rate.cancellationPolicy?.status === "Refundable" ?
                <div className="green green%%%"> قابل استرداد</div>
                :
                <div className="gray-tag font-12">غیر قابل استرداد</div>
              }
            </div>
          </div>

        </div>
        <div className="room-item-footer card-padding">
          <div>
            <div className="inline-medium margin-end-half font-12 sm-font-14 xs-margin-bottom-half">
              {Dictionary.totalFor} {duration} {Dictionary.nights}:
            </div>
            {prices.roomPrice && prices.roomPrice > 1000 ? (
              <>
                {prices.boardPrice && prices.roomPrice < prices.boardPrice && (
                  <div className="old-price inline-medium font-16 sm-font-20">
                    <Price currency={props.currency} >
                      {rate.boardPrice}
                    </Price>
                  </div>
                )}
                <b className="new-price inline-medium font-16 sm-font-20">
                  <Price currency={props.currency}>
                    {(isSelectedRoom ? props.count : 1) * prices.roomPrice}
                  </Price>
                </b>
              </>
            ) : (
              <span className="margin-bottom-light purple">قیمت نیازمند استعلام است</span>
            )}

          </div>
          {isSelectedRoom ?
            // <span className='green selected-room-message'>
            //   <span className='itours-icon icon-small check-green margin-end-light' />
            //   این اتاق انتخاب شد
            //   </span>
            <div>
              <div className="block medium-inline-block middle text-center margin-bottom-light margin-end-light">تعداد اتاق:</div>
              <div className="block medium-inline-block middle">
                <QuantityInput min={1} max={rate.available} onChange={props.setCount} value={props.count} className="style-b" />
              </div>
            </div>
            :
            <button className="button blue-btn" type='buttom' onClick={() => { props.selectRoomHandle(room, rate) }} disabled={props.reserveLoading}>
              انتخاب اتاق
            </button>
          }
        </div>
      </div>
    </div>
  )
}

const stateMapProp = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  currency: state.UserData.Currency
});

export default connect(stateMapProp)(DomesticHotelSmRoom);
