import React from "react";
import { connect } from "react-redux";
import { filterSelect, ApplyFilterFlight } from "../../Actions/FlightAction";
import { FilterType, FilterName } from "../../Enum/Models";
import { Collapse } from "antd";
import LayoutConfig from "../../Config/layoutConfig";
const { Panel } = Collapse;

class FilterForm extends React.Component {
  handleSelect = (evt, type, filterType) => {
    if (evt.target.type === "checkbox") {
      let value = isNaN(evt.target.value)
        ? evt.target.value
        : parseFloat(evt.target.value);
      if (evt.target.checked) {
        this.props.filterSelect({ isAdd: true, type, value, filterType });
      } else {
        this.props.filterSelect({ isAdd: false, type, value, filterType });
      }
    } else {
      let textInput = evt.target.value;
      this.props.filterSelect({ isAdd: true, type, textInput, filterType });
    }
    this.props.ApplyFilterFlight();
  };
  render() {
    const { Dictionary } = this.props;
    return (
      <>
        <strong className="filter-header">{Dictionary.filters}</strong>
        <div className="padding-h-10">
          <Collapse
            expandIconPosition={"right"}
            bordered={false}
            defaultActiveKey={["1", "2", "3", "4", "5","6"]}
          >
            <Panel header={<span className="okr-bg">{Dictionary.stops}</span>} key={"1"}>
              <div className="checkbox-holder">
                <input
                  className="custom-checkbox"
                  id="nonstop"
                  type="checkbox"
                  value={0}
                  onChange={(e) => {
                    this.handleSelect(e, FilterName.stop, FilterType.Union);
                  }}
                />
                <label className="checkbox-label" htmlFor="nonstop">
                  {Dictionary.direct}
                </label>
              </div>
              <div className="checkbox-holder">
                <input
                  className="custom-checkbox"
                  id="onestop"
                  type="checkbox"
                  value={1}
                  onChange={(e) => {
                    this.handleSelect(e, FilterName.stop, FilterType.Union);
                  }}
                />
                <label className="checkbox-label" htmlFor="onestop">
                  {" "}
                  1 {Dictionary.stop}{" "}
                </label>
              </div>
              <div className="checkbox-holder">
                <input
                  className="custom-checkbox"
                  id="multistop"
                  type="checkbox"
                  value={2}
                  onChange={(e) => {
                    this.handleSelect(e, FilterName.stop, FilterType.Union);
                  }}
                />
                <label className="checkbox-label" htmlFor="multistop">
                  {" "}
                  1+ {Dictionary.stop}{" "}
                </label>
              </div>
            </Panel>
            <Panel header={<span className="okr-bg">{Dictionary.classCabin}</span>} key={"2"}>
              {this.props.filtershow.class.value.map((item, index) => {
                return (
                  <div className="checkbox-holder" key={index}>
                    <input
                      className="custom-checkbox"
                      type="checkbox"
                      value={item}
                      onChange={(e) => {
                        this.handleSelect(e, FilterName.class, FilterType.Union);
                      }}
                    />
                    <label className="checkbox-label">{item}</label>
                  </div>
                );
              })}
            </Panel>
            <Panel header={<span className="okr-bg">{Dictionary.airline}</span>} key={"3"}>
              {this.props.filtershow.airline.value.map((item, index) => {
                return (
                  <div className="checkbox-holder" key={index}>
                    <input
                      className="custom-checkbox"
                      type="checkbox"
                      value={item}
                      onChange={(e) => {
                        this.handleSelect(
                          e,
                          FilterName.airline,
                          FilterType.Union
                        );
                      }}
                    />
                    <label className="checkbox-label">{item}</label>
                  </div>
                );
              })}
            </Panel>
            {this.props.domesticFlight && this.props.filtershow.flightType.value.length > 1 && <Panel header={<span className="okr-bg">{Dictionary.flightType}</span>} key={"6"}>
              {this.props.filtershow.flightType.value.map((item, index) => {
                return (
                  <div className="checkbox-holder" key={index}>
                    <input
                      className="custom-checkbox"
                      type="checkbox"
                      value={item}
                      onChange={(e) => {
                        this.handleSelect(
                          e,
                          FilterName.flightType,
                          FilterType.Union
                        );
                      }}
                    />
                    <label className="checkbox-label">{item==="Systemic"?Dictionary.Systemic:item==="Charter"?Dictionary.Charter:item}</label>
                  </div>
                );
              })}
            </Panel>}
            {/* {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' && <Panel header={Dictionary.Baggage} key={"4"}>
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value="baggage"
                    onChange={(e) => {
                      this.handleSelect(e, FilterName.baggage, FilterType.Union);
                    }}
                  />
                  <label className="checkbox-label">{Dictionary.baggage}</label>
                </div>
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value="extraBaggage"
                    onChange={(e) => {
                      this.handleSelect(e, FilterName.baggage, FilterType.Union);
                    }}
                  />
                  <label className="checkbox-label">{"Extra Baggage"}</label>
                </div>
              </Panel>
            } */}
            <Panel header={<span className="okr-bg">{Dictionary.departureTime}</span>} key={"5"}>
              <div
                className={
                  LayoutConfig.themeClassName === "padide-com-theme" ||
                  "containerFlex time-filters"
                }
              >
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value={"00:00-05:59"}
                    onChange={(e) => {
                      this.handleSelect(
                        e,
                        FilterName.departureTime,
                        FilterType.Union
                      );
                    }}
                  />
                  <label className="checkbox-label">
                    <div>
                      <span className="itours-icon icon-small fill-sun" />
                    </div>
                    <div>00:00</div>
                    <div>-</div>
                    <div>06:00</div>
                  </label>
                </div>
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value={"06:00-11:59"}
                    onChange={(e) => {
                      this.handleSelect(
                        e,
                        FilterName.departureTime,
                        FilterType.Union
                      );
                    }}
                  />
                  <label className="checkbox-label">
                    <div>
                      {" "}
                      <span className="itours-icon icon-small empty-sun" />
                    </div>
                    <div>06:00</div>
                    <div>-</div>
                    <div>12:00</div>
                  </label>
                </div>
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value={"12:00-17:59"}
                    onChange={(e) => {
                      this.handleSelect(
                        e,
                        FilterName.departureTime,
                        FilterType.Union
                      );
                    }}
                  />
                  <label className="checkbox-label">
                    <div>
                      <span className="itours-icon icon-small fill-moon" />
                    </div>
                    <div>12:00</div>
                    <div>-</div>
                    <div>18:00</div>
                  </label>
                </div>
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value={"18:00-23:59"}
                    onChange={(e) => {
                      this.handleSelect(
                        e,
                        FilterName.departureTime,
                        FilterType.Union
                      );
                    }}
                  />
                  <label className="checkbox-label">
                    <div>
                      <span className="itours-icon icon-small empty-moon" />
                    </div>
                    <div>18:00</div>
                    <div>-</div>
                    <div>00:00</div>
                  </label>
                </div>
              </div>
            </Panel>
            <Panel header={<span className="okr-bg">{Dictionary.arrivalTime}</span>} key={"6"}>
              <div
                className={
                  LayoutConfig.themeClassName === "padide-com-theme" ||
                  "containerFlex time-filters"
                }
              >
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value={"00:00-05:59"}
                    onChange={(e) => {
                      this.handleSelect(
                        e,
                        FilterName.returnTime,
                        FilterType.Union
                      );
                    }}
                  />
                  <label className="checkbox-label">
                    <div>
                      {" "}
                      <span className="itours-icon icon-small fill-sun" />
                    </div>
                    <div>00:00</div>
                    <div>-</div>
                    <div>06:00</div>
                  </label>
                </div>
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value={"06:00-11:59"}
                    onChange={(e) => {
                      this.handleSelect(
                        e,
                        FilterName.returnTime,
                        FilterType.Union
                      );
                    }}
                  />
                  <label className="checkbox-label">
                    <div>
                      {" "}
                      <span className="itours-icon icon-small empty-sun" />
                    </div>
                    <div>06:00</div>
                    <div>-</div>
                    <div>12:00</div>
                  </label>
                </div>
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value={"12:00-17:59"}
                    onChange={(e) => {
                      this.handleSelect(
                        e,
                        FilterName.returnTime,
                        FilterType.Union
                      );
                    }}
                  />
                  <label className="checkbox-label">
                    <div>
                      <span className="itours-icon icon-small fill-moon" />
                    </div>
                    <div>12:00</div>
                    <div>-</div>
                    <div>18:00</div>
                  </label>
                </div>
                <div className="checkbox-holder">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    value={"18:00-23:59"}
                    onChange={(e) => {
                      this.handleSelect(
                        e,
                        FilterName.returnTime,
                        FilterType.Union
                      );
                    }}
                  />
                  <label className="checkbox-label">
                    <div>
                      {" "}
                      <span className="itours-icon icon-small empty-moon" />
                    </div>
                    <div>18:00</div>
                    <div>-</div>
                    <div>00:00</div>
                  </label>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </>
    );
  }
}
const dispatchToProps = (dispatch) => ({
  filterSelect: (data) => dispatch(filterSelect(data)),
  ApplyFilterFlight: () => dispatch(ApplyFilterFlight()),
});
const stateToProps = (state) => ({
  filtershow: state.FlightSearch.filterShow,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});

export default connect(stateToProps, dispatchToProps)(FilterForm);
