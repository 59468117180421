import React from "react";
import { withRouter } from 'react-router-dom';
import axios from "axios";
import {
  Header,
  Hotel as HotelURL,
  ServerAddress
} from "../../Enum/Urls";
import queryString from "query-string";
import HotelItemDetails from "./HotelItemDetails";
import Loading from "../Common/Loading";
import Rate from "../Common/Rate";
import HotelNoImage from "../../image/default-hotel-image.svg";
import HotelNoImageStyle2 from "../../image/hotel-vector.svg";
import { Currency } from "../../Enum/Models";
import { connect } from "react-redux";
// import {toggleAccountWindow } from "../../Actions/VisualDataAction";
import LayoutConfig from '../../Config/layoutConfig';
import { Tooltip, Tag } from "antd";
import { Link } from "react-router-dom";
import Price from "../Common/Price";
class ShowAccomodation extends React.Component {
  static defaultProps = {
    model: {
      phone: [],
      emails: [],
      geolocation: { latitude: 0, longitude: 0 },
      features: [],
      address: "",
      id: 0,
      rating: 0,
      image: null,
      regularPrice: 0,
      salePrice: 0,
      discountPercent: 0,
      name: "",
    },
    forBook: true,
  };
  state={
    //requiredLoginModalVisibility:false,
    naminTravelSelectedHotel:undefined,
    detailsLoading:false,
    hotelDetails:undefined,
    detailsModalVisiblility:false,
    errorMessage:""
  }
  currecySign = null;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.userData && (this.props.userData !== prevProps.userData) && this.props.userData.isEmailConfirmed ){
      if (this.state.naminTravelSelectedHotel){
          this.props.history.push(this.state.naminTravelSelectedHotel);
      }
    }
  }
  // bookHotel = (url)=>{
  //   this.setState({requiredLoginModalVisibility:true,naminTravelSelectedHotel:url});
  //   //this.props.setNaminTravelSelectedFlight({key:url,extraBaggage:false});
  // }

  showHotelDetail =(id)=>{
    this.setState({detailsModalVisiblility:true});
    if (!this.state.hotelDetails){
      this.setState({detailsLoading:true});
      let parameters = queryString.parse(this.props.location.search);
      let params = new URLSearchParams();
      params.append("Id", id);
      parameters.adults.split(",").forEach((item) =>
        params.append("Adults", item)
      );
      parameters.children.split(",").forEach((item) =>
        params.append("Children", item)
      );
      if (parameters.ages.length !== 0) {
        parameters.ages.split(",").forEach((item) => params.append("Ages", item));
      }
      params.append("Checkin", parameters.checkin);
      params.append("Checkout", parameters.checkout);
  
      axios({
        method: "get",
        url: `${ServerAddress.Type}${ServerAddress.Hotel}${HotelURL.GetAccomadtionDetails}`,
        params: params,
        headers: { ...Header },
      })
      .then((response) => {
        this.setState({detailsLoading:false,hotelDetails:response.data.result});
      })
      .catch((error) => {
        this.setState({errorMessage:"Internal Error,Please refresh the page",detailsLoading:false})
        try {
          debugger;
        } catch (e) {
          debugger;
        }
      });
    }
  }

  render() {
    const {
      features,
      address,
      id,
      rating,
      mainPhoto,
      regularPrice,
      salePrice,
      discountPercent,
      name,
    } = this.props.model;
    const { params } = this.props;
    const { Dictionary } = this.props;
    if (this.props.currencyCode !== undefined) {
      let currency = this.props.currencyCode.toString();
      this.currecySign = Currency[currency];
    } else {
      this.currecySign = "???";
    }

    const detailBtn = <div className="text-end hotel-detail-btn-holder">
      <button type="button" disabled={this.state.detailsLoading} className="button link-style-btn" onClick={()=>this.showHotelDetail(id)}>{Dictionary.hotelDetails}</button>
    </div>;

    const selectHotelBtn = <>
      {/* {process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%' || (this.props.userData && this.props.userData.isEmailConfirmed) ? */}
      <Link
        className="button full-width red-btn has-arrow nowrap"
        target="_blank"
        to={`/hotel/details?Id=${id}&Adults=${params.adults}&Children=${params.children}&Ages=${params.ages}&Checkin=${params.checkin}&Checkout=${params.checkout}`}
      >
        {Dictionary.seeRooms}
      </Link>                
      {/* :<button
          type="button"
          className="button full-width red-btn has-arrow"
          onClick={()=>{this.bookHotel(`/hotel/details?Id=${id}&Adults=${params.adults}&Children=${params.children}&Ages=${params.ages}&Checkin=${params.checkin}&Checkout=${params.checkout}`)}}
        >
          {Dictionary.seeRooms}
        </button>
      } */}
    </>;

  const priceLabel = <div className={"text-end font-12 gray price-for-night"}>
    <span>{Dictionary.startingPrice}</span> <span className="nowrap"> ({this.props.night} {Dictionary.nights}) </span>
  </div>;

  const price = <>
    {discountPercent === 0 ? (
      <div className={"text-end prices-holder"}>
        <b
          className="purple small-margin-bottom text-center"
          dir="ltr"
        >
          <Price currency={this.props.currencyCode}>
            {salePrice}
          </Price>
        </b>
      </div>
    ) : (
      <div dir="ltr" className={"text-end prices-holder"}>
        <span className="margin-end-light old-price no-wrap inline-block">
          <Price currency={this.props.currencyCode}>
            {regularPrice}
          </Price>
        </span>
        <b className="purple small-margin-bottom text-center no-wrap inline-block">
          <Price currency={this.props.currencyCode}>
            {salePrice}
          </Price>
        </b>
      </div>
    )}  
  </>;

    return (
      <div className="hotel-result-item">
        {/* {this.state.requiredLoginModalVisibility && <div className="itours-modal login-to-book-modal visible">
            <div className="modal-overlay" onClick={()=>{this.setState({requiredLoginModalVisibility:false})}} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <span className="itours-icon user-login icon-xxlarge" />
                <h3 className="normal-title">{this.props.userData && this.props.userData.userName ? "please Confirm Your Email To Continue" :Dictionary.pleaseLoginToContinue} ...</h3>
                <p className="text-center gray semi-bold margin-bottom-large">
                  <span className="itours-icon info-icon icon-small margin-end-light" />
                  {this.props.userData && this.props.userData.userName ? Dictionary.pleaseConfirmYourEmailThenReloadThisPageToContinueTheBooking :Dictionary.toProceedWithBookingAtSpecialPriceYouMustLogIn}
                </p>
                <div className="text-center margin-top-large margin-bottom-large">
                  {this.props.userData && this.props.userData.userName ?
                  <button type="button" className="button blue-btn large-btn" onClick={()=>{window.location.reload();}} >
                    {Dictionary.Reload}
                  </button>
                  :<button
                    type="button"
                    className="button red-btn large-btn"
                    onClick={()=>{
                      this.setState({requiredLoginModalVisibility:false});
                      this.props.toggleAccountWindow();
                    }}
                  >
                    {Dictionary.login}
                  </button>}               
                </div>
              </div>
            </div>
          </div>
        } */}
        {this.state.detailsModalVisiblility && <div className="itours-modal hotel-details-modal visible">
          <div className="modal-overlay" onClick={()=>{this.setState({detailsModalVisiblility:false})}} />
          <div className="modal-inner">
            <div className="modal-main">
              {this.state.detailsLoading ? 
                <div className="card-padding"><Loading style2={LayoutConfig.themeClassName === "style-2"} /></div>
              :this.state.errorMessage ?
                <div className="card-padding text-center">
                  <br/>
                  <span className="itours-icon error2-icon icon-xlarge" />
                  <br/><br/>
                  <h3 className="normal-title">{this.state.errorMessage}</h3>
                  <br/><br/>
                </div>
              :
                <HotelItemDetails hotelDetails={this.state.hotelDetails} />
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button grey-btn margin-end"
                onClick={()=>{this.setState({detailsModalVisiblility:false})}}
                disabled={this.state.detailsLoading}
              >
                {Dictionary.close}
              </button>
              {/* {process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%' || (this.props.userData && this.props.userData.isEmailConfirmed) ? */}
                <Link
                  className="button red-btn has-arrow nowrap"
                  to={`/hotel/details?Id=${id}&Adults=${params.adults}&Children=${params.children}&Ages=${params.ages}&Checkin=${params.checkin}&Checkout=${params.checkout}`}
                >
                  {Dictionary.seeRooms}
                </Link>                
                {/* :<button
                  type="button"
                  className="button red-btn has-arrow"
                  onClick={()=>{this.bookHotel(`/hotel/details?Id=${id}&Adults=${params.adults}&Children=${params.children}&Ages=${params.ages}&Checkin=${params.checkin}&Checkout=${params.checkout}`)}}
                >
                  {Dictionary.seeRooms}
                </button>
              } */}
            </div>
          </div>
        </div>}
        {mainPhoto == null ? (
          <div
            className="hotel-image default-image"
            style={{ backgroundImage: `url("${LayoutConfig.themeClassName === 'style-2'?HotelNoImageStyle2:HotelNoImage}")`}}
          ></div>
        ) : (
          <div
            className="hotel-image"
            style={{ backgroundImage: `url(${mainPhoto})` }}
          ></div>
        )}
        <div className="hotel-content">
          <div className="hotel-details" dir="ltr">
            <div className="clearfix">
              {process.env.REACT_APP_THEME !=='NAMINTRAVELCOM%%%%%' || (this.props.userData && this.props.userData.isEmailConfirmed)  ? <Link
                className="inline-block hotel-name margin-right-light"
                target="_blank"
                to={`/hotel/details?Id=${id}&Adults=${params.adults}&Children=${params.children}&Ages=${params.ages}&Checkin=${params.checkin}&Checkout=${params.checkout}`}
              >
                {name}
              </Link>
              :
              <span className="inline-block hotel-name margin-right-light">{name}</span>
              }
              <div className="rating inline-block">
                <Rate number={rating} />
              </div>
            </div>
            <div className="hotel-address">
              <span className="en-font gray font-12">{address}</span>
            </div>
            {this.props.model && this.props.model.boards && (this.props.model.boards.length > 0 ) ?              
              <div className="margin-top"> {Dictionary.board}: {this.props.model && this.props.model.boards && this.props.model.boards.map(boardItem=><Tooltip key={boardItem.name} placement="top" title= {boardItem.name}>
                    <Tag>{boardItem.code}</Tag>
                </Tooltip>)}
              </div>
              :null
            }

            <div className="feature-list">
              <div className="feature-items" dir="ltr">
                {features &&
                  features.map((item, index) => (
                    <div key={index} className="en-font">
                      {item.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="hotel-footer">
            <div className="result-footer-title full-width">
              {LayoutConfig.themeClassName === 'naminTravel-theme'?<div className="namin-travel-hotel-item-footer">
              {detailBtn}
              {priceLabel}
              {price}
              {selectHotelBtn}
              </div>:
              <>
                {price}
                {priceLabel}
                {detailBtn}
                {selectHotelBtn}
              </>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const stateMapProps = (state) => {
  return {
    currencyCode: state.UserData.Currency,
    params: state.HotelSearch.parameters,
    night: state.HotelSearch.date.duration,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
    userData:state.UserData.userData
  };
};
const mapDispatchToProps = (dispatch) => ({
  // toggleAccountWindow: () => dispatch(toggleAccountWindow())
});

export default withRouter(connect(stateMapProps,mapDispatchToProps)(ShowAccomodation));
