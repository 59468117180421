import axios from "axios";
import { push } from "connected-react-router";

import {
  Header,
  Package as PackageURL,
  ServerAddress,
} from "../Enum/Urls";
import { showError, editMessage } from "./VisualDataAction";

export const getHotelDetailsAsync = (data) => ({
  type: "HotelDetails",
  result: data,
});

export const setReservedPackage=(data)=>({
  type:'setPackageData',
  result: data
});

export const getReserveByIdPackage=(params)=>{
  return(dispatch)=>{
      axios({
          method:"get",
          params,
          url:`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.GetReserveById}`,
          headers:{...Header}
      })
      .then((response)=>{
          dispatch(setReservedPackage(response.data.result))
          dispatch(editMessage(true));
      })
      .catch((error)=>{
          try{
              dispatch(showError({visibility:true,content:error.response.data.error.message}))
          }catch (e) {
              dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
          }
      });
  }
};

export const confirmPackage = (params) => {
  return (dispatch) => {
    //handle header
    let token = localStorage.getItem("userToken");
    let HeaderAthu = null;
    if (token) {
      HeaderAthu = { Authorization: `Bearer ${token}`, ...Header };
    } else {
      HeaderAthu = { ...Header };
    }
    //
    axios({
      method: "post",
      url: `${ServerAddress.Type}${ServerAddress.Package}${PackageURL.Confirm}`,
      data: { ...params },
      headers: HeaderAthu,
    })
      .then((responsive) => {
        dispatch(
          push(
            `/profile/details?userName=${params.username}&reserveId=${params.reserveId}`
          )
        );
      })
      .catch((error) => {
        try {
          dispatch(
            push(
              `/profile/details?userName=${params.username}&reserveId=${params.reserveId}`
            )
          );
        } catch (e) {
          dispatch(
            showError({
              visibility: true,
              content: "Internal Error,Please refresh the page",
            })
          );
        }
      });
  };
};