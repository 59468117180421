import React from "react";
import {connect} from "react-redux";

import underConstruction from "../image/underConstruction.svg"
const UnderConstruction = (props) => {
    const { Dictionary } = props;
    return (<div className="section-vertical-padding">
        <div className="page-container text-center">
            <img src={underConstruction} alt={Dictionary.underConstruction} className="under-construction-image"  />
            <h2 className="large-title"> {Dictionary.underConstruction} </h2>
            <p>{Dictionary.weAreMakingLotsOfImprovementsAndWillBeBackSoon}</p>
        </div>
    </div>);
};
const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(UnderConstruction);