import {Reducers} from '../Enum/Models';
//import '../Dictionary'
export const showAccountWindowIR=(value)=>({type:'ShowAccountWindowIR',data:value});
export const toggleAccountWindow=()=>({type:'ToggleAccountWindow'});
export const toggleErrorWindow=(params)=>({type:'ToggleErrorWindow',params});
export const SwitchAccountWindow=(mode)=>({type:'SwitchAccountWindow',mode});
export const toggleVarification=(mode)=>({type:'VERIFICATION',mode});

//new  version
export const showError=(config)=>({
  type:Reducers.HANDLE_ERROR,
  visibility:config.visibility,
  content:config.content,
  closable:config.closable,
  buttonLabel:config.buttonLabel,
  icon:config.icon,
  bold:config.bold
});
export const editMessage=(data)=>({type:Reducers.MESSAGE,responded:data});
export const setHomeCSS=(data)=>({type:'SETHOMEPAGECSS',status:data});
export const setLanguage=(data,lan)=>({type:'SETLAN',data:{Dictionary:data,Lan:lan}});
export const setLang=(lan)=>({type:'SETLANG',lan:lan});
export const loadLanguage=(lan)=>{
  return(dispatch)=>{
          dispatch(setLanguage(undefined,lan))    
  }
};
export const changeScrollAbility=(data)=>({type:Reducers.SCROLLABILITY, value:data});
export const changeSafarmarketLayout=(data)=>({type:Reducers.SAFARMARKET_LAYOUT, value:data});
