import React from "react";
import resaneh from "../../image/footer/resaneh.png";
import enamad from "../../image/footer/enamad.png";
import iranPlaneOrganization from "../../image/footer/iran-plane-organization.png";
import iranAirplane from "../../image/footer/iran-airplane.png";
import moment from "moment";
import { connect } from "react-redux";
import LayoutConfig from "../../Config/layoutConfig";

class SafarmarketFooter extends React.Component {
  state = {
    showcurrency: false,
    showlang: false,
  };
  toggleCurrency() {
    this.setState((prevState) => ({
      showcurrency: !prevState.showcurrency,
    }));
  }
  toggleLang() {
    this.setState((prevState) => ({
      showlang: !prevState.showlang,
    }));
  }
  render() {
    const { Dictionary, LAN } = this.props;
    return (
      <footer className="footer safarmarket-footer">
        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "naminTravel-theme" ) || (
          <div className="main-footer">
            <div className="page-container">
              <div className="safarmarket-footer-inner">
                <div className="namads-holder clearfix">
                  {(LayoutConfig.companyName === "Sunhotel")?
                      <a className="footer-namad-item" referrerPolicy="origin" target="_blank" href="https://logo.samandehi.ir/Verify.aspx?id=160388&p=rfthgvkaobpdxlaomcsimcsi">
                        <img referrerPolicy="origin" src={resaneh} className="logo-samandehi" alt="" />
                      </a>
                      :
                      <a 
                        className="footer-namad-item"
                        referrerpolicy="origin" 
                        target="_blank" 
                        href="https://logo.samandehi.ir/Verify.aspx?id=289746&p=uiwkmcsipfvljyoeaodsgvka"
                        >
                        <img 
                          referrerpolicy="origin" 
                          src='https://logo.samandehi.ir/logo.aspx?id=289746&p=odrfaqgwbsiyyndtshwlwlbq' 
                          alt="logo-samandehi" 
                          id="nbqeoeuksizpjzpewlaofukz"
                          />
                      </a> 
                  }
                  {/* <a
                    className="footer-namad-item"
                    href="https://www.cao.ir/paxrights"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={passengerRights}
                      className="namad-item"
                      alt="حقوق مسافر"
                    />
                  </a> */}
                  {(LayoutConfig.companyName === "Sunhotel")?
                  <a className="footer-namad-item" referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=29250&amp;Code=uGKdtCgAdAMGvWshw6E0">
                    <img referrerPolicy="origin" src={enamad} className="namad-logo" alt="" id="uGKdtCgAdAMGvWshw6E0" />
                  </a>                           
                  :
                    <a className="footer-namad-item" referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=34040&amp;Code=PsXBCQQuoLeiSeNcJ7sv">
                      <img referrerpolicy="origin" src={enamad} alt="" id="PsXBCQQuoLeiSeNcJ7sv" />
                    </a>
                  }
                  <a
                    className="footer-namad-item"
                    href="https://www.cao.ir"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={iranPlaneOrganization}
                      className="namad-item"
                      alt="سازمان هواپیمایی کشور "
                    />
                  </a>

                  <a
                    className="footer-namad-item"
                    href="http://www.aira.ir/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={iranAirplane}
                      className="namad-item"
                      alt="انجمن شرکت های هواپیمایی ایران "
                    />
                  </a>
                  {/* <span className="footer-namad-item">
                    <img src={padidehLogo} alt="padideh81" className="namad-item" />
                  </span> */}
                  
                </div>
                <div className="logo-copyright">

                  <img
                    src={LayoutConfig.footerLogo || LayoutConfig.logo}
                    alt={LayoutConfig.companyName}
                    className="footer-logo"
                  />
                  <div className="margin-top">
                      {/* پشتیبانی 24 ساعته (از 8 صبح تا 6 صبح روز بعد) : <span dir="ltr" className="no-wrap">+98 21 91008091</span> */}
                      پشتیبانی همه روزه از ساعت 9 الی 23 : <span dir="ltr" className="no-wrap">+98 21 91008091</span>
                      <div>
                        شما همچنین می توانید برای ارتباط سریع تر از طریق آدرس ایمیل 
                        <a href="mailto:online@itours.ir" target="_blank" rel="noreferrer" className="margin-start-half margin-end-half" >Online@itours.ir</a>
                        با ما در ارتباط باشید
                      </div>
                    </div>
                </div>
              </div>
              {/* <div>
                {LAN==="NO"?(
                  LayoutConfig.copyrightText
                    ? LayoutConfig.copyrightText
                    : Dictionary.copyrightText
                )
                :LayoutConfig.themeClassName === "style-2"? (<span className="semi-bold black">
                      &copy;
                      {LayoutConfig.copyrightText
                        ? LayoutConfig.copyrightText
                        : Dictionary.copyrightText}
                  </span>)
                  :
                  (<span className="small">
                  <small>
                    &copy;
                    {LayoutConfig.copyrightText
                      ? LayoutConfig.copyrightText
                      : Dictionary.copyrightText}
                  </small>
                </span>)
                }
              </div> */}
            </div>
          </div>
        )}
      </footer>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(SafarmarketFooter);
