import React ,{ Component } from "react";
import {connect} from "react-redux";
import {Tooltip} from 'antd';

class SelectPassenger extends Component{
    constructor(props) {
        super(props);    
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
      }
    componentDidMount() {
        if (window.innerWidth > 850){
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.props.close();
        }
    }
    render(){
        const {Dictionary} = this.props;
        const selectedPassengers = <>

            <span>{this.props.adults} </span>
            <span className="margin-end-light"> {this.props.adults > 1 ? Dictionary.adults : Dictionary.adult}</span>

            {this.props.children > 0 && <>
                <span> + {this.props.children} </span>
                <span className="margin-end-light">{this.props.children > 1 ? Dictionary.children : Dictionary.child}</span>
            </>}
        
            {this.props.infants > 0 && <>
            <span> + {this.props.infants} </span>
            {this.props.infants > 1 ? Dictionary.infants : Dictionary.infant}
            </>}

        </>;
        return(
            <div className={`select-passengers ${this.props.largeSelectPassenger ?"large-select-passenger":"" }`}  ref={this.setWrapperRef}>
                <Tooltip title={selectedPassengers} >
                    <button type="button" className="selected-passengers-input" onClick={this.props.toggleSelectPassengers}> 
                        {selectedPassengers}
                    </button> 
                </Tooltip>
            {this.props.selectPassengersShow?
            <div className="select-passenegers-block">
                <div className="type-row clearfix">
                    <span className="type-label pull-start">
                        {Dictionary.adult}
                        {/*(12+)*/}
                    </span>
                    <div className="change-quantity pull-end">
                        <button className="remove-btn" onClick={() => this.props.removePassenger("adult")}><span className="itours-icon minus-icon" /></button>
                        <input className="passenger-input" value={this.props.adults} onChange={(e)=>this.props.handleSearchParam("adult",parseInt(e.target.value))} readOnly/>
                        <button className="add-btn" onClick={() => this.props.addPassenger("adult")}><span className="itours-icon plus-icon" /></button>
                    </div>
                </div>
                <div className="type-row clearfix">
                    <span className="type-label pull-start">
                        {Dictionary.children} (2-11)
                    </span>
                    <div className="change-quantity pull-end">
                        <button className="remove-btn" onClick={() => this.props.removePassenger("child")}><span className="itours-icon minus-icon" /></button>
                        <input className="passenger-input" value={this.props.children} onChange={(e)=>this.props.handleSearchParam("child",parseInt(e.target.value))} readOnly/>
                        <button className="add-btn" onClick={() => this.props.addPassenger("child")}><span className="itours-icon plus-icon" /></button>
                    </div>
                </div>
                <div className="type-row clearfix">
                    <span className="type-label pull-start">
                        {Dictionary.infant} ({Dictionary.under2})
                    </span>
                    <div className="change-quantity pull-end">
                        <button className="remove-btn" onClick={() => this.props.removePassenger("infant")}><span className="itours-icon minus-icon" /></button>
                        <input className="passenger-input" value={this.props.infants} onChange={(e)=>this.handleSearchParam("infant",parseInt(e.target.value))} readOnly/>
                        <button className="add-btn" onClick={() => this.props.addPassenger("infant")}><span className="itours-icon plus-icon" /></button>
                    </div>
                </div>
            </div>:
            null
            }
        </div>  
        )
    }
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(SelectPassenger);