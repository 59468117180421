export const Map=(object,pattern,value)=>{
    let cash=object
    for(let cnt=0;cnt<pattern.split(".").length;cnt++){
        let isArray=false;
        let name=(pattern.split(".")[cnt]).toString();
        let indexArray=0;
        if(name.indexOf("[")===-1){
            isArray=false;
        }
        else{
            isArray=true;
            indexArray=parseInt((name.split("[")[1]).split("]")[0]);
            name=name.split("[")[0];

        }
        if(cnt===pattern.split(".").length-1){
            cash[name]=value
        }
        else if(cnt===0){
            if(object[name]===undefined){
                (isArray)?object[name]=[]:object[name]={};
            }
            //cash=(isArray)?object[name][indexArray]:object[name];
            if(isArray){
                if(object[name][indexArray]===undefined){
                    object[name][indexArray]={};
                    cash=object[name][indexArray];
                }else{
                    cash=object[name][indexArray];
                }
            }else{
                cash=object[name];
            }
        }else{
            cash=(isArray)?cash[name][indexArray]:cash[name];
        }
    }
};
export function replaceAll(stringParam,search, replacement){
    return stringParam.replace(new RegExp(search, 'g'), replacement);
};
export const getCookie=(cname)=> {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
export const setCookie=(cname, cvalue, exdays)=> {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};