import { useEffect, useState } from "react";

const Timer = props => {

    const { initialTime } = props;
    const [remaindTime, setRemaindTime] = useState(initialTime);

    useEffect(() => {
        const timer = setInterval(() => {
            setRemaindTime(prevState => {
                if (prevState > 0) {
                    return (prevState - 1);
                } else {
                    clearInterval(timer);
                    props.callBack();
                    return (prevState);
                }
            });
        }, 1000);

        return (() => {
            clearInterval(timer)
        })
    }, [initialTime]);

    const min = Math.floor(remaindTime / 60);
    const sec = remaindTime % 60;

    return (
        <span className={`timer-wrapper ${props.className || ""}`}>
            <span className="minutes">{min.toString().padStart(2, '0')}</span>
            <span className="symbol">:</span>
            <span className="seconds">{sec.toString().padStart(2, '0')}</span>
        </span>
    )
}
export default Timer;