import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { ServerAddress } from "../../Enum/Urls";
import ChangePassword from "../../components/Admin/ChangePassword";
import "react-day-picker/lib/style.css";
import { getHotelReserve } from "../../Actions/HotelAction";
import queryString from "query-string";
import { editMessage } from "../../Actions/VisualDataAction";
import numeral from "numeral";
import { Payment } from "../../Enum/Models";
import Price from "../../components/Common/Price";
import ChargeDeposit from "../../components/Common/ChargeDeposit";
import UserInfo from "../../components/Admin/UserInfo";

class Index extends React.Component {
  ref = null;
  state = {
    userInformation: this.props.userData,
    selectedItem: 'userInformation',
    MessageContainerShow: false,
    MessageContainerContent: null
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const mode = queryString.parse(this.props.location.search).mode;
    if (mode){
      this.setState({ selectedItem: mode });
    }else{
      this.props.history.push("/profile?userInformation");
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedItem !== prevState.selectedItem) {
      if (this.state.selectedItem === "reservedHotels") {
        //call hotel service
        this.props.editMessage(false);
        this.props.getHotelReserve("HotelGlobal");
      }
      if (this.state.selectedItem === "reservedFlights") {
        //call hotel service
        this.props.editMessage(false);
        // if (LayoutConfig.themeClassName === 'naminTravel-theme--%%%--'){
        //   let emailAdress = this.props.userData && this.props.userData.emailAddress;
        //   this.props.getHotelReserve("NaminTravelFlights", emailAdress);
        // }else{
          this.props.getHotelReserve("FlightGlobal");
        // }
      }
      this.props.editMessage(false);
    }
    if (prevProps.userData !== this.props.userData){
      this.setState({
        userInformation: this.props.userData
      })
    }

    const mode = queryString.parse(this.props.location.search).mode || "userInformation";
    const prevMode = queryString.parse(prevProps.location.search).mode || "userInformation";

    if (mode !== prevMode) {
      this.setState({ selectedItem: mode });
    }

  }

  render() {
    const { Dictionary,userData,balance } = this.props;
    const AppAddress = process.env.REACT_APP_SERVER_LOCALSERVER; 
    const token=localStorage.getItem('userToken');
    const isItoursIr = token && (AppAddress ==="santana.imt.as" || AppAddress ==="itours.ir");

    return (
      <div className="section-vertical-padding">
        <div className="profileContainer">
          <div className="page-container">
            {process.env.REACT_APP_SERVER_LOCALSERVER === 'namintravel.com' || <h2 className="page-title"> {Dictionary.userProfile} </h2>}
            <div className={`profile-tab-and-contents-wrapper ${process.env.REACT_APP_SERVER_LOCALSERVER === 'namintravel.com'?"":"bg-white box-shadow"}`}>
              <div className="clearfix tab-holder flex text-center">
                <Link
                  to="/profile?mode=userInformation"
                  className={
                    this.state.selectedItem === "userInformation"
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  {Dictionary.editInformation}
                </Link>
                <Link
                  to="/profile?mode=reservedHotels"
                  className={
                    this.state.selectedItem === "reservedHotels"
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  {Dictionary.reservedHotels}
                </Link>
                <Link
                  to="/profile?mode=reservedFlights"
                  className={
                    this.state.selectedItem === "reservedFlights"
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  {Dictionary.reservedFlights}
                </Link>
                {isItoursIr && <Link
                  to="/profile?mode=chargeDeposit"
                  className={
                    this.state.selectedItem === "chargeDeposit"
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  افزایش اعتبار کیف پول
                </Link>}
                <Link
                  to="/profile?mode=resetPassword"
                  className={
                    this.state.selectedItem === "resetPassword"
                      ? "tab-s active-tab-s"
                      : "tab-s"
                  }
                >
                  {Dictionary.changePassword}
                </Link>
              </div>
              {this.state.selectedItem === "userInformation" && <UserInfo userData={userData} />}
              {this.state.selectedItem === "resetPassword" && (
                <div className="card-padding">
                  <div className="information">
                    <h3 className="normal-title margin-bottom-large">
                      <span className="itours-icon change-password margin-end" />
                      <span className="inline-block">
                        {Dictionary.changePassword}
                      </span>
                    </h3>
                    <ChangePassword />
                  </div>
                  {this.state.MessageContainerShow ? (
                    <div className="errorShow">
                      {this.state.MessageContainerContent}
                    </div>
                  ) : null}
                </div>
              )}
              {this.state.selectedItem === "reservedHotels" && (
                <div className="card-padding">
                  <h3 className="normal-title margin-bottom-large">
                    <span className="itours-icon hotel-gray margin-end" />
                    <span className="inline-block">
                      {Dictionary.reservedHotels}
                    </span>
                  </h3>

                  {!this.props.responded ? (
                    <div>
                      {Dictionary.loadingData}
                      ...
                    </div>
                  ) : (
                    <div className="itours-table-holder responsive-table no-scroll-responsive-table">
                      <table className="itours-table">
                        <thead>
                          <tr>
                            <th>{Dictionary.id}</th>
                            <th> {Dictionary.checkIn} </th>
                            <th>{Dictionary.fullName}</th>
                            <th>{Dictionary.hotelName}</th>
                            <th>{Dictionary.price}</th>
                            <th>{Dictionary.status}</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.hotelVocher.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.id}{" "}
                                </span>
                                <span> {item.id} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.checkIn}{" "}
                                </span>
                                <span className="text-end"> {item.date} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.fullName}{" "}
                                </span>
                                <span className="text-end">
                                  {" "}
                                  {item.gender ? "Mr" : "Mrs"} {item.firstName}{" "}
                                  {item.lastName}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.hotelName}{" "}
                                </span>
                                <span className="text-end"> {item.hotel} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.price}{" "}
                                </span>
                                <span
                                  className="nowrap price-holder text-end"
                                  dir="ltr"
                                >
                                  {" "}
                                  {numeral(item.salePrice).format("0,0")}{" "}
                                  {item.currency}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.status}{" "}
                                </span>
                                {item.status === "Issued" ? (
                                  <span className="green nowrap text-end">
                                    {Dictionary.Issued}
                                  </span>
                                ) : item.status === "Pending" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.paymentPending}
                                  </span>
                                ) : item.status === "Canceled" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.canceled}
                                  </span>
                                ) : item.status === "Refunded" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.Refunded}
                                  </span>
                                ) : item.status === "Failed" ? (
                                  <span className="purple nowrap text-end">
                                    {Dictionary.WebServiceUnsuccessful}
                                  </span>
                                ) : item.status === "PaymentSuccessful" ? (
                                  <span className="green nowrap text-end">
                                    {Dictionary.successfulPayment}
                                  </span>
                                ) : item.status === "WebServiceUnsuccessful" ? (
                                  <span className="purple nowrap text-end">
                                    {Dictionary.WebServiceUnsuccessful}
                                  </span>
                                ) : item.status === "Registered" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.registered}
                                  </span>
                                ) : item.status === "Undefined" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.undefined}
                                  </span>
                                ) : (
                                  <span className="nowrap text-end">
                                    {Dictionary.undefined}
                                  </span>
                                )}
                              </td>
                              <td>
                                <Link
                                  className="nowrap content-link has-left-arrow"
                                  to={`/profile/details?userName=${userData.userName}&reserveId=${item.id}`}
                                >
                                  {Dictionary.reservationDetails}
                                </Link>
                                {(item.status === Payment.Registered || item.status === Payment.Pending) && <a
                                className="payment button red-btn nowrap margin-start-half"
                                href={`${ServerAddress.Type}${ServerAddress.localServer}/payment/reserve?reserveId=${item.id}`}
                              >
                                {Dictionary.payment}
                              </a>}
                              {(item.status === Payment.Registered || item.status === Payment.Issued) && <a
                                    target="_blank"
                                    className=" button color-1-btn nowrap margin-start-half"
                                    rel="noopener noreferrer"
                                    href={`http://92.222.136.211:8080/en/Hotel/Voucher?reserveId=${item.id}&username=${userData.userName}`}
                                  >
                                    {Dictionary.GetVoucher}
                                  </a>}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.selectedItem === "reservedFlights" && (
                <div className="card-padding">
                  <h3 className="normal-title margin-bottom-large">
                    <span className="itours-icon top-plane-gray margin-end" />
                    <span className="inline-block">
                      {Dictionary.reservedFlights}
                    </span>
                  </h3>

                  {!this.props.responded ? (
                    <div>
                      {Dictionary.loadingData}
                      ...
                    </div>
                  ) : (
                    <div className="itours-table-holder responsive-table no-scroll-responsive-table">
                      <table className="itours-table">
                        <thead>
                          <tr>
                            <th>{Dictionary.id}</th>
                            <th>{Dictionary.departure} </th>
                            <th>{Dictionary.return} </th>
                            <th>{Dictionary.fullName} </th>
                            <th>{Dictionary.email} </th>
                            <th>{Dictionary.phoneNumber} </th>
                            <th>{Dictionary.price} </th>
                            <th>{Dictionary.status} </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.flightVoucher.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.id}{" "}
                                </span>
                                <span> {item.id} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.departureDate}{" "}
                                </span>
                                <span className="text-end">
                                  {" "}
                                  {moment(
                                    item.departureDate,
                                    "YYYY-MM-DDTHH:mm"
                                  ).format("YYYY/MM/DD HH:mm")}{" "}
                                </span>
                              </td>
                              <td>
                                {item.returnDate ? <><span className="mobile-label">
                                  {" "}
                                  {Dictionary.returnDate}{" "}
                                </span>
                                <span className="text-end">
                                  {" "}
                                  {moment(
                                    item.returnDate,
                                    "YYYY-MM-DDTHH:mm"
                                  ).format("YYYY/MM/DD HH:mm")}{" "}
                                </span>
                                </>
                                :"--"}
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.fullName}{" "}
                                </span>
                                <span className="en-font text-end">
                                  {" "}
                                  {item.gender ? "Mr" : "Mrs"} {item.firstName}{" "}
                                  {item.lastName}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.email}{" "}
                                </span>
                                <span dir="ltr" className="en-font text-end">
                                  {" "}
                                  {item.email}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.phoneNumber}{" "}
                                </span>
                                <span dir="ltr"> {item.phoneNumber} </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.price}{" "}
                                </span>
                                <span
                                  className="nowrap price-holder text-end"
                                  dir="ltr"
                                >
                                  <Price currency={item.currency.code}>
                                    {item.salePrice}
                                  </Price>
                                </span>
                              </td>
                              <td>
                                <span className="mobile-label">
                                  {" "}
                                  {Dictionary.status}{" "}
                                </span>
                                {item.status === "Issued" ? (
                                  <span className="green nowrap text-end">
                                    {Dictionary.Issued}
                                  </span>
                                ) : item.status === "Pending" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.paymentPending}
                                  </span>
                                ) : item.status === "Canceled" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.canceled}
                                  </span>
                                ) : item.status === "Refunded" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.Refunded}
                                  </span>
                                ) : item.status === "Failed" ? (
                                  <span className="purple nowrap text-end">
                                    {Dictionary.WebServiceUnsuccessful}
                                  </span>
                                ) : item.status === "PaymentSuccessful" ? (
                                  <span className="green nowrap text-end">
                                    {Dictionary.successfulPayment}
                                  </span>
                                ) : item.status === "WebServiceUnsuccessful" ? (
                                  <span className="purple nowrap text-end">
                                    {Dictionary.WebServiceUnsuccessful}
                                  </span>
                                ) : item.status === "Registered" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.registered}
                                  </span>
                                ) : item.status === "Undefined" ? (
                                  <span className="nowrap text-end">
                                    {Dictionary.undefined}
                                  </span>
                                ) : (
                                  <span className="nowrap text-end">
                                    {Dictionary.undefined}
                                  </span>
                                )}
                              </td>
                              <td>
                                <Link
                                  className="nowrap content-link has-left-arrow"
                                  to={`/profile/details?userName=${userData.userName}&reserveId=${item.id}`}
                                >
                                  {Dictionary.reservationDetails}
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}

              {isItoursIr && this.state.selectedItem === "chargeDeposit" && (
                <div className="card-padding">
                  <div className="information">
                    <h3 className="normal-title margin-bottom-large">
                      <span className="itours-icon wallet margin-end" />
                      <span className="inline-block">
                        افزایش اعتبار کیف پول
                      </span>
                    </h3>
                    {balance && <div className="margin-bottom-large semi-bold">
                      <span className="margin-end">موجودی کیف پول:</span>
                        {balance ==="loading" && <span className="loading-inline-circle margin-end" />}
                        {balance.amount > 0 ? <Price currency={balance.currencyType} >{balance.amount}</Price> : balance.amount}
                    </div>}
                    <ChargeDeposit />
                  </div>
                  {this.state.MessageContainerShow ? (
                    <div className="errorShow">
                      {this.state.MessageContainerContent}
                    </div>
                  ) : null}
                </div>
              )}


            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.UserData.userData,
  hotelVocher: state.HotelSearch.reserveHotel,
  responded: state.VisualData.Responded,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  flightVoucher: state.FlightSearch.reserveFlight,
  balance : state.UserData.userBalance
});
const mapActionToDispatch = (dispatch) => ({
  getHotelReserve: (type,email) => dispatch(getHotelReserve(type,email)),
  editMessage: (param) => {
    dispatch(editMessage(param));
  },
});
export default connect(mapStateToProps, mapActionToDispatch)(Index);
