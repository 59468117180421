import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import moment from "moment";

import { showError } from "../../Actions/VisualDataAction";
import useInterval from '../../hooks/useIntreval';


const RemainingTime = props => {
  const [remainingTime, setRemainingTime] = useState();
  const durationRef=useRef();

  useEffect(()=>{
    durationRef.current=moment.utc(props.expirationTime).diff(props.startTime ?? Date.now());
  },[props.expirationTime, props.startTime]);

 const timer = useInterval(() => {
      if (durationRef.current>0){
        const durationFormatted = new Date(durationRef.current).toISOString().substr(11, 8);
        durationRef.current=durationRef.current-1000;      
        setRemainingTime(durationFormatted);
      }else{
        props.showError({
            icon:"timer",
            visibility:true,
            content:<div className='margin-top margin-bottom'>
                {props.Dictionary.ThisReservationHasExpiredPleaseTryAgain}
              </div>,
            buttonLabel:props.Dictionary.returnToHome
          });
          timer.cleanup();
      }
  }, 1000);

  let remainedTime = null;
  if (remainingTime){
    remainedTime = <div className="card card-padding bg-white box-border margin-bottom margin-top">
        <div className='remained-time-wrapper'>
            <div dir="ltr">{remainingTime}</div>
            <span className="icon-large itours-icon red-clock-icon"/>
        </div>
    </div>;
  }

  return remainedTime;
};

const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
  });
const mapActionToProps = (dispatch) => ({
showError: (param) => dispatch(showError(param))
});

export default connect(mapStateToProps, mapActionToProps)(RemainingTime);
