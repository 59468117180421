import { useState } from "react";
import axios from "axios";
import { Form } from "antd";
import { connect } from "react-redux";

import { Header, DomesticHotelApiUrls as HotelURL, ServerAddress } from "../../Enum/Urls";
import Loading from "../Common/Loading";

const DomesticHotelDetailCommentForm = props => {

    const { Dictionary } = props;
    const { getFieldDecorator } = props.form;

    const [status, setStatus] = useState();

    let errorMessage = "";
    switch (status) {
        case "error":
            errorMessage = "ارسال اطلاعات با خطا روبرو شد. لطفا دوباره تلاش کنید."
            break;
        case "success":
            errorMessage = "دیدگاه شما با موفقیت ثبت شد و در انتظار تایید است."
            break;
        default:
            errorMessage = "";
    }

    const SubmitLoginHandler = (e) => {
        e.preventDefault();
        props.form.validateFields(async (err, values) => {
            if (!err) {
                setStatus('pending');

                const submitComment = async () => {
                    // setRateResponse();
                    const params = {
                        PageId: props.pageId,
                        FullName: values.FullName,
                        CityName: values.CityName,
                        Email: values.Email,
                        Comment: values.Comment,
                        IsRecommended: values.IsRecommended || false,
                        Satisfaction: +values.Satisfaction,
                        RoomService: +values.RoomService,
                        ResturantQuality: +values.ResturantQuality,
                        DealWithPassanger: +values.DealWithPassanger
                    };
                    try {
                        const response = await axios.post(`${ServerAddress.Type}${ServerAddress.DomesticHotelA}${HotelURL.InsertComment}`,
                            params,
                            {
                                headers: {
                                    ...Header,
                                    apikey: "68703d73-c92c-4105-9f71-9f718aaad2cc",
                                    'Accept-Language': 'fa-IR'
                                }
                            }
                        );

                        if (response?.data) {
                            setStatus('success');
                        } else {
                            setStatus('error');
                        }
                    } catch (e) {
                        console.dir(e);

                        setStatus('error');
                    }
                }

                submitComment();

            }
        });
    };
    const closeStatusModal = () => {
        if (status !== "pending") {
            setStatus();
        }
    }

    return (
        <>
            {!!status && <div className="itours-modal visible" >
                <div className="modal-overlay" onClick={closeStatusModal}
                />
                <div className="modal-inner small-width text-center">
                    <div className="modal-main">
                        {status === "pending" ?
                            <div className='padding-v-20'>
                                <Loading />
                            </div>
                            : (
                                <div>
                                    <br /><br />
                                    {status === "success" ?
                                        <span className="itours-icon check-green icon-xlarge" />
                                        :
                                        <span className="itours-icon error-icon icon-xlarge" />
                                    }
                                    <div className="margin-top margin-bottom">
                                        {errorMessage}
                                    </div>
                                    <br />
                                </div>
                            )}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="button color-1-btn"
                            onClick={closeStatusModal}
                            disabled={status === "pending"}
                        >
                            {Dictionary.ok}
                        </button>
                    </div>
                </div>
            </div>}

            <h5 className="small-title">ثبت نظر </h5>
            {status === 'pending' ?
                <div className='padding-v-20'>
                    <Loading />
                </div>
                :
                <Form onSubmit={SubmitLoginHandler}>
                    <div className="float-row">
                        <div className="col-small-12 col-medium-6 col-large-4">
                            <Form.Item className="no-margin form-group" label={Dictionary.fullName}>
                                {getFieldDecorator('FullName', {
                                    rules: [{ required: true, message: 'نام و نام خانوادگی خود را وارد کنید.' }],
                                })(<input className="form-input" type="text" />)}
                            </Form.Item>

                            <Form.Item className="no-margin form-group" label="شهر">
                                {getFieldDecorator('CityName', {
                                    rules: [{ required: true, message: 'نام شهر را وارد کنید.' }],
                                })(<input className="form-input" type="text" />)}
                            </Form.Item>

                            <Form.Item className="no-margin form-group" label={Dictionary.email}>
                                {getFieldDecorator('Email', {
                                    rules: [{ required: true, message: Dictionary.enterEmail },
                                    { type: 'email', message: Dictionary.enteredEmailAddressIsNotValid }
                                    ],
                                })(<input className="form-input" type="email" />)}
                            </Form.Item>
                        </div>
                        <div className="col-small-12 col-medium-6 col-large-4">
                            <Form.Item label="درصد رضایتمندی" className="form-group">
                                {getFieldDecorator('Satisfaction', {
                                    rules: [{ required: true, message: 'یکی از گزینه ها را انتخاب کنید.' }],
                                })(
                                    <select className="form-select block" defaultValue={false}>
                                        <option disabled value={false}>--</option>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                            <option key={item * 10} value={item * 10}>{item * 10}</option>
                                        ))}
                                    </select>,
                                )}
                            </Form.Item>

                            <Form.Item label="آرامش، پاکیزگی و وضعیت اتاق‌ها" className="form-group">
                                {getFieldDecorator('RoomService', {
                                    rules: [{ required: true, message: 'یکی از گزینه ها را انتخاب کنید.' }],
                                })(
                                    <select className="form-select block" defaultValue={false}>
                                        <option disabled value={false}>--</option>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                            <option key={item} value={item}>{item}</option>
                                        ))}
                                    </select>,
                                )}
                            </Form.Item>
                            <Form.Item label="کیفیت رستوران و کافی شاپ" className="form-group">
                                {getFieldDecorator('ResturantQuality', {
                                    rules: [{ required: true, message: 'یکی از گزینه ها را انتخاب کنید.' }],
                                })(
                                    <select className="form-select block" defaultValue={false}>
                                        <option disabled value={false}>--</option>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                            <option key={item} value={item}>{item}</option>
                                        ))}
                                    </select>,
                                )}
                            </Form.Item>
                            <Form.Item label="خدمات و نحوه برخورد کارکنان" className="form-group">
                                {getFieldDecorator('DealWithPassanger', {
                                    rules: [{ required: true, message: 'یکی از گزینه ها را انتخاب کنید.' }],
                                })(
                                    <select className="form-select block" defaultValue={false}>
                                        <option disabled value={false}>--</option>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                            <option key={item} value={item}>{item}</option>
                                        ))}
                                    </select>,
                                )}
                            </Form.Item>
                        </div>
                        <div className="col-small-12 col-medium-12 col-large-4">
                            <Form.Item label="متن نظر" className="form-group">
                                {getFieldDecorator('Comment', {
                                    rules: [{ required: true, message: "دیدگاه خود را وارد کنید." }],
                                })(
                                    <textarea placeholder="متن نظر" className="full-width form-input hotel-comment-textarea"></textarea>
                                )}
                            </Form.Item>

                            <Form.Item className="form-group">
                                {getFieldDecorator('IsRecommended')(
                                    <div className="checkbox-holder">
                                        <input className="custom-checkbox" type="checkbox" />
                                        <label className="checkbox-label"> پیشنهاد به دیگران </label>
                                    </div>
                                )}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="submit" className="margin-top margin-bottom button blue-btn large-btn" disabled={status === 'pending'}>
                            ثبت
                            {status === 'pending' && <span className="loading-inline-circle margin-start-half" />}
                        </button>
                    </div>
                </Form>}
        </>
    )
}

const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary
});


export default connect(mapStateToProps)(Form.create({ name: 'comment' })(DomesticHotelDetailCommentForm));