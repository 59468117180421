import React from 'react';
import PropTypes from 'prop-types';
import {Redirect,Route} from 'react-router-dom';
export default class GaurdRouter extends React.Component{
    static propTypes={
        component:PropTypes.any.isRequired,
        path:PropTypes.string.isRequired
    }
    render(){
        const {component:Component,auth:Auth,...Props}=this.props;
        return <Route {...Props} render={(prop)=>(
            Auth ? (<Component {...prop} />):(<Redirect to={{pathname:'/',state:{from:prop.hotelNoImage}}} />)
        )}></Route>
    }
}