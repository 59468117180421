import { useState } from "react";

import Bar from "../Common/Bar";
import DomesticHotelDetailCommentItem from "./DomesticHotelDetailCommentItem";
import DomesticHotelDetailCommentForm from "./DomesticHotelDetailCommentForm";

const DomesticHotelDetailComments = props => {

    const { scoreInformation } = props;
    const [showAllComments, setShowAllComments] = useState(false)

    if (!scoreInformation) {
        return "no score Information"
    }

    return (
        <>
            <h5 className="normal-title">نظرات</h5>
            {!!scoreInformation.CommentCount && <div className="card box-border margin-bottom bg-white card-padding">
                <div className="float-row large-gap">
                    <div className="col-small-12 col-medium-3">
                        <h5 className="small-title">امتیاز هتل از دید کاربران</h5>
                        <label className="font-13">
                            درصد رضایتمندی
                        </label>
                        <Bar persentage={scoreInformation.Satisfaction} className="block margin-bottom" />

                        <label className="font-13">
                            آرامش، پاکیزگی و وضعیت اتاق‌ها
                        </label>
                        <Bar persentage={scoreInformation.RoomService} className="block margin-bottom" />

                        <label className="font-13">
                            کیفیت رستوران و کافی شاپ
                        </label>
                        <Bar persentage={scoreInformation.ResturantQuality} className="block margin-bottom" />

                        <label className="font-13">
                            خدمات و نحوه برخورد کارکنان
                        </label>
                        <Bar persentage={scoreInformation.DealWithPassanger} className="block margin-bottom" />
                    </div>
                    <div className="col-small-12 col-medium-9">
                        <h5 className="small-title">نظرات کاربران</h5>

                        {scoreInformation.Comments?.slice(0, 3).map(commentItem => <DomesticHotelDetailCommentItem key={commentItem.CommentId} comment={commentItem} />)}

                        {showAllComments && scoreInformation.Comments?.slice(3).map(commentItem => <DomesticHotelDetailCommentItem key={commentItem.CommentId} comment={commentItem} />)}

                        {scoreInformation.Comments?.length > 3 && <div className="text-center">
                            <button
                                type="button"
                                className="button blue-btn"
                                onClick={() => { setShowAllComments(prevState => !prevState) }}
                            >
                                مشاهده نظرات {showAllComments ? "کمتر" : "بیشتر"}
                            </button>
                        </div>}
                    </div>
                </div>
            </div>}
            <div className="card box-border margin-bottom bg-white card-padding">
                <DomesticHotelDetailCommentForm pageId={props.pageId} />
            </div>
        </>
    )
}

export default DomesticHotelDetailComments;