import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import {
  searchFlight,
  searchFlightAsync,
  sortFlightSearch,
  resetFilters
} from "../../Actions/FlightAction";
import FilterForm from "../../components/Flight/FilterForm";
import BaggageFilter from "../../components/Flight/BaggageFilter";
import ListItem from "../../components/Common/ListItem.js";
import { Flight, Travel } from "../../Enum/Models";
import sortIcon from "../../image/vertical2arrow.svg";
import _ from "lodash";
//import image
import SearchForm from "../../components/Flight/SearchForm";
import AirlineFilter from "../../components/Flight/AirlineFilter.js";
import Loading from "../../components/Common/Loading";
import Page from "../../components/Common/Page";
import danger from "../../image/danger.svg";
import { editMessage } from "../../Actions/VisualDataAction";
import LayoutConfig from "../../Config/layoutConfig";
import FlightFormSearchTOF from "../../components/TOF/Flight-Form-Search-TOF";
class FlightResult extends React.Component {
  state = {
    error: null,
    first: 0,
    last: 9,
    showfilter: false,
    showsearch: false,
    sortPrice: false,
    sortName: 0,
    sortRate: 0,
    tripType: null,
    timeoutModalVisibility:false
  };
  handleChangeValue(first, last) {
    this.setState({ first: first, last: last });
  }
  
  resultsTimer = null;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.userData && (this.props.userData !== prevProps.userData) && this.props.userData.isEmailConfirmed ){
      if (this.props.naminTravelSelectedFlight){
        if (this.props.naminTravelSelectedFlight.extraBaggage){
          this.props.history.push(
            `/flight/getpassenger?key=${this.props.naminTravelSelectedFlight.key}&extraBaggage=true`
          );
        }else{
          this.props.history.push(
            `/flight/getpassenger?key=${this.props.naminTravelSelectedFlight.key}`
          );
        }

      }
    }
    if (this.props.location.search !== prevProps.location.search) {
      this.props.searchFlightAsync(new Flight());
      this.props.editMessage(false);
      this.getFlights();
    }
    if (this.props.flights !== prevProps.flights){
      this.setState({
        ...this.state,
        first: 0,
        last: 9
      });
    }
    if (this.props.flights !== prevProps.flights || prevState.first !== this.state.first){
      this.scrollToResults();
    }

    if (this.props.flights && this.props.flights.length>0 && prevProps.flights !==this.props.flights){
      this.resultsTimer = setTimeout(()=>{this.setState({timeoutModalVisibility:true})},600000);        
    }
    if (this.props.location !== prevProps.location){
      clearTimeout(this.resultsTimer);
    }

  }
  componentWillUnmount() {
    clearTimeout(this.resultsTimer);
  }
  searchRepeatHandle = ()=>{
    this.props.searchFlightAsync(new Flight());
    this.props.editMessage(false);
    this.getFlights();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.searchFlightAsync(new Flight());
    this.props.editMessage(false);
    this.getFlights();
    //this.props.fillHotelSearchURL(window.location.href);
  }
  handleSort = (type) => {
    if (type === "totalPrice") {
      this.setState(
        (prevState) => ({
          sortPrice: !prevState.sortPrice,
        }),
        () => {
          this.props.sortFlightSearch({
            typeSort: type,
            isReverse: this.state.sortPrice,
          });
        }
      );
    }
  };
  getFlights = () => {
    this.props.resetAllFilters();
    let parameters = queryString.parse(this.props.location.search);
    parameters.adult = parseInt(parameters.adult);
    parameters.child = parseInt(parameters.child);
    parameters.infant = parseInt(parameters.infant);
    parameters.departureDateTimes = parameters.departureDateTimes.split("|");
    parameters.originCodes = parameters.originCodes.split("|");
    parameters.originNames = parameters.originNames.split("|");
    parameters.destinationCodes = parameters.destinationCodes.split("|");
    parameters.destinationNames = parameters.destinationNames.split("|");
    parameters.isEthnic = process.env.REACT_APP_THEME==='NAMINTRAVELCOM'?true:undefined;
    //parameters.supplier = process.env.REACT_APP_THEME==='NAMINTRAVELCOM'?"Ethnic":undefined;
    if (parameters.airTripType === "RoundTrip") {
      parameters.originCodes.push(parameters.destinationCodes[0]);
      parameters.destinationCodes.push(parameters.originCodes[0]);
    }
    this.setState({
      tripType: parameters.airTripType,
    });
    this.props.searchFlight(parameters);
  };
  
  scrollToResults = () => { if(this.availsHolder ){ this.availsHolder.scrollIntoView({behavior: "smooth"})}} ;

  render() {
    const { Dictionary } = this.props;
    return (!this.props.flights || this.props.flights.length === 0 ) && !this.props.respond ? (
      <Loading style2={LayoutConfig.themeClassName === "style-2"} fullPage description={Dictionary.SearchingBestPrices+" ..."}/>
    ) : // loading
    this.props.flights === null ? (
      <div className="flight">
        <div className="page-container">
          <div className="section-vertical-padding">
            <h2 className="page-title">
              {Dictionary.noFlightFoundForYourRequest}.
              <div className="page-subtitle">{Dictionary.pleaseTryAgain}</div>
            </h2>

            <div className="clearfix alert-cart card">
              <img src={danger} className="alert-icon" alt="danger-icon" />
              <div className="alert-content">
                <h6 className="alert-title">
                  {Dictionary.please},{Dictionary.noFlightFoundForYourRequest}.
                </h6>
                <p className="no-margin">{Dictionary.pleaseTryAgain}.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="flight search-result-page">
        {this.state.timeoutModalVisibility &&
          <div className="itours-modal visible">
            <div className="modal-overlay" onClick={this.closeItinerariModal} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <div className="text-center margin-top margin-bottom">
                  <div className="margin-bottom">
                    <span className="itours-icon gray-clock-icon icon-xlarge" />
                  </div>
                  <h4 className="normal-title margin-bottom">
                    {Dictionary.flightStillAroundTitle}
                  </h4>
                  <p className="timer-modal-text">
                    {Dictionary.flightStillAroundP1} {Dictionary.flightStillAroundP2}
                  </p>
                  <Link to="/" className="button grey-btn margin-end">                   
                   {Dictionary.home}
                  </Link>
                  <button
                    type="button"
                    className="button color-1-btn"
                    onClick={()=>window.location.reload()}
                  >
                    {Dictionary.searchAgain}
                </button>
                </div>
              </div>
            </div>
          </div>
        }


        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "naminTravel-theme__" )  && (
          <div className="result-inline-search-section">
            <div className="page-container">
              <FlightFormSearchTOF inline={true} onSearchRepeate={this.searchRepeatHandle} />
            </div>
          </div>
        )}
        { LayoutConfig.themeClassName === "style-2" && <div className="search-form-wrapper">
          <div className="page-container ">
            <SearchForm
              alwaysShowForm
              inline={true}
              onSearchRepeate={this.searchRepeatHandle}
              originCity={
                this.props.flights &&
                this.props.flights[0] &&
                this.props.flights[0].airItinerary[0].flightSegments[0]
                  .departureAirport.cityName
              }
              destinationCity={
                this.props.flights &&
                this.props.flights[0] &&
                _.last(
                  this.props.flights[0].airItinerary[0].flightSegments
                ).arrivalAirport.cityName
              }
            />
          </div>
        </div>
        }
        {this.props.flights && this.props.airlineFilter.length !== 0 ? (
          <div className="airline-filter-holder">
            <AirlineFilter />
          </div>
        ) : null}
        <div className="page-container">
          {LayoutConfig.themeClassName === "naminTravel-theme" && <div className="float-row padding-top-half">
          <div className="col-small-12 col-medium-3">
            {/* <div className="okr-bg side-top-text margin-bottom hidden-responsive">
                We look at the price, travel time, number of stops, and baggage allowance to determine which options you might like best. 
              </div> */}
              <BaggageFilter />
          </div>
          <div className="col-small-12 col-medium-9">
          <div className="search-form-wrapper ">
                  <SearchForm
                    inline={true}
                    onSearchRepeate={this.searchRepeatHandle}
                    originCity={
                      this.props.flights &&
                      this.props.flights[0] &&
                      this.props.flights[0].airItinerary[0].flightSegments[0]
                        .departureAirport.cityName
                    }
                    destinationCity={
                      this.props.flights &&
                      this.props.flights[0] &&
                      _.last(
                        this.props.flights[0].airItinerary[0].flightSegments
                      ).arrivalAirport.cityName
                    }
                  />
                </div>            
          </div>
          </div>}
          <div className="avails-row float-row flight-avails-row">
            <div className="col-small-12 col-medium-3">
              <div className="sidebar medium-box-border bg-white">
                {/*                                         <div className="sidebar-item clearfix">
                                    <span className="results-number-holder">
                                        <b> {<span>{this.state.result && this.state.result.length}</span>} </b> flyreise funnet
                                    </span>
                                    <button type="button" className="button grey-btn pull-end filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                                        Filtre
                                    </button>
                                </div> */}
                <div
                  className={
                    this.state.showfilter
                      ? "showfilter filters-holder"
                      : "filters-holder"
                  }
                >
                  <FilterForm />
                  <button
                    type="button"
                    className="button red-btn filter-close-btn"
                    onClick={() => {
                      this.setState({
                        showfilter: false,
                      });
                    }}
                  >
                    {Dictionary.close}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-small-12 col-medium-9">
              {LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "style-2" || LayoutConfig.themeClassName ==="naminTravel-theme" || (
                <div className="search-form-wrapper ">
                  <SearchForm
                    inline={true}
                    onSearchRepeate={this.searchRepeatHandle}
                    originCity={
                      this.props.flights &&
                      this.props.flights[0] &&
                      this.props.flights[0].airItinerary[0].flightSegments[0]
                        .departureAirport.cityName
                    }
                    destinationCity={
                      this.props.flights &&
                      this.props.flights[0] &&
                      _.last(
                        this.props.flights[0].airItinerary[0].flightSegments
                      ).arrivalAirport.cityName
                    }
                  />
                </div>
              )}

              <div ref={element => {this.availsHolder = element}}>
                {this.props.flights && this.props.flights.length > 0 ? (
                  <div className="avails-holder">
                    <div className="results-sorting-block row">
                      <div className="sort-container">
                        <img
                          src={sortIcon}
                          alt={"sort"}
                          title={Dictionary.sortBy}
                        />
                        <label className="margin-end-light" htmlFor={"sort"}>
                          {Dictionary.sortBy}:{" "}
                        </label>
                        <button
                          id={"sort"}
                          type="button"
                          className={
                            this.state.sortPrice
                              ? "button results-sort-btn"
                              : "button results-sort-btn low"
                          }
                          onClick={() => {
                            this.handleSort("totalPrice");
                          }}
                        >
                          {" "}
                          {this.state.sortPrice
                            ? Dictionary.theMostExpensive
                            : Dictionary.cheapest}{" "}
                        </button>
                      </div>
                    </div>
                    {/* {process.env.REACT_APP_THEME==='NAMINTRAVELCOM' && <a href="https://itours.no" className="card-padding box-border itours-no-card-link">
                          To see normal prices click here
                    </a>} */}
                    <ListItem
                      data={this.props.flights}
                      last={this.state.last}
                      start={this.state.first}
                      type={Travel.flight}
                      tripType={this.state.tripType}
                    />
                    {this.props.flights.length > 10 && (
                        <Page
                          length={this.props.flights.length}
                          itemCurrent={this.state.first}
                          itemPerPage={10}
                          onChangeValue={this.handleChangeValue.bind(this)}
                        />
                    )}
                    {this.props.flights.length > 0 && (
                      <button
                        type="button"
                        className="filter-responsive-btn"
                        onClick={() => {
                          this.setState((prevState) => ({
                            showfilter: !prevState.showfilter,
                          }));
                        }}
                      >
                        {Dictionary.filters}
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="section-vertical-padding text-center">
                    <div>
                      <span className="itours-icon icon-xlarge error-icon" />
                    </div>
                    <h5 className="normal-title">
                      {Dictionary.noFlightFoundForYourRequest}.
                      <div className="page-subtitle">
                        {Dictionary.pleaseTryAgain}.
                      </div>
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  respond: state.VisualData.Responded,
  flights: state.FlightSearch.flight,
  airlineFilter: state.FlightSearch.filterShow.airlinePrice,
  backupFlight: state.FlightSearch.backupFlight,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  naminTravelSelectedFlight:state.FlightSearch.naminTravelSelectedFlight,
  userData: state.UserData.userData
});
const mapDispatchToProps = (dispatch) => ({
  searchFlight: (param) => {
    dispatch(searchFlight(param));
  },
  searchFlightAsync: (param) => {
    dispatch(searchFlightAsync(param));
  },
  editMessage: (param) => {
    dispatch(editMessage(param));
  },
  sortFlightSearch: (param) => {
    dispatch(sortFlightSearch(param));
  },
  resetAllFilters:()=>{
    dispatch(resetFilters());
  }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlightResult));
