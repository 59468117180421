import parse from 'html-react-parser';

const DomesticHotelDetailRules = props => {

    let instructionElement, mendatoryFeeElement;

    if (props.accommodationInformation?.instruction) {
        instructionElement = parse(props.accommodationInformation?.instruction);
    }
    if (props.accommodationInformation?.mendatoryFee) {
        mendatoryFeeElement = parse(props.accommodationInformation?.mendatoryFee);
    }

    return (
        <>
            <h5 className="normal-title">قوانین و مقررات</h5>
            <div className="card box-border margin-bottom bg-white card-padding">
                <div className="float-row large-gap">
                    {props.policies?.length > 0 && <div className={`col-small-12 ${(instructionElement || mendatoryFeeElement) ? "col-medium-3" : ""}`}>
                        {props.policies?.map(item => <div className="pair-row" key={item.Title}>
                            <span><img src={item.Image} alt={item.Keyword} className="small-image-icon margin-end-light" /> {item.Title}</span>
                            <span>{item.Description}</span>
                        </div>)}
                    </div>}
                    <div className={`domestic-hotel-rules-list col-small-12 ${props.policies?.length > 0 ? "col-medium-9" : ""}`}>
                        {instructionElement || null}
                        {mendatoryFeeElement || null}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DomesticHotelDetailRules;