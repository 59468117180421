import React from 'react';
import axios from "axios";
import {
    Header,
    User as UserURL,
    ServerAddress,
  } from "../../Enum/Urls";
import {toggleAccountWindow,SwitchAccountWindow} from '../../Actions/VisualDataAction';
import {confrimEmail} from '../../Actions/UserAction';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
class Confrim extends React.Component{
    state={
        message:this.props.Dictionary.YourEmailIsBeingVerifiedPleaseWait,
        status:"loading",
        varficationSuccess:false
    };
    componentDidMount() {
        window.scrollTo(0,0);
        debugger;
        let userData = this.props.userData;
        if (userData && userData.isEmailConfirmed){
            this.setState({
                varficationLoading:false,
                message:this.props.Dictionary.YourEmailHasAlreadyBeenVerified,
                status:"success"
            });
            console.log("YourEmailHasAlreadyBeenVerified");
        }else{
            console.log("YourEmailHasNotAlreadyBeenVerified");
            let parameters=this.props.location.search;
            const code = parameters.split("code=")[1].split("&")[0];
            const userId = parameters.split("userId=")[1].split("&")[0];
            axios.post(`${ServerAddress.Type}${ServerAddress.User}${UserURL.ActivateEmail}`, {code:code,userId:userId}, {headers: {...Header,},})
            .then(({ data }) => {
               if (data.error) {
                console.log("EmailVerificationEncounteredAnError");
                  debugger;
                  this.setState({
                    varficationLoading:false,
                    message:this.props.Dictionary.EmailValidationFailedPleaseTryAgain,
                    status:"failed"
                });
               }else{
                console.log("YourEmailHasBeenSuccessfullyActivated");
                   debugger;
                   this.setState({
                    varficationLoading:false,
                    message:this.props.Dictionary.YourEmailHasBeenSuccessfullyActivated,
                    status:"success"
                    });
                    // this.props.SwitchAccountWindow(false);
                    // this.props.toggleAccountWindow();
               }
            })
            .catch((error) => {
                console.log("EmailVerificationEncounteredAnError2");
               debugger;
               this.setState({
                varficationLoading:false,
                message:this.props.Dictionary.EmailValidationFailedPleaseTryAgain,
                status:"failed"
            });
            });
        }

    }
    render(){
        const {Dictionary} = this.props;
        let color = "";
        if (this.state.status==="success"){
            color="green";
        }else if (this.state.status === "failed"){
            color="purple"
        }
        return(
            <div>
                <div className="section-vertical-padding">
                    <div className="profileContainer">
                        <div className="page-container">
                            <h2 className="page-title"> {Dictionary.EmailVerification} </h2>
                            <div className="page-padding text-center">
                                <br/><br/>
                                {this.state.status === "loading"? (<div className="loading-inner-container margin-bottom">
                                    <div className="item-1"></div>
                                    <div className="item-2"></div>
                                    <div className="item-3"></div>
                                    <div className="item-4"></div>
                                    <div className="item-5"></div>
                                </div>)
                                : this.state.status === "success"? <span className={`itours-icon check-green icon-xlarge margin-bottom-large`} />
                                : this.state.status === "failed" ? <span className={`itours-icon circle-error icon-xlarge margin-bottom-large`} />
                                :null}
                                <div className={`large-title ${color}`}>
                                    {this.state.message}
                                </div>  

                                <div className="margin-top-large">
                                    <br/>
                                    {this.props.userData && this.props.userData.userName ? <Link className="button blue-btn" to={"/"}>{Dictionary.home}</Link>
                                    :<button type="button" className="button blue-btn" onClick={()=>{this.props.toggleAccountWindow()}}>
                                        {Dictionary.login}
                                    </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    error:state.VisualData.error,
    respond:state.VisualData.Responded,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN,
    userData: state.UserData.userData
})
const mapDispatchToProps=(dispatch)=>({
    confrimEmail:(param)=>{dispatch(confrimEmail(param))},
    toggleAccountWindow: () => dispatch(toggleAccountWindow()),
    SwitchAccountWindow: (mode) => {dispatch(SwitchAccountWindow(mode));}
})
export default connect(mapStateToProps,mapDispatchToProps)(Confrim)