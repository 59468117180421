import React from "react";
import { connect } from "react-redux";
import { filterSelect, ApplyFilterFlight } from "../../Actions/FlightAction";
import { FilterType, FilterName } from "../../Enum/Models";

class BaggageFilter extends React.Component {
    handleSelect = (evt, type, filterType) => {
        let value = isNaN(evt.target.value)
            ? evt.target.value
            : parseFloat(evt.target.value);
        if (evt.target.checked) {
            this.props.filterSelect({ isAdd: true, type, value, filterType });
        } else {
            this.props.filterSelect({ isAdd: false, type, value, filterType });
        }

        this.props.ApplyFilterFlight();
    };
    render() {
        const { Dictionary } = this.props;
        return (
            <div className="baggage-filter-wrapper">
                <div className="text-center">
                    <strong>Baggage</strong>
                    <div className="margin-bottom-half font-13 semi-bold">Include in the total price (Per traveler)</div>
                </div>
                <div className="baggage-filter-checkbox text-center">
                    <div className="float-row">
                        <div className="col-small-6">
                            <div className="checkbox-holder">
                                <label className="semi-bold no-wrap">With Baggage</label>
                                <div className="baggage-filter-inner">
                                    <span class="itours-icon icon-large green-baggage margin-end-light" />
                                    <input
                                        className="custom-checkbox"
                                        type="checkbox"
                                        value="WithBaggage"
                                        onChange={e => { this.handleSelect(e, FilterName.baggage, FilterType.Union) }}
                                    />
                                    <span className="checkbox-label baggage-radio-label" />
                                </div>
                            </div>
                        </div>                        
                        <div className="col-small-6">
                            <div className="checkbox-holder">
                                <label className="semi-bold no-wrap">Without Baggage</label>
                                <div className="baggage-filter-inner">
                                    <span class="itours-icon icon-large red-no-baggage margin-end-light" />
                                    <input
                                        className="custom-checkbox"
                                        type="checkbox"
                                        value="WithoutBaggage"
                                        onChange={e => { this.handleSelect(e, FilterName.baggage, FilterType.Union) }}
                                    />
                                    <span className="checkbox-label baggage-radio-label" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const dispatchToProps = (dispatch) => ({
    filterSelect: (data) => dispatch(filterSelect(data)),
    ApplyFilterFlight: () => dispatch(ApplyFilterFlight()),
});
const stateToProps = (state) => ({
    filtershow: state.FlightSearch.filterShow,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
});

export default connect(stateToProps, dispatchToProps)(BaggageFilter);


