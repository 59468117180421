import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import _ from 'lodash';

const Map = props => {

  const { hotels } = props;

  const centerLat = (hotels.reduce((sum, currtentItem) => sum + currtentItem.latitude, 0)) / hotels.length;
  const centerLong = (hotels.reduce((sum, currtentItem) => sum + currtentItem.longitude, 0)) / hotels.length;

  let averageCenter, zoom;

  if (hotels.length > 1) {
    //calculate center & zoom:
    let minLat = _.minBy(hotels, 'latitude').latitude;
    let maxLat = _.maxBy(hotels, 'latitude').latitude;
    let minLong = _.minBy(hotels, 'longitude').longitude;
    let maxLong = _.maxBy(hotels, 'longitude').longitude;

    averageCenter = [(minLat + maxLat) / 2, (minLong + maxLong) / 2];

    const latdif = maxLat - minLat;
    const longdif = maxLong - minLong;

    zoom = 14;
    if ((latdif > .03) || longdif > .03) {
      zoom = 13;
    }
    if ((latdif > .06) || longdif > .06) {
      zoom = 12;
    }
    if ((latdif > .1) || longdif > .1) {
      zoom = 11;
    }
    if ((latdif > .2) || longdif > .2) {
      zoom = 10;
    }
    if ((latdif > .7) || longdif > .7) {
      zoom = 9;
    }

  } else {
    zoom = props.zoom || 14;
  }

  return (
    <LeafletMap center={averageCenter || [centerLat, centerLong]} zoom={zoom} className={`map-wrapper ${props.hotels[0]?.popupContent ? "hasPopup" : ""}`}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {hotels.map((item, index) => <Marker position={[item.latitude, item.longitude]} key={index}>
        {!!item.popupContent && <Popup>
          {item.popupContent}
        </Popup>}
      </Marker>)}
    </LeafletMap>
  )
}

export default Map;