import React from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import moment from "moment";

import LayoutConfig from '../../Config/layoutConfig';

const Style2HomeDestinations = props => {

    const domesticFlights = [
        {
            origin:{title:"تهران",name:"Tehran,%20Iran(THR-All%20Airports)",code:"THR"},
            destination:{title:"مشهد",name:"Mashhad",code:"MHD"}
        },
        {
            origin:{title:"اصفهان",name:"Isfahan",code:"IFN"},
            destination:{title:"کیش",name:"Kish%20Island",code:"KIH"}
        },
        {
            origin:{title:"مشهد",name:"Mashhad",code:"MHD"},
            destination:{title:"اصفهان",name:"Isfahan",code:"IFN"}
        },
        {
            origin:{title:"کیش",name:"Kish%20Island",code:"KIH"},
            destination:{title:"بندرعباس",name:"Bandar%20Abbas",code:"BND"}
        },
        {
            origin:{title:"تبریز",name:"Tabriz",code:"TBZ"},
            destination:{title:"شیراز",name:"Shiraz",code:"SYZ"}
        }
    ];

    const foreignFlights = [
        {
            origin:{title:"تهران",name:"Tehran,%20Iran(THR-All%20Airports)",code:"THR"},
            destination:{title:"استانبول",name:"Istanbul,%20Turkey(IST-All%20Airports)",code:"IST"}
        },
        {
            origin:{title:"شیراز",name:"Shiraz",code:"SYZ"},
            destination:{title:"دبی",name:"Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)",code:"DXB"}
        },
        {
            origin:{title:"تبریز",name:"Tabriz",code:"TBZ"},
            destination:{title:"باکو",name:"Baku,%20Azerbaijan(BAK-All%20Airports)",code:"BAK"}
        },
        
        {
            origin:{title:"مشهد",name:"Mashhad",code:"MHD"},
            destination:{title:"بغداد",name:"Baghdad",code:"BGW"}
        },
        {
            origin:{title:"استانبول",name:"Istanbul,%20Turkey(IST-All%20Airports)",code:"IST"},
            destination:{title:"تورنتو",name:"Toronto,%20Canada(YTO-All%20Airports)",code:"YTO"}
        }
    ];

    ///hotel/result?locationId=75286&location=Istanbul&checkin=2022-03-21&checkout=2022-03-24&adults=2&children=0&ages=0
    const hotels =[
        {title:"استانبول",id:75286,name:"Istanbul"},
        {title:"دبی",id:267,name:"Dubai"},
        {title:"آنکارا",id:75608,name:"Ankara"},
        {title:"رم",id:54323,name:"Rome"},
        {title:"ایروان",id:488,name:"Yerevan"}
    ]
    const packages = [
        {
            origin:{title:"مشهد",name:"Mashhad",code:"MHD"},
            destination:{title:"استانبول",name:"Istanbul,%20Turkey(IST-All%20Airports)",code:"IST"}
        },
        {
            origin:{title:"تهران",name:"Tehran,%20Iran(THR-All%20Airports)",code:"THR"},
            destination:{title:"آنکارا",name:"Ankara,%20Turkey(ANK-All%20Airports)",code:"ANK"}
        },
        {
            origin:{title:"اصفهان",name:"Isfahan",code:"IFN"},
            destination:{title:"اسلو",name:"Oslo,%20Norway(OSL-All%20Airports)",code:"OSL"}
        },
        {
            origin:{title:"تبریز",name:"Tabriz",code:"TBZ"},
            destination:{title:"بغداد",name:"Baghdad",code:"BGW"}
        },
        {
            origin:{title:"استانبول",name:"Istanbul,%20Turkey(IST-All%20Airports)",code:"IST"},
            destination:{title:"تورنتو",name:"Toronto,%20Canada(YTO-All%20Airports)",code:"YTO"}
        }
    ];
    
    const date = new Date();
    const domesticFlightDeparture = moment(date).add(7, "d").format("YYYY-MM-DD");
    const foreignFlightDeparture = moment(date).add(15, "d").format("YYYY-MM-DD");
    const hotelCheckin =  moment(date).add(15, "d").format("YYYY-MM-DD");
    const hotelCheckout = moment(date).add(17, "d").format("YYYY-MM-DD");
    const packageCheckin =  moment(date).add(15, "d").format("YYYY-MM-DD");
    const packageCheckout = moment(date).add(17, "d").format("YYYY-MM-DD");

    
    const {Dictionary} = props;
    return(<>
        <section className='style2-destinations-section'>
            <div className='page-container'>
                <h3 className='large-title text-center blue margin-bottom-xlarge'>{LayoutConfig.sloganText && LayoutConfig.sloganText.largeText
                ? LayoutConfig.sloganText.largeText
                : Dictionary.letsBookYourNextTrip}
                </h3>
                <div className="float-row">
                    <div className="col-small-12 col-medium-6 col-large-3">
                        <h3 className="style2dest-title">{Dictionary.domesticFlight}</h3>
                        {domesticFlights.map((item,index)=><Link key={index} to={`/domesticflight/result?originCodes=${item.origin.code}&originNames=${item.origin.name}&destinationCodes=${item.destination.code}&destinationNames=${item.destination.name}&departureDateTimes=${domesticFlightDeparture}&adult=1&child=0&infant=0&cabinClassCode=Economy&airTripType=OneWay&nonStop=false&airlineCode=all&airlineText=all`}>
                            <div className='destination-item'>
                                <span>{item.origin.title}</span>
                                <span className='itours-icon style-2-flight-red mirror-rtl' />
                                <span>{item.destination.title}</span>                        
                            </div>
                        </Link>)}
                    </div>
                    <div className="col-small-12 col-medium-6 col-large-3">
                        <h3 className="style2dest-title">{Dictionary.foreignFlight}</h3>
                        {foreignFlights.map(item=><Link key={item.id} to={`/flight/result?originCodes=${item.origin.code}&originNames=${item.origin.name}&destinationCodes=${item.destination.code}&destinationNames=${item.destination.name}&departureDateTimes=${foreignFlightDeparture}&adult=1&child=0&infant=0&cabinClassCode=Economy&airTripType=OneWay&nonStop=false&airlineCode=all&airlineText=all`}>
                            <div className='destination-item'>
                                <span>{item.origin.title}</span>
                                <span className='itours-icon style-2-flight-red mirror-rtl' />
                                <span>{item.destination.title}</span>                        
                            </div>
                        </Link>)}
                    </div>
                    <div className="col-small-12 col-medium-6 col-large-3">
                        <h3 className="style2dest-title">{Dictionary.foreignHotel}</h3>
                        {hotels.map((item,index)=><Link key={index} to={`/hotel/result?locationId=${item.id}&location=${item.name}&checkin=${hotelCheckin}&checkout=${hotelCheckout}&adults=2&children=0&ages=0`}>
                            <div className='destination-item'>
                                <span>{item.title}</span>  
                                <span className='itours-icon style-2-hotel-red' />                    
                            </div>
                        </Link>)}
                    </div>
                    <div className="col-small-12 col-medium-6 col-large-3">
                        <h3 className="style2dest-title">{Dictionary.package}</h3>
                        {packages.map((item,index)=><Link key={index} to={`/package/result?originCode=${item.origin.code}&originName=${item.origin.name}&destinationCode=${item.destination.code}&destinationName=${item.destination.name}&checkin=${packageCheckin}&checkout=${packageCheckout}&adults=2&children=0&ages=0`}>
                            <div className='destination-item'>
                                <span>{item.origin.title}</span>
                                <span className='itours-icon style-2-package-red' />
                                <span>{item.destination.title}</span>                        
                            </div>
                        </Link>)}                
                    </div>
                </div>
            </div>
        </section>
        <section className='three-large-icon-section'>
            <div className='page-container'>
                <div className='three-large-icon-section-inner'>
                    <div className='icon-item text-center'>
                        <span className='itours-icon icon-xlarge check-badge' />
                        <h3 className='item-title'>تضمین مناسب ترین قیمت</h3>
                    </div>
                    <div className='icon-item text-center'>
                        <span className='itours-icon icon-xlarge headset-support' />
                        <h3 className='item-title'>تیم پشتیبانی حرفه ای</h3>
                    </div>
                    <div className='icon-item text-center'>
                        <span className='itours-icon icon-xlarge check-badge' />
                        <h3 className='item-title'>تضمین مناسب ترین قیمت</h3>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
const mapStateToProps = state => ({
    Dictionary: state.VisualData.Dictionary
});
export default connect(mapStateToProps)(Style2HomeDestinations);