import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Price from "../Common/Price";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Flight as FlightURL, Header, ServerAddress } from "../../Enum/Urls";
import { showError } from "../../Actions/VisualDataAction";
import FlightItinerary from "../Flight/FlightItinery";
import Route from "../Flight/Route";
import airplane from "../../image/airplane.svg";
import { Collapse } from "antd";
class PackageFlightItem extends React.Component {
  static propTypes = {
    model: PropTypes.any.isRequired,
  };
  state = {
    showFlightDetails: false,
    detailsActiveTab:"itineraryDetail",
    rulesContent: null
  };
  getRules = (key) => {
    axios({
      method: "get",
      url: `${ServerAddress.Type}${ServerAddress.Flight}${FlightURL.GetRules}`,
      params: { key },
      headers: { ...Header },
    })
      .then((response) => {
        this.setState({
          rulesContent: response.data.result,
        });
      })
      .catch((error) => {
        try {
          this.props.showError({
            visibility: true,
            content: error.response.data.error.message,
          });
        } catch (e) {
          this.props.showError({
            visibility: true,
            content: "Internal Error,Please refresh the page",
          });
        }
      });
  };
  toggleDetails = () => {
    this.setState({
      detailsActiveTab:"itineraryDetail",
      showFlightDetails: !this.state.showFlightDetails
    })
  };
  showRules = () => {
    this.getRules(this.props.model.key);
  };
  tabClick = (item) => {
    this.setState({
      detailsActiveTab: item,
    });
    if (item === "flightRules") {
      this.showRules();
    }
  };

  render() {
    const { Panel } = Collapse;
    const { Dictionary } = this.props;
    let airportChange = false;

    return (
      <>
        {this.state.showFlightDetails && <div className="overlay" onClick={()=>{this.setState({showFlightDetails:false,detailsActiveTab:"itineraryDetail"})}} />}
        <div className="flight-result-item" >
          <div className="item-main">
            {this.props.isInPackageDetail && <h5 className="normal-title margin-bottom card-padding black">{Dictionary.selectedFlight}</h5>}
            <div className="item-main-inner">
              <div className={"content"}>
                {this.props.model.airItinerary.originDestinationOptions.map(item =><Route key={item.flightSegments[0].flightNumber} model={item} isPackage={true} />)}
                {airportChange && (
                  <div className="has-airport-change">
                    <span className="itours-icon error2-icon icon-small inline-block-middle margin-end-light" />
                    <span className="purple inline-block-middle font-12 semi-bold">
                      {Dictionary.StopForAirportChange}
                    </span>
                  </div>
                )}
              </div>
              {/* <div className="detail-btn-holder">
                <div className={"detail-btn-holder-inner"}>

                </div>
              </div> */}
            </div>
          </div>
          {this.state.showFlightDetails && (
            <div className="flightDetails margin-bottom">
              <div className="flight-detail-main">            
                <div className="itours-tab-holder">
                  <div className="itours-tab-list">
                    <button
                      className={
                        this.state.detailsActiveTab === "itineraryDetail"
                          ? "itours-tab-btn active"
                          : "itours-tab-btn"
                      }
                      onClick={() => this.tabClick("itineraryDetail")}
                    >
                      {Dictionary.flightItinery}
                    </button>
                    <button
                      className={
                        this.state.detailsActiveTab === "baggageInfo"
                          ? "itours-tab-btn active"
                          : "itours-tab-btn"
                      }
                      onClick={() => this.tabClick("baggageInfo")}
                    >
                      {Dictionary.baggageInfo}
                    </button>
                    <button
                      className={
                        this.state.detailsActiveTab === "flightFare"
                          ? "itours-tab-btn active"
                          : "itours-tab-btn"
                      }
                      onClick={() => this.tabClick("flightFare")}
                    >
                      {Dictionary.flightFare}
                    </button>
                    <button
                      className={
                        this.state.detailsActiveTab === "flightRules"
                          ? "itours-tab-btn active"
                          : "itours-tab-btn"
                      }
                      onClick={() => this.tabClick("flightRules")}
                    >
                      {Dictionary.flightRules}
                    </button>
                  </div>
                  <div className="itours-tab-contents">
                    {this.state.detailsActiveTab === "itineraryDetail" ? (
                      <div className="detail-flight">
                        <FlightItinerary model={this.props.model.airItinerary.originDestinationOptions} />
                      </div>
                    ) : (
                      false
                    )}
                    {this.state.detailsActiveTab === "baggageInfo" ? (
                      <div className="flight-baggage-detail">
                        {this.props.model.airItinerary.originDestinationOptions.map((item) => {
                          return (
                            <div
                              className="detail-flight-item "
                              key={item.flightSegments[0].flightNumber}
                            >
                              {item.flightSegments.map((item, index) => {
                                return (
                                  <div
                                    className="baggegesInformation"
                                    dir="ltr"
                                    key={index}
                                  >
                                    <div className="text-left">
                                      <div>
                                        <b>{item.departureAirport.cityName}</b> -{" "}
                                        <small>
                                          {item.departureAirport.countryName}
                                        </small>
                                      </div>
                                    </div>
                                    <div className={"airplaneIcon"}>
                                      <img
                                        src={airplane}
                                        alt={"airplane"}
                                        title={"airplane"}
                                      />
                                    </div>
                                    <div className="text-left">
                                      <div>
                                        <b>{item.arrivalAirport.cityName}</b> -{" "}
                                        <small>
                                          {item.arrivalAirport.countryName}
                                        </small>
                                      </div>
                                    </div>
                                    <div>
                                      {item.baggageInformation.map(
                                        (baggageItem, baggageIndex) => (
                                          <div key={baggageIndex}>
                                            {baggageItem.baggageAllowance}{" "}
                                            {baggageItem.unitType}{" "}
                                            {this.props.hasExtraBaggage && <span>(
                                              +{item.extraBaggageInformation.filter(x=>x.passengerType === baggageItem.passengerType)[0].baggageAllowance}  {baggageItem.unitType}{" "} {Dictionary.extraBaggage}
                                            )</span>}
                                            <span> {Dictionary.for} </span>
                                            {baggageItem.passengerType === "ADT" && (
                                              <span>{Dictionary.adult}</span>
                                            )}
                                            {baggageItem.passengerType === "CHD" && (
                                              <span>{Dictionary.child}</span>
                                            )}
                                            {baggageItem.passengerType === "INF" && (
                                              <span>{Dictionary.infant}</span>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      false
                    )}
                    {this.state.detailsActiveTab === "flightFare" ? (
                      <div className={"fareContainer"}>
                        <div className="title">{Dictionary.fareTotal}</div>
                        <div className="baseFare">
                          <strong>{Dictionary.baseFare}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.airItineraryPricingInfo.itinTotalFare.baseFare}                           
                          </Price>
                        </div>
                        {this.props.model.airItineraryPricingInfo.itinTotalFare.fess && (this.props.model.airItineraryPricingInfo.itinTotalFare.fess.length > 0) && (
                          <div className="fees">
                            <strong>{Dictionary.fees}: </strong>
                            <Price currency={this.props.currency}>
                              {this.props.model.airItineraryPricingInfo.itinTotalFare.fess[0].amount}
                            </Price>
                          </div>
                        )}
                        {this.props.model.airItineraryPricingInfo.itinTotalFare.taxes && (this.props.model.airItineraryPricingInfo.itinTotalFare.taxes.length > 0) && (
                        <div className="taxes">
                          <strong>{Dictionary.taxes}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.airItineraryPricingInfo.itinTotalFare.taxes[0].amount}
                          </Price>
                        </div>
                        )}
                        {/* {this.props.hasExtraBaggage && 
                        <div className="extraBaggage">
                          <strong>{Dictionary.extraBaggage}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.airItineraryPricingInfo.itinTotalFare.extraBaggage}
                          </Price>
                        </div>                      
                        } */}
                        <hr />
                        <div className="totalPrice">
                          <strong>{Dictionary.totalPrice}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.airItineraryPricingInfo.itinTotalFare.totalFare}
                          </Price>
                        </div>

                        {/* {this.props.hasExtraBaggage && <div className="totalPrice">
                          <strong>{Dictionary.totalFareWithExtraBaggage}: </strong>
                          <Price currency={this.props.currency}>
                            {this.props.model.airItineraryPricingInfo.itinTotalFare.totalFareWithExtraBaggage}
                          </Price>
                        </div>} */}
                      </div>
                    ) : (
                      false
                    )}
                    {this.state.detailsActiveTab === "flightRules" ? (
                      <div className="rules-container">
                        {this.state.rulesContent ? (
                          <>
                            {this.state.rulesContent.flightRules.length === 0 ? (
                              <div>{Dictionary.informationNotFound}</div>
                            ) : (
                              <>
                                {this.state.rulesContent.flightRules.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className="rules-item en-font"
                                        key={index}
                                      >
                                        <div className="rule-title">
                                          FareBase: {item.fareBase}
                                        </div>
                                        <Collapse>
                                          {item.flightRule.map(
                                            (itemIn, indexIn) => {
                                              return (
                                                <Panel
                                                  header={itemIn.ruleTitle}
                                                  key={indexIn}
                                                >
                                                  <p>{itemIn.ruleDescription}</p>
                                                </Panel>
                                              );
                                            }
                                          )}
                                        </Collapse>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div>
                            {Dictionary.loadingData}
                            <span className="dots-loading">
                              <span className="dot">.</span>
                              <span className="dot">.</span>
                              <span className="dot">.</span>
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden-medium hidden-large">
                <div className="item-footer">
                  <button
                    type="button"
                    onClick={() => this.toggleDetails()}
                    className="button link-style-btn flight-detail-btn"
                  >
                    {Dictionary.close}
                  </button>
                  <div className="no-wrap">
                    <b dir="ltr" className={`${this.props.isSelected?"":"margin-end-half"} ${this.props.isInPackageDetail? "font-20 black bold":""}`}>
                      <Price currency={this.props.currency}>
                        {this.props.model.airItineraryPricingInfo.itinTotalFare.totalFare}
                      </Price>
                    </b>
                    {this.props.isSelected ?
                      this.props.showTag && <span className="check-green itours-icon margin-start icon-large" />
                    :
                    <button
                      type="button"
                      className="button red-btn"
                      onClick={()=>{
                        this.setState({showFlightDetails:false});
                        this.props.onSelectFlight();
                      }} >
                      {Dictionary.select}
                    </button>}
                  </div>
                </div> 
              </div>             

            </div>
          )}          
          <div className="item-footer">
            {/* <div>           
              {this.props.model.key.slice(0, 3) === "009" ?
                <>
                <Tag color="red" className="itours-tag">{Dictionary.nonChangeable}</Tag>
                  <Tag color="red" className="itours-tag">{Dictionary.nonRefundable}</Tag>
                </>
                :null
              }
            </div> */}

            <button
              type="button"
              onClick={() => this.toggleDetails()}
              className="button link-style-btn flight-detail-btn"
            >
              {Dictionary.flightDetails}{" "}
            </button>
            <div className="no-wrap">
              <b dir="ltr" className={`${this.props.isSelected?"":"margin-end-half"} ${this.props.isInPackageDetail? "font-20 black bold":""}`}>
                <Price currency={this.props.currency}>
                  {this.props.model.airItineraryPricingInfo.itinTotalFare.totalFare}
                </Price>
              </b>
              {this.props.isSelected ?
                this.props.showTag && <span className="check-green itours-icon margin-start icon-large" />
              :
               <button
                type="button"
                className="button red-btn"
                onClick={()=>{
                  this.setState({showFlightDetails:false});
                  this.props.onSelectFlight();
                }} >
                {Dictionary.select}
              </button>}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  currency: state.UserData.Currency,
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => dispatch(showError(param)),
});
const routerBind = withRouter(PackageFlightItem);
export default connect(mapStateToProps, mapDispatchToProps)(routerBind);
