import { connect } from "react-redux";

const DomesticHotelPoint = props => {

  const { Dictionary } = props;

  let pointTitle, pointColor;
  if (props.point >= 90) {
    pointTitle = Dictionary.Excellent;
    pointColor = 'rgb(20, 148, 15)';
  } else if (props.point >= 80) {
    pointTitle = Dictionary.VeryGood;
    pointColor = 'rgb(108, 191, 74)';
  } else if (props.point >= 70) {
    pointTitle = Dictionary.Good;
    pointColor = 'rgb(163, 205, 77)';
  } else if (props.point >= 50) {
    pointTitle = Dictionary.Pleasant;
    pointColor = 'rgb(243, 163, 36)';
  } else {
    pointTitle = Dictionary.Bad;
    pointColor = '#FF5722';
  }

  return (

    <div className={`domesticHotelPointHolder ${props.className || ""}`}>
      <div className="text-end">
        <div className="pointTitle" style={{ color: pointColor }}>
          {pointTitle}
        </div>
        <span className="pointReviews">
          {props.reviews} {Dictionary.GuestReviews}
        </span>
      </div>
      <div className="pointNumber" style={{ backgroundColor: pointColor }}>
        <span className="pointCaret" style={{ backgroundColor: pointColor }} />
        {props.point}
      </div>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN
  };
};

export default connect(mapStateToProps)(DomesticHotelPoint);
