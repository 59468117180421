import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./page/App";
import LayoutConfig from "./Config/layoutConfig";

if (LayoutConfig.siteTitle) {
  document.title = LayoutConfig.siteTitle;
} else {
  switch (process.env.REACT_APP_SERVER_CULTURE) {
    case "IR":
      document.title =
        "آیتورز | خرید بلیط هواپیما ، رزرو هتل خارجی ، تور مسافرتی";
      break;
    default:
      document.title = "Itours Travel: Search Hotels & Cheap Flights";
  }
}

if (LayoutConfig.favicon) {
  let link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = LayoutConfig.favicon;
  document.getElementsByTagName("head")[0].appendChild(link);
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
