import { Tooltip } from 'antd';
import moment from 'moment-jalaali';
import React, { useState } from "react";
import { Transition } from "react-transition-group";
import queryString from "query-string";
import { connect } from "react-redux";

import Price from "../../../components/Common/Price";
import { getCancellationPolicy } from "../../../Actions/HotelAction";
import { showError } from '../../../Actions/VisualDataAction';

function HotelSmRoom(props) {
  const { Dictionary,LAN,rate } = props;
  const [showCancellationInfo, setshowCancellationInfo] = useState(false);
  const [cancellationInfo, setCancellationInfo] = useState();
  const [cancellationLoading, setCancellationLoading] = useState(false);
  const toggleCancellationHandler = async() => {
    if (!cancellationInfo){
      setCancellationLoading(true);

      let parameters = queryString.parse(props.location.search);
      let params = new URLSearchParams();
      
      if (parameters.Id || parameters.hotelId){
        params.append("id", parameters.Id || parameters.hotelId );
      }
  
      parameters.Adults.split(",").forEach((item) =>
        params.append("adults", item)
      );
      params.append("bookingToken", rate.bookingToken);
      params.append("checkin", parameters.Checkin);
      params.append("checkout", parameters.Checkout);
  
      parameters.Children.split(",").forEach((item) =>
        params.append("children", item)
      );
      if (parameters.Children !== "0") {
        parameters.Ages.split(",").forEach((item) => params.append("ages", item));
      }
      
      const cancellationInfo = await getCancellationPolicy(params);
      setCancellationLoading(false);
      if (cancellationInfo?.data) {
        setCancellationInfo(cancellationInfo.data.result.fees);
        setshowCancellationInfo(true);
      }else{
        props.showError({visibility:true,content:cancellationInfo.error.message,closable:true});
      }
    }else{
      setshowCancellationInfo(prevState => !prevState);
    }

  }
  moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: false,
  });
  return (
    <div className={"margin-bottom bg-white rounded-medium"}>
      <div className="border-bottom">
        <div className="card-padding">
          <div className='sm-room-item-content'>
            <div className='sm-room-item-rooms'>
              {props.rooms.map((roomItem,roomIndex) => <div key={roomIndex} className="sm-room-item" >

                <strong className="font-14 sm-font-22 room-name">{roomItem.name}</strong>

                <div className="font-12 sm-font-14 room-capacity">
                  <span className="itours-icon guests-dark margin-end-light icon-small" />
                  <span className="margin-end-light">ظرفیت: </span>
                  <span className="inline-block margin-end-light">
                      {roomItem.adults}
                  </span>
                  {roomItem.adults > 1 ? (
                      <span className="inline-block margin-end-light">
                      {Dictionary.adults}
                      </span>
                  ) : (
                      <span className="inline-block margin-end-light">
                      {Dictionary.adult}
                      </span>
                  )}
                  {roomItem.children > 0 ? (
                      <>
                        <span className="inline-block margin-end-light">
                            {Dictionary.and}
                        </span>
                        <span className="inline-block margin-end-light">
                            {roomItem.children}
                        </span>
                        <span className="inline-block">
                            {roomItem.children > 1
                            ? Dictionary.children
                            : Dictionary.child}
                        </span>
                      </>
                  ) : null}
                </div>
              </div>)}
            </div>

            <div className='sm-board-cancellation'>
              <span className="gray-tag font-12 no-wrap margin-bottom-half">{rate.board.name}<span className="pipe margin-start-light margin-end-light" />{rate.board.code}</span>
              {rate.cancellationPolicy?.status === "Refundable"?
                <button type="button" className="button link-style-btn" onClick={toggleCancellationHandler}>
                  قوانین استرداد
                  {cancellationLoading ? 
                  <span className="loading-inline-circle margin-start-light" /> 
                  : 
                  <span className={`css-icon down-caret-css-icon margin-start-light ${showCancellationInfo?"reverse":""}`} />
                  }
                </button>
              :
                <div className="gray-tag font-12">غیر قابل استرداد</div>
              }
            </div>
          </div>

        </div>
        <Transition in={showCancellationInfo} timeout={300} mountOnEnter unmountOnExit>
          {state => (<div className={`cancellation-collapse-block ${state==="exiting"?"exiting":"visible"}`}>
            <div className='card-padding'>
              <div className="margin-bottom">
                امکان کنسل کردن فقط تا تاریخ های زیر میسر است و در
                صورت درخواست کنسلی، 
                <b> ده درصد مبلغ (حداقل  2,000,000 ریال) </b>
                بابت کارمزد خدمات از مبلغ کل رزرو کسر میگردد.
              </div>
              <ul className="bullet-list ">
                {cancellationInfo.map((rule, index) => {
                  if (rule.amount > 0) {
                    return (
                      <li key={index}>
                        <span className="inline-block margin-end-light">
                          <Price currency={props.currency}>
                            {rule.amount}
                          </Price>
                        </span>
                        <span className="inline-block margin-end-light">
                          {Dictionary.afterDate}
                        </span>
                        <span className="inline-block">
                          <Tooltip
                            title={
                              <span className="en-font">
                                {moment(
                                  rule.fromDate.split("T")[0]
                                ).format("D/M/YYYY")}
                              </span>
                            }
                          >
                            {moment(
                              rule.fromDate.split("T")[0]
                            ).format("D MMM YYYY")}
                          </Tooltip>
                          {LAN === "IR" && (
                            <Tooltip
                              title={moment(
                                rule.fromDate.split("T")[0]
                              ).format("jYYYY/jM/jD")}
                            >
                              (
                              {moment(
                                rule.fromDate.split("T")[0]
                              ).format("jD jMMMM jYYYY")}{" "}
                              )
                            </Tooltip>
                          )}
                        </span>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
            </div>
          </div>)}
        </Transition>
        <div className="room-item-footer card-padding">
            <div>
                <div className="inline-medium margin-end-half font-12 sm-font-14 xs-margin-bottom-half">
                    {Dictionary.totalFor} {props.date.duration} {Dictionary.nights}:
                </div>
                {rate.regularPrice !== rate.salePrice ? (
                    <div className="old-price inline-medium font-16 sm-font-20">
                        <Price currency={props.currency}>
                            {rate.regularPrice}
                        </Price>
                    </div>
                ) : null}
                <b className="new-price inline-medium font-16 sm-font-20">
                    <Price currency={props.currency}>
                    {rate.salePrice}
                    </Price>
                </b>
            </div>   
            {props.selectedRooms?.rate?.bookingToken ===  rate.bookingToken ? <span className='green selected-room-message'>
              <span className='itours-icon icon-small check-green margin-end-light' />
              این اتاق انتخاب شد
              </span>:
            <button className="button blue-btn" type='buttom' onClick={props.onSelectRoomHandler} disabled={props.reserveLoading}>
              انتخاب اتاق
            </button>
            }
        </div>
      </div>
    </div>
  );
}
const stateMapProp = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchesToProps = dispatch => ({
  showError: params => dispatch(showError(params))
})
export default connect(stateMapProp,mapDispatchesToProps)(HotelSmRoom);
