

import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import LayoutConfig from "../../Config/layoutConfig";
import DomesticHotelDetail from "../../components/domesticHotel/DomesticHotelDetail";
import DomesticHotelDetailSafarmarket from "../../components/domesticHotel/DomesticHotelDetailSafarmarket";

const DomesticHotelDetails = props => {

    const parameters = queryString.parse(props.location.search);
    
    if (LayoutConfig.safarmarket && props.currencyCode ==="IRR" && parameters.safarmarketId && parameters.utm_medium && parameters.utm_source ){
      return <DomesticHotelDetailSafarmarket />
    }

    return (
      <DomesticHotelDetail />
    );
}

const stateMapProp = (state) => ({
  currencyCode: state.UserData.Currency
});
export default connect(stateMapProp)(DomesticHotelDetails);
