import React, { Component } from "react";
import queryString from "query-string";
import DomesticHotelGetPassenger from "../../components/domesticHotel/DomesticHotelGetPassenger";
import DomesticHotelGetPassengerSafarmarket from "../../components/domesticHotel/DomesticHotelGetPassengerSafarmarket";
import LayoutConfig from "../../Config/layoutConfig";

class DomesticHotelGetPassengers extends Component {
  render() {
    const parameters = queryString.parse(this.props.location.search);
    if (LayoutConfig.safarmarket && parameters.isFromSafarmarket && parameters.smid){
      return <DomesticHotelGetPassengerSafarmarket />
    }
    return (
      <DomesticHotelGetPassenger />
    );
  }
}

export default DomesticHotelGetPassengers;