import React from 'react';
import {connect} from 'react-redux';
import Rate from "../Common/Rate";
import {filterSelect,applyFilter} from "../../Actions/HotelAction";
import {FilterName,FilterType} from "../../Enum/Models";
import LayoutConfig from '../../Config/layoutConfig';
class FilterForm extends React.Component{
    handleSelect=(evt,type,filterType)=>{
        if(evt.target.type==="checkbox"){
            let value=(isNaN(evt.target.value)?evt.target.value:parseFloat(evt.target.value));
            if(evt.target.checked){
                this.props.filterSelect({isAdd:true,type,value,filterType});
            }else{
                this.props.filterSelect({isAdd:false,type,value,filterType});

            }
        }else{
            let textInput=evt.target.value;
            this.props.filterSelect({isAdd:true,type,textInput,filterType});
        }
        this.props.applyFilter();
    };
    render() {
        const {Dictionary} = this.props;
        return(
        <>
            <strong className={`filter-header ${LayoutConfig.themeClassName === 'naminTravel-theme'?"":"margin-top"}`}>{Dictionary.filters}</strong>
            <div className='padding-h-10'>
                <div className="sidebar-item">

                    <h5 className="normal-title"><span>{Dictionary.searchByName}</span></h5>
                    <div className="form-group has-fake-magnifirer-icon">
                        <input className="form-input placeholder-right"  placeholder={Dictionary.enterHotelName} type="text" onChange={(e)=>{this.handleSelect(e,FilterName.name,FilterType.Like)}} />
                    </div>
                </div>
                <div className="sidebar-item">
                    <h5 className="normal-title"> <span className='okr-bg'>{Dictionary.starRating}</span> </h5>
                    <div className="filter-content">
                        {this.props.filterShow.rating.value.map((item,index)=>{
                            return <div key={index} className="checkbox-holder">
                                    <input className="custom-checkbox" id={"rate" + index} type="checkbox" defaultValue={item} onChange={(e) => { this.handleSelect(e, FilterName.rating, FilterType.Union) }} />
                                    <label className="checkbox-label" htmlFor={"rate" + index}>
                                        <Rate number={parseFloat(item)} />
                                    </label>
                                </div>                    
                            })
                        }
                    </div>
                </div>
                <div className="sidebar-item">
                    <h5 className="normal-title"><span className='okr-bg'>{Dictionary.facilities}</span></h5>
                    {this.props.filterShow.features.value.map((item, index) => {
                        return <div key={index} className="checkbox-holder">
                                    <input className="custom-checkbox" id={"feature" + index} type="checkbox" defaultValue={JSON.stringify(item)} onChange={(e) => { this.handleSelect(e, FilterName.features, FilterType.Intersection) }} />
                                    <label className="checkbox-label" htmlFor={"feature" + index}><span dir="ltr">{item.name}</span></label>
                                </div>
                    })
                    } 
                </div>
                <div className="sidebar-item">
                    <h5 className="normal-title"> <span className='okr-bg'>{Dictionary.board}</span> </h5>
                    {this.props.filterShow.boards.value.map((item,index)=>{
                        // let label=null;
                        // if(Boards[item.board]===undefined){
                        //     label=item.board;
                        // }else{
                        //     label=Boards[item.board].label;
                        // }
                        return <div key={index} className="checkbox-holder">
                                    <input className="custom-checkbox" id={"board" + index} type="checkbox" defaultValue={JSON.stringify(item)} onChange={(e) => { this.handleSelect(e, FilterName.boards, FilterType.Union) }} />
                                    <label className="checkbox-label" htmlFor={"rate" + index}><span dir="ltr">{item.name}</span></label>
                                </div>
                    })
                    }                
                </div>
                <div className="sidebar-item">
                    <h5 className="normal-title"><span className='okr-bg'>{Dictionary.area}</span></h5>
                    {this.props.filterShow.regions.value.map((item,index)=>{
                        return <div key={index} className="checkbox-holder">
                            <input className="custom-checkbox" id={"area" + index} type="checkbox" defaultValue={JSON.stringify(item)} onChange={(e) => { this.handleSelect(e, FilterName.regions, FilterType.Union) }} />
                            <label className="checkbox-label" htmlFor={"area" + index}><span dir="ltr">{item.name}</span></label>
                        </div>
                    })
                    }
                </div>
            </div>
        </>
        )
    }

}
const dispatchToProps=(dispatch)=>({
    filterSelect:(data)=>dispatch(filterSelect(data)),
    applyFilter:()=>dispatch(applyFilter())
})
const stateToProps=(state)=>({
   filterShow:state.HotelSearch.filterShow,
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export  default connect(stateToProps,dispatchToProps)(FilterForm);