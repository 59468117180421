import React from 'react';
import {Card} from 'antd';

const PaymentRadioList = props => {

    return (
        <div className="form-group">
            {props.banks.map(item => <div key={item.keyword} className='margin-bottom'>
                <Card size="small" title={
                    <div className="bank-group-title">
                        <img src={item.image.path} title={item.image.titleAttribute} alt={item.image.altAttribute} className="margin-end" />
                        <div>
                        <b>{item.name}</b> ({item.title})
                        <div className='description'>{item.description}</div>
                        </div>
                    </div>
                }>
                    {item.gateways.map(gatewayItem => <div className="radio-item separated" key={gatewayItem.id}>
                        <input className="custom-radio" type="radio" name="gateway" value={gatewayItem.id} onChange={props.onSelectBank} />
                        <label className="radio-label">
                            <span className="radio-mark" />
                            <img src={gatewayItem.image.path} alt={gatewayItem.image.altAttribute || gatewayItem.name} className="margin-start margin-end-half" />
                            {gatewayItem.name}
                        </label>
                    </div>)}
                </Card>
            </div>)}
        </div>
    )

}

export default PaymentRadioList;